import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { Button, FormGroup, Input } from "reactstrap";
import { Button, FormGroup, Input } from "@mui/material";
// import AuthContainer from "../../components/AuthContainer/Index";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import IPageProps from "../../interfaces/Page";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "100px",
    height: "70px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
  },
}));

const ChangePasswordPage: React.FunctionComponent<IPageProps> = (props) => {
  const classes = useStyles();
  const [changing, setChanging] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [old, setOld] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [error, setError] = useState<string>("");

  const history = useHistory();

  const passwordChangeRequest = () => {
    if (password !== confirm) {
      setError("Make sure your passwords are matching");
      return;
    }

    if (error !== "") setError("");

    setChanging(true);

    auth.currentUser
      ?.updatePassword(password)
      .then(() => {
        logging.info("Password change successful.");
        history.push("/");
      })
      .catch((error: any) => {
        logging.error(error);
        setChanging(false);
        setError(error.message);
      });
  };

  if (auth.currentUser?.providerData[0]?.providerId !== "password")
    return <Redirect to="/" />;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography component="h1" variant="h5">
        Change Password
      </Typography>
      <FormLabel>Current Password</FormLabel>
      <FormGroup>
        <Input
          autoComplete="new-password"
          type="password"
          name="oldPassword"
          id="oldPassword"
          placeholder=""
          onChange={(event?: any) => setOld(event.target.value)}
          value={old}
        />
      </FormGroup>
      <FormLabel>New Password</FormLabel>
      <FormGroup>
        <Input
          autoComplete="new-password"
          type="password"
          name="password"
          id="password"
          placeholder=""
          onChange={(event?: any) => setPassword(event.target.value)}
          value={password}
        />
      </FormGroup>
      <FormLabel>Confirm New Password</FormLabel>
      <FormGroup>
        <Input
          autoComplete="new-password"
          type="password"
          name="confirm"
          id="confirm"
          placeholder=""
          onChange={(event?: any) => setConfirm(event.target.value)}
          value={confirm}
        />
      </FormGroup>
      <Button
        disabled={changing}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        // block
        className={classes.submit}
        onClick={() => passwordChangeRequest()}
      >
        Change Password
      </Button>
      <ErrorText error={error} />
    </Container>
  );
};

export default ChangePasswordPage;
