import IRoute from "../interfaces/Route";

import ChangePasswordPage from "../pages/auth/Change";
import ForgotPasswordPage from "../pages/auth/Forgot";
import LoginPage from "../pages/auth/Login";
import LogoutPage from "../pages/auth/Logout";
import RegisterPage from "../pages/auth/Register";
import RegisterPageEmail from "../pages/auth/RegisterEmail";
import RegisterPageEmailConfirmation from "../pages/auth/RegisterEmailConfirmation";
import EmailVerification from "../pages/auth/EmailVerification";
import ResetPasswordPage from "../pages/auth/Reset";
// import FormPage from "../pages/Form";
import HomePage from "../pages/Home";
// import OnlineTrainingPage from "../pages/OnlineTraining";

// import OnlineEntForm from "../components/Forms/OnlineEntForm";
// // import OnlineEntFormBeta from "../components/Forms/OnlineEntFormBeta";
// import DigitalLiterayForm from "../components/Forms/DigitalLiterayForm";
// import PlaceAttractionForm from "../components/Forms/PlaceAttractionForm";
// import ActivityForm from "../components/Forms/ActivityForm";
// import OnlineTrgForm from "../components/Forms/OnlineTrgForm";
// import MenarikForm from "../components/Forms/MenarikForm";
// import OperationForm from "../components/Forms/OperationForm";
// import RegisterForm from "../components/Forms/RegisterForm";
// import IncidentForm from "../components/Forms/IncidentForm";
// // import FeedbackForm from "../components/Forms/FeedbackForm";

// import ModuleEntrepreneur from "../components/OnlineEntrepreneur";
// import PusatInternet from "../components/PusatInternet";
// import DigitalLiteracy from "../components/DigitalLiteracy";
// import PlaceAttraction from "../components/PlaceAttraction";
// import PikActivity from "../components/Activity";
// import OnlineTraining from "../components/OnlineTraining";
// import OnlineTrainingParticipant from "../components/OnlineTrainingParticipant";
// import MenarikDi from "../components/MenarikDi";
// import OperationHour from "../components/OperationHour";
// import Incident from "../components/Incident";
// import Feedback from "../components/Feedback";
import Dashboard from "../components/Dashboard";
// import Dashboard2 from "../components/Dashboard2";

// import BookingCal from "../components/Calendar/Index";

import UserList from "../components/CfgUser/List";
import UserForm from "../components/CfgUser/Form";
import AclForm from "../components/CfgUser/Acl";
// import ModulePage from "../pages/Module";
import MapChecker from "../components/MapChecker";
import MapChecker2 from "../components/MapChecker/Report";
import UploadForm from "../pages/UploadForm";
const Routes: IRoute[] = [
  {
    path: "/",
    exact: true,
    component: HomePage,
    name: "Home Page",
    protected: true,
  },
  // {
  //   path: "/form/p1_online_ent",
  //   name: "Online Enterprenuer",
  //   exact: false,
  //   protected: true,
  //   component: OnlineEntForm,
  // },
  // // {
  // //   path: "/form/p1_online_entbeta",
  // //   name: "Online Enterprenuer - Beta",
  // //   exact: false,
  // //   protected: true,
  // //   component: OnlineEntFormBeta,
  // // },
  // {
  //   path: "/form/p1_digital_literacy",
  //   name: "Digital Literacy",
  //   exact: false,
  //   protected: true,
  //   component: DigitalLiterayForm,
  // },
  // {
  //   path: "/form/p1_place_attraction",
  //   name: "Place Of Attraction",
  //   exact: false,
  //   protected: true,
  //   component: PlaceAttractionForm,
  // },
  // {
  //   path: "/form/p1_activity",
  //   name: "Activity",
  //   exact: false,
  //   protected: true,
  //   component: ActivityForm,
  // },
  // {
  //   path: "/form/p1_online_trg",
  //   name: "Online Training",
  //   exact: false,
  //   protected: true,
  //   component: OnlineTrgForm,
  // },
  // {
  //   path: "/form/p1_register",
  //   name: "Register",
  //   exact: false,
  //   protected: true,
  //   component: RegisterForm,
  // },
  // {
  //   path: "/form/p1_menarik_di",
  //   name: "Menarik Di",
  //   exact: false,
  //   protected: true,
  //   component: MenarikForm,
  // },
  // {
  //   path: "/form/p1_operation_hours",
  //   name: "Operation Hours",
  //   exact: false,
  //   protected: true,
  //   component: OperationForm,
  // },
  // {
  //   path: "/form/p1_incident",
  //   name: "Incident",
  //   exact: false,
  //   protected: true,
  //   component: IncidentForm,
  // },
  // {
  //   path: "/form/p1_feedback",
  //   name: "Feedback",
  //   exact: false,
  //   protected: true,
  //   component: FeedbackForm,
  // },
  // {
  //   path: "/form",
  //   exact: false,
  //   component: FormPage,
  //   name: "Form Page",
  //   protected: true,
  // },
  // {
  //   path: "/onlinetraining/calendar/:id",
  //   exact: false,
  //   component: BookingCal,
  //   name: "Calendar Page",
  //   protected: true,
  // },
  // {
  //   path: "/onlinetraining/calendar",
  //   exact: false,
  //   component: BookingCal,
  //   name: "Calendar Page",
  //   protected: true,
  // },
  // {
  //   path: "/module/onlinetraining",
  //   exact: false,
  //   component: OnlineTraining,
  //   name: "Training",
  //   description: "Training record and data entry form",
  //   protected: true,
  // },
  // {
  //   path: "/onlinetraining/participant/:id",
  //   exact: false,
  //   component: OnlineTrainingParticipant,
  //   name: "Online Training Participant",
  //   protected: true,
  // },
  {
    path: "/register",
    exact: true,
    component: RegisterPage,
    name: "Register Page",
    protected: false,
  },
  {
    path: "/register/email",
    exact: true,
    component: RegisterPageEmail,
    name: "Register e-mail Page",
    protected: false,
  },
  {
    path: "/register/confirmation",
    exact: true,
    component: RegisterPageEmailConfirmation,
    name: "Register e-mail Page Confirmation",
    protected: false,
  },
  {
    path: "/register/verify",
    exact: false,
    component: () => {
      const location = window.location;
      const path: any = new URLSearchParams(location.search);
      const mode: string = path.get("mode");
      // console.log(mode);
      if (/resetPassword/i.test(mode)) {
        return <ResetPasswordPage name="reset" />;
      } else {
        return <EmailVerification name="emailverify" />;
      }
    },
    name: "Verification e-mail Page",
    protected: false,
  },
  {
    path: "/login",
    exact: true,
    component: LoginPage,
    name: "Login Page",
    protected: false,
    redirect: "/dashboard/login",
  },
  {
    path: "/dashboard/login",
    exact: true,
    component: LoginPage,
    name: "Login Page",
    protected: false,
  },
  {
    path: "/change",
    exact: true,
    component: ChangePasswordPage,
    name: "Change Password Page",
    protected: true,
  },
  {
    path: "/logout",
    exact: true,
    component: LogoutPage,
    name: "Logout Page",
    protected: true,
  },
  {
    path: "/dashboard/logout",
    exact: true,
    component: LogoutPage,
    name: "Logout Page",
    protected: true,
  },
  {
    path: "/forget",
    exact: true,
    component: ForgotPasswordPage,
    name: "Forgot Password Page",
    protected: false,
  },
  {
    path: "/reset",
    exact: true,
    component: ResetPasswordPage,
    name: "Reset Password Page",
    protected: false,
  },
  {
    path: "/user/list",
    exact: true,
    component: UserList,
    name: "User List",
    protected: true,
  },
  {
    path: "/user/acl",
    exact: true,
    component: AclForm,
    name: "User ACL",
    protected: true,
  },
  {
    path: "/user/form/:id",
    exact: true,
    component: UserForm,
    name: "User Form",
    protected: true,
  },
  // {
  //   path: "/module/onlineent",
  //   exact: true,
  //   component: ModuleEntrepreneur,
  //   name: "Entrepreneurship",
  //   description: "Enterprenuers record and data entry form",
  //   protected: true,
  // },
  // {
  //   path: "/module",
  //   exact: true,
  //   component: ModulePage,
  //   name: "PEDi Modules",
  //   protected: true,
  // },
  // {
  //   path: "/module/digitalliteracy",
  //   exact: true,
  //   component: DigitalLiteracy,
  //   name: "Digital Literacy",
  //   description: "Digital Literacy record and data entry form",
  //   protected: true,
  // },
  // {
  //   path: "/module/placeattraction",
  //   exact: true,
  //   component: PlaceAttraction,
  //   name: "Place Of Attractions",
  //   description: "Place Of Attractions record and data entry form",
  //   protected: true,
  // },
  // {
  //   path: "/module/activity",
  //   exact: true,
  //   component: PikActivity,
  //   name: "Activity & Programmes",
  //   description: "List of activity/programmes and data entry form",
  //   protected: true,
  // },
  // {
  //   path: "/module/menarikdi",
  //   exact: true,
  //   component: MenarikDi,
  //   name: "Menarik Di",
  //   description: "Menarik Di places and data entry form",
  //   protected: true,
  // },
  // {
  //   path: "/module/operationhour",
  //   exact: true,
  //   component: OperationHour,
  //   name: "Operation Hours",
  //   description:
  //     "PEDi operation hours entry form for any closure due to emergency and etc.",
  //   protected: true,
  // },
  // // {
  // //   path: "/module/incident",
  // //   exact: true,
  // //   component: Incident,
  // //   name: "Incidents",
  // //   description:
  // //     "Form was initially build to close PEDi for emergency, festive & etc. It's serves the same purpose with Operation Hours data entry form. This was listed under enhancement list Phase 1 provide to GDMD.",
  // //   protected: true,
  // // },
  // {
  //   path: "/agency/pusatinternet",
  //   exact: false,
  //   component: PusatInternet,
  //   name: "Pusat Internet",
  //   protected: true,
  // },
  {
    path: "/dashboard",
    exact: false,
    component: Dashboard,
    name: "Dashboard",
    protected: true,
  },
  {
    path: "/dashboard",
    exact: false,
    component: Dashboard,
    name: "Dashboard",
    protected: true,
  },
  {
    path: "/form/upload",
    exact: true,
    component: UploadForm,
    name: "Upload Form",
    protected: true,
  },
  {
    path: "/map-checker/:kategori_name",
    exact: true,
    component: MapChecker2,
    name: "Map Checker",
    protected: true,
  },
  {
    path: "/map-checker2/:kategori_name",
    exact: true,
    component: MapChecker,
    name: "Map Checker",
    protected: true,
  },
];

export default Routes;
