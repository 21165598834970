import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { green } from "@material-ui/core/colors";
// import { Headers, fetchPlus } from "./../../store/headers";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "./../../store/index";
import { setSearch } from "./../../store/form";
import { IsGrantedAccess, AuthDisplay } from "../../config/Acl";

import "./List.css";

const useTableCellStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableCell = (props: any) => {
  const newclasses = useTableCellStyles();
  return <TableCell {...props} classes={newclasses} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
    },
    category: {
      marginTop: theme.spacing(1),
    },
    textField: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    badge: {
      marginLeft: theme.spacing(1),
    },
    badges: {
      position: "absolute",
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  user: CfgUser;
}

interface CfgUser {
  id: number;
  user_id: string;
  name: string;
  userdata?: any;
  email?: string;
  created_at?: string;
  updated_at?: string;
  access?: any[];
  picture?: string;
  status: number;
  [key: string]: number | string | object | any;
}

const CfgUserList = ({ user }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { search } = useSelector((state: RootState) => state.formState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [user_list, setCfgUserList] = React.useState<CfgUser[]>([]);
  // <{ group?: any }>
  let { group } = useParams();
  // console.log(file, uuid);
  // if (file && uuid) {
  //   removelabel = true;
  // }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(event.currentTarget.value));
  };

  const getFormatedDate = (date: string | undefined) => {
    if (date) {
      //console.log(date);
      date = date.replace(/ /, "T") + "Z";
      let d = new Date(date);
      return <>{d.toLocaleString()}</>;
    }
    return <>{date}</>;
  };

  const UserStatus: any = {
    "0": "Pending",
    "1": "Approved",
  };

  // fetch("/api/coverage/3.086356,101.586326/?apikey=testapikeyserver")
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log(data);
  //   });

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch(
        "/api/user/list" +
          (typeof group !== "undefined" && group !== "" ? "/" + group : "")
        // Headers()
      )
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            //console.log(data);
            if (data.users) {
              setCfgUserList(data.users);
            }
          }
        });
    };
    if (typeof user_list === "undefined" || user_list.length <= 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [user_list, group]);

  let recordLength = user_list.length;
  let copy = user_list;
  if (search) {
    const keyword = search.toLowerCase();

    copy = user_list.filter((row: CfgUser) => {
      for (let r in row) {
        if (
          typeof row[r] !== "object" &&
          row[r] &&
          row[r].toString().toLowerCase().indexOf(keyword) >= 0
        ) {
          return row;
        }
      }

      return null;
    });
    recordLength = copy.length;
  }

  const rows = copy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const body = rows.map((row, i) => (
    <TableRow key={i.toString()} className="atheletelist">
      <StyledTableCell>
        <Typography variant="body1">#{row.id}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        {row?.picture && (
          <div
            className="user-icon"
            style={{ backgroundImage: "url(" + row.picture + ")" }}
          ></div>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body1">
          <Link to={`/user/form/${row.id}`}>{row.name}</Link>
          <br />
          {row.email}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body1">
          {getFormatedDate(row.created_at)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body1">
          {getFormatedDate(row.updated_at)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="center" title={UserStatus[row.status]}>
        {row.status > 0 ? (
          <VerifiedUserIcon style={{ color: green[500] }} />
        ) : (
          <HourglassEmptyIcon color="secondary" />
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body1">
          {row?.position}
          {/* {row.access &&
            row.access.map((access, ix) => (
              <Fragment key={ix}>
                {ix.toString() !== "0" && <>, </>}
                <span key={ix}>
                  {typeof group !== "undefined" &&
                  access?.group_id === group ? (
                    <i>{access?.access_name}</i>
                  ) : (
                    <Link to={`/admin/config/user/${access?.group_id}`}>
                      {access?.access_name}
                    </Link>
                  )}
                </span>
              </Fragment>
            ))} */}
        </Typography>
      </StyledTableCell>
    </TableRow>
  ));

  if (!IsGrantedAccess("pikmembers")) {
    return <AuthDisplay />;
  } else {
    return (
      <>
        <Typography variant="h5">User Lists</Typography>
        {/* <TextField
        label="Find"
        className={classes.textField}
        placeholder="Find"
        value={search}
        onChange={onSearchChange}
      /> */}

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} sm={5}>
            <TextField
              label="Find"
              className={classes.textField}
              placeholder="Find"
              value={search}
              onChange={onSearchChange}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={7}>
            <div style={{ textAlign: "right" }}>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add New User"
                title="Add New User"
                component={Link}
                to={`/user/form/new`}
                className={classes.margin}
              >
                <AddIcon />
              </Fab>
            </div>
          </Grid>
        </Grid>

        <Paper className={classes.root}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow></TableRow>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  colSpan={9}
                  count={recordLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
              <TableRow className="tableheader">
                <StyledTableCell colSpan={2}>User ID</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Created</StyledTableCell>
                <StyledTableCell>Updated</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell>Position</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{body}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  colSpan={6}
                  count={recordLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </>
    );
  }
};

export default CfgUserList;
