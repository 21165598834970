// import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useLayoutEffect, useRef, useState } from "react";
import Geocoding from "./Geocoding";
import GoogleMapsProvider from "./GoogleMaps";
// import classes from "./Map.module.css";
import { Typography } from "@material-ui/core";
import CircleIcon from "@mui/icons-material/Circle";
import { Paper } from "@mui/material";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      // position: "absolute",
      // top: 0,
      // bottom: 0,
      // left: 0,
      // right: 0,
      width: "100%",
      height: "100%",
      border: "0px solid #ff0000",
    },

    map_container_grid: {
      border: "1px solid #cccccc",
      width: "100%",
      height: "100%",
    },

    map_grid: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
  })
);

interface MapProps {
  profile?: any;
  category?: any;
  features?: any[];
  filters?: any[];
  options?: any;
  mapOptions?: any;
  turnoflegend?: boolean;
  layerCallback?: (value: any) => void;
  mapCallback?: (value: any, value1: any) => void;
  mapboxCallback?: (value: any) => void;
  mapOnClick?: (value: any) => void;
}

const Map = ({
  category,
  profile,
  features,
  filters,
  options,
  turnoflegend,
  layerCallback,
  mapCallback,
  mapboxCallback,
  mapOnClick,
  mapOptions,
}: MapProps) => {
  // function Map() {
  const classes = useStyles();
  const [mapElement, setMapElement] = useState<HTMLDivElement>();

  const mapRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (mapRef.current) {
      setMapElement(mapRef.current);
    }
  }, [mapElement]);

  useLayoutEffect(() => {
    if (mapRef.current) {
      setMapElement(mapRef.current);
    }
  }, [features]);

  const grid = false;
  if (grid) {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-end"
        alignItems="stretch"
        className={classes.map_container_grid}
      >
        <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
          <div ref={mapRef} className={classes.map_grid} />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
          <div>
            <GoogleMapsProvider
              elem={mapElement}
              loaderOptions={{ apiKey: "" }}
              category={profile}
              profile={profile}
              mapCallback={mapCallback}
            >
              <Geocoding
                category={category}
                profile={profile}
                layerCallback={layerCallback}
                mapOnClick={mapOnClick}
                grid={true}
                features={features}
              />
            </GoogleMapsProvider>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        <GoogleMapsProvider
          elem={mapElement}
          loaderOptions={{
            apiKey: "AIzaSyBu0hVs2HgXXe0ly8wagam8q8Wmz-12bs8",
            libraries: ["visualization"],
          }}
          category={profile}
          profile={profile}
          mapCallback={mapCallback}
          mapOptions={mapOptions}
        >
          {(!options || (options && options?.mapbox)) && (
            <Geocoding
              category={category}
              profile={profile}
              layerCallback={layerCallback}
              mapOnClick={mapOnClick}
              mapboxCallback={mapboxCallback}
              features={features}
              filters={filters}
            />
          )}
        </GoogleMapsProvider>
        <div ref={mapRef} className={classes.map} />
        {!turnoflegend && (
          <div
            style={{
              position: "absolute",
              bottom: "40px",
              right: "30px",
              color: "#f5f5f5",
              zIndex: "5",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Paper elevation={2} sx={{ padding: "10px" }}>
              <Grid item container xs={12}>
                <CircleIcon
                  sx={{
                    color: "#d50000",
                    fontSize: "15px",
                    paddingRight: "5px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Premis
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <CircleIcon
                  sx={{
                    color: "#ffea00",
                    fontSize: "15px",
                    paddingRight: "5px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "13px",
                  }}
                >
                  Suspek
                </Typography>
              </Grid>
            </Paper>
          </div>
        )}
      </>
    );
  }
};

export default Map;
