import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { format } from "date-fns";
import { useParams, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../config/Firebase";
import Map from "../../../MapLight";

const SespectIndex = () => {
  const [profile, setProfile] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  let { id } = useParams();

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof profile === "undefined" && token !== "" && id !== "") {
      fetch("/api/crime/cases/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (body) {
          return body.json();
        })
        .then(function (data) {
          if (typeof data?.cases?.id === "undefined") {
            setProfile({});
          } else {
            setProfile(data);
          }

          // for (let i = 0; i < data.features.length; i++) {
          // }
        })
        .catch(function (err) {
          console.log(err);
          alert("Unable to fetch census data");
        });
    }
  }, [profile, token, id]);

  console.log("profile", profile);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={10}>
          <Typography variant="h6" component="p">
            Maklumat Kes:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4} md={2} style={{ textAlign: "right" }}>
          <Button variant="contained">Approved?</Button>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              height: "30vh",
              maxHeight: "500px",
              minHeight: "10vh",
              border: "0px solid #ff0000",
            }}
          >
            <Map />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            Laporan Id: #{profile?.cases?.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Sektor: {profile?.cases?.district}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Status: {profile?.cases?.status}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Kelulusan:{" "}
            {profile?.cases?.case_is_approved
              ? profile?.cases?.is_approved
              : "Menunggu Kelulusan"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Pelapor: {profile?.cases?.reporter_name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Tarikh Laporan:
            {profile?.cases?.created_at
              ? format(
                  new Date(profile?.cases?.created_at),
                  "dd MMMM yyyy HH:mm aa"
                )
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Tarikh Kemaskini Terakhir:
            {profile?.cases?.updated_at
              ? format(
                  new Date(profile?.cases?.updated_at),
                  "dd MMMM yyyy HH:mm aa"
                )
              : ""}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="p">
            Maklumat Suspek:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" component="p">
                Gambar Profil:
              </Typography>
              <Divider />
              <div
                style={{
                  height: "30vh",
                  width: "100%",
                  maxHeight: "500px",
                  minHeight: "10vh",
                  backgroundImage: `url(https://ck.tuxgeo.dev/api/photos/cawangankhas/suspect_profile_picture/${profile?.cases?.id})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    `https://ck.tuxgeo.dev/api/photos/cawangankhas/suspect_profile_picture/${profile?.cases?.id}`,
                    "profilephotos"
                  );
                }}
              ></div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" component="p">
                Gambar Kad Pengenalan:
              </Typography>
              <Divider />
              <div
                style={{
                  height: "30vh",
                  width: "100%",
                  maxHeight: "500px",
                  minHeight: "10vh",
                  backgroundImage: `url(https://ck.tuxgeo.dev/api/photos/cawangankhas/suspect_ic_picture/${profile?.cases?.id})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    `https://ck.tuxgeo.dev/api/photos/cawangankhas/suspect_ic_picture/${profile?.cases?.id}`,
                    "profilephotos"
                  );
                }}
              ></div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            Nama Suspek: {profile?.cases?.suspect_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            {profile?.cases?.suspect_description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Sindiket: {profile?.cases?.syndicate}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            No. Kad Pengenalan: {profile?.cases?.suspect_ic_no}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Sektor: {profile?.cases?.suspect_district}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="p">
            Maklumat Premis:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div
            style={{
              height: "30vh",
              width: "100%",
              maxHeight: "500px",
              minHeight: "10vh",
              backgroundImage: `url(https://ck.tuxgeo.dev/api/photos/cawangankhas/premise_picture/${profile?.cases?.id})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                `https://ck.tuxgeo.dev/api/photos/cawangankhas/premise_picture/${profile?.cases?.id}`,
                "profilephotos"
              );
            }}
          ></div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            Nama Premis: {profile?.cases?.premise_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            {profile?.cases?.premise_description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Jenis Premis: {profile?.cases?.premise_type}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Mula Beroperasi:
            {profile?.cases?.premise_started_date
              ? format(
                  new Date(profile?.cases?.premise_started_date),
                  "dd MMMM yyyy"
                )
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Sektor: {profile?.cases?.premise_district}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SespectIndex;
