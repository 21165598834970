import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Autocomplete,
  Button,
  LinearProgress,
  Popover,
  TextField,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { onAuthStateChanged } from "firebase/auth"; // New import
import * as React from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { useHistory, useParams } from "react-router-dom";
import bbox from "@turf/bbox";
import { auth } from "../../config/Firebase";
import Map from "../Map/Map";

const drawerWidth = 270;

function Index() {
  const [init, setInit] = React.useState<boolean>(false);
  const [bounds, setBounds] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  const { kategori_name } = useParams();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const [mapbox, setMapbox] = React.useState<any>();
  const [map, setMap] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [districtList, setDistrictList] = React.useState<any[]>();
  const [premiseType, setPremiseType] = React.useState<any[]>();
  const [filters, setFilters] = React.useState<any>({
    district: "",
    category: kategori_name,
    premise_name: "",
    reporter_name: "",
    premiseFrom: "",
    premiseTo: "",
  });
  const defaultSelected: DateRange = {
    from: undefined,
    to: undefined,
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [range, setRange] = React.useState<DateRange | undefined>(
    defaultSelected
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (!mapbox) {
      return;
    }

    mapbox.addSource("premise", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    mapbox.addLayer({
      id: "premise_point",
      type: "circle",
      source: "premise", // reference the data source
      layout: {},
      paint: {
        "circle-color": "#ff0000",
        "circle-opacity": 1,
        "circle-radius": {
          base: 2,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
      },
    });

    mapbox.addSource("suspect", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    mapbox.addLayer({
      id: "suspect",
      type: "circle",
      source: "suspect", // reference the data source
      layout: {},
      paint: {
        "circle-color": "#ffff00",
        "circle-opacity": 1,
        "circle-radius": {
          base: 2,
          stops: [
            [12, 300],
            [22, 1500],
          ],
        },
      },
    });

    mapbox.addSource("sarawak_district", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    // Add a new layer to visualize the polygon.
    mapbox.addLayer({
      id: "sarawak_district",
      type: "fill",
      source: "sarawak_district", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#DEE9F8", // blue color fill
        "fill-opacity": 0.2,
      },
    });
    // Add a black outline around the polygon.
    mapbox.addLayer({
      id: "outline",
      type: "line",
      source: "sarawak_district",
      layout: {},
      paint: {
        "line-color": "#e9e7fa",
        "line-width": 0.3,
      },
    });

    return () => {
      if (mapbox) {
        if (mapbox.getLayer("suspect")) {
          mapbox.removeLayer("suspect");
        }
        if (mapbox.getLayer("premise_point")) {
          mapbox.removeLayer("premise_point");
        }
        if (mapbox.getLayer("sarawak_district")) {
          mapbox.removeLayer("sarawak_district");
        }
        if (mapbox.getLayer("outline")) {
          mapbox.removeLayer("outline");
        }

        if (mapbox.getSource("suspect")) {
          mapbox.removeSource("suspect");
        }
        if (mapbox.getSource("premise")) {
          mapbox.removeSource("premise");
        }
        if (mapbox.getSource("sarawak_district")) {
          mapbox.removeSource("sarawak_district");
        }
      }
    };
  }, [mapbox]);

  // const handleMapData = () => {
  const handleMapData = React.useCallback(() => {
    if (init === true) {
      setLoading(false);
      return false;
    }
    const queryString = createQueryString(filters);
    setLoading(true);

    const premiseData = () =>
      fetch("/api/map/crime/premise?" + queryString, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (data && mapbox && mapbox.getSource("premise")) {
            mapbox.getSource("premise").setData(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    const suspectData = () =>
      fetch("/api/map/crime/suspect?" + queryString, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (data && mapbox && mapbox.getSource("suspect")) {
            mapbox.getSource("suspect").setData(data);
          }
          console.log("datatatat", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    const districtPolygon = () =>
      fetch(`/api/map/district?name=${filters.district}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (data && mapbox && mapbox.getSource("sarawak_district")) {
            mapbox.getSource("sarawak_district").setData(data);
          }
          if (
            typeof data?.features !== "undefined" &&
            data.features.length > 0
          ) {
            var boundbox = bbox(data);
            setBounds(boundbox);
          }
        });

    // Create an array of Promises
    const promises = [premiseData(), suspectData(), districtPolygon()];
    // Use Promise.all to wait for all Promises to resolve
    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, [filters, mapbox, token, init]);

  const mapboxCallback = (newmap: any) => {
    if (
      typeof mapbox === "undefined" &&
      typeof google !== "undefined" &&
      typeof newmap !== "undefined"
    ) {
      setMapbox(newmap);
    }
  };

  React.useEffect(() => {
    if (typeof map?.map !== "undefined" && typeof bounds !== "undefined") {
      var bb = new map.google.maps.LatLngBounds();
      bb.extend({
        lat: bounds[1],
        lng: bounds[0],
      });
      bb.extend({
        lat: bounds[3],
        lng: bounds[2],
      });
      map?.map.fitBounds(bb, { padding: 20 });
    }
  }, [bounds, map]);

  let mapCallback = (newmap: any, maphandler: any) => {
    if (typeof map === "undefined" && typeof maphandler !== "undefined") {
      //   const darkMap = GoogleDarkMap as google.maps.MapTypeStyle[];

      maphandler.setOptions({
        // styles: darkMap,
        center: { lat: 2.5559400616701495, lng: 113.02748230696474 },
        zoom: 7,
      });

      setMap({
        google: newmap,
        map: maphandler,
      });
    }
  };

  const handleKembali = () => {
    history.push("/dashboard/kategori");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearch = (key: any, value: any) => {
    // Assuming filters is an object representing your search filters
    setFilters({ ...filters, [key]: value });
  };

  const createQueryString = (filters: any) => {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (filters[key]) {
        // Only add parameters with values
        params.append(key, filters[key]);
      }
    }
    return params.toString();
  };

  const handleClickCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCalendarChange(newRange: DateRange | undefined) {
    setRange(newRange);

    if (newRange && newRange.from && newRange.to) {
      const formattedFrom = format(newRange.from, "yyyy/MM/dd");
      const formattedTo = format(newRange.to, "yyyy/MM/dd");
      // setFilters({ ...filters, dateRange: `${formattedFrom} - ${formattedTo}` });
      setFilters({
        ...filters,
        premiseFrom: `${formattedFrom}`,
        premiseTo: `${formattedTo}`,
      });
    } else {
      setFilters({ ...filters, premiseFrom: "", premiseTo: "" });
    }
  }

  React.useEffect(() => {
    if (!token) return;

    setLoading(true);

    handleMapData();
    setInit(true);

    return () => {
      setLoading(true);
    };
  }, [token, mapbox, kategori_name, handleMapData]);

  React.useEffect(() => {
    if (!token) return;

    fetch("/api/map/district/list", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        if (data.list) {
          setDistrictList(
            data.list.map((row: any) => {
              return {
                id: row.id,
                label: row.district,
              };
            })
          );
        }
      });
  }, [token]);

  function resetRange() {
    setRange({ from: undefined, to: undefined });
    setFilters({ ...filters, premiseFrom: "", premiseTo: "" });
  }

  const drawer = (
    <div>
      <Toolbar>
        <IconButton onClick={handleKembali}>
          <ArrowBackIcon />
          <Typography marginLeft="15px">Kembali</Typography>
        </IconButton>
      </Toolbar>
      <Divider />
      <Box margin="10px 15px 0px 15px">
        <Autocomplete
          size="small"
          disablePortal
          id="district"
          options={districtList ? districtList : []}
          loading={!districtList}
          getOptionLabel={(option) => (option ? option.label : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          fullWidth
          sx={{ backgroundColor: "white", marginBottom: "10px" }}
          renderInput={(params) => (
            <TextField {...params} label="Pilih Daerah" />
          )}
          onChange={(_, value) =>
            handleSearch("district", value ? value.label : "")
          }
        />

        <TextField
          size="small"
          id="premise_name"
          label="Nama Premis"
          type="search"
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e) => handleSearch("premise_name", e.target.value)}
        />
        <TextField
          size="small"
          id="syndicate"
          label="Nama Sindiket"
          type="search"
          fullWidth
          sx={{ marginBottom: "10px" }}
          onChange={(e) => handleSearch("syndicate", e.target.value)}
        />
        <TextField
          size="small"
          id="suspect_name"
          label="Nama Suspek"
          type="search"
          fullWidth
          sx={{ marginBottom: "10px" }}
          onChange={(e) => handleSearch("suspect_name", e.target.value)}
        />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              "&:hover": {
                border: "1px solid black",
              },
              border: "1px solid #bcbcbc",
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              marginBottom: "40px",
              fill: "#004C5B",
              opacity: range?.from && range?.to ? 1 : 0.7,
            }}
            onClick={handleClickCalendar}
          >
            {range && range.from && range.to
              ? `${format(range.from, "yyyy/MM/dd")} -  ${format(
                  range.to,
                  "yyyy/MM/dd"
                )}`
              : "Julat Tarikh Mula Beroperasi"}
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DayPicker
              mode="range"
              captionLayout="dropdown-buttons"
              selected={range}
              onSelect={handleCalendarChange}
            />
            <Box margin="0px 5px 5px" display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                color="error"
                size="small"
                sx={{ marginRight: "5px" }}
                onClick={resetRange}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#2020ff",
                  "&:hover": {
                    backgroundColor: "#3a3aff",
                  },
                }}
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                Selesai
              </Button>
            </Box>
          </Popover>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            // marginBottomn: "10px",
          }}
        >
          <Typography
            sx={{
              "&:hover": {
                border: "1px solid black",
              },
              border: "1px solid #bcbcbc",
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              marginBottom: "30px",
              fill: "#004C5B",
              opacity: range?.from && range?.to ? 1 : 0.7,
            }}
            onClick={handleClickCalendar}
          >
            {range && range.from && range.to
              ? `${format(range.from, "yyyy/MM/dd")} -  ${format(
                  range.to,
                  "yyyy/MM/dd"
                )}`
              : "Julat Laporan Dibuat"}
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DayPicker
              mode="range"
              captionLayout="dropdown-buttons"
              selected={range}
              onSelect={handleCalendarChange}
            />
            <Box margin="0px 5px 5px" display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                color="error"
                size="small"
                sx={{ marginRight: "5px" }}
                onClick={resetRange}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#2020ff",
                  "&:hover": {
                    backgroundColor: "#3a3aff",
                  },
                }}
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                Selesai
              </Button>
            </Box>
          </Popover>
        </Box>

        <Box display="flex" justifyContent="space-between" padding="5px">
          {/* <Button fullWidth color="error" sx={{ marginRight: "3px" }}>
            Reset
          </Button> */}
          <Button
            fullWidth
            sx={{
              backgroundColor: "#2C3A97",
              color: "white",
              "&:hover": {
                backgroundColor: "darkblue",
              },
              // marginLeft: "3px",
            }}
            // onClick={handleMapData}
            onClick={() => setInit(false)}
          >
            Cari
          </Button>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#000000",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            letterSpacing={2}
            fontSize={18}
          >
            Map Checker
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box display={loading ? "block" : "none"} width="100%">
          <LinearProgress />
        </Box>
        <Box
          width="100%"
          height="calc(100vh - 64px)"
          display={loading ? "none" : "block"}
          style={{ position: "relative" }}
        >
          <Map mapboxCallback={mapboxCallback} mapCallback={mapCallback} />
        </Box>
      </Box>
    </Box>
  );
}

export default Index;
