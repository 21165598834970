import CircularProgress from "@material-ui/core/CircularProgress";
import {
  CheckCircle,
  DashboardCustomizeRounded,
  Folder,
  Group,
  MapOutlined,
  NavigateNext,
  Visibility,
} from "@mui/icons-material";
import {
  Autocomplete,
  Breadcrumbs,
  Divider,
  Link,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { onAuthStateChanged } from "firebase/auth"; // New import
import * as React from "react";
import { auth } from "../../config/Firebase";
import DoughnutChart from "../Charts/Doughnut";
import { GoogleDarkMap } from "../Map/GMap.darkMap";
import bbox from "@turf/bbox";
import Map from "../Map/Map";
import "../Map/Showcase.css";
import InfoBox from "./InfoBox";
import "./index.css";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const defaultInfoBoxes = [
  {
    value: 0,
    label: "Anggota Berdaftar",
    icon: <Group sx={{ fontSize: 50, color: "#2C2B78" }} fontSize="large" />,
  },
  {
    value: 0,
    label: "Kes Ditutup",
    icon: (
      <CheckCircle sx={{ fontSize: 50, color: "#209A33" }} fontSize="large" />
    ),
  },
  {
    value: 0,
    label: "Laporan Kes",
    icon: (
      <Visibility sx={{ fontSize: 50, color: "#000000" }} fontSize="large" />
    ),
  },
  {
    value: 0,
    label: "Bilangan Daerah",
    icon: (
      <MapOutlined sx={{ fontSize: 50, color: "#000000" }} fontSize="large" />
    ),
  },
];

const DefaultContent = () => {
  const [filters, setFilters] = React.useState<any>({
    district: "",
  });
  const [bounds, setBounds] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  const [infoBoxes, setInfoBoxes] = React.useState<any[]>();
  const [doughnutChartData, setDoughnutChartData] = React.useState<any>();
  const [mapbox, setMapbox] = React.useState<any>();
  const [map, setMap] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [premisetypes, setPremiseType] = React.useState<any[]>([]);

  const [districtList, setDistrictList] = React.useState<any[]>();

  const mapboxCallback = (newmap: any) => {
    if (
      typeof mapbox === "undefined" &&
      typeof google !== "undefined" &&
      typeof newmap !== "undefined"
    ) {
      setMapbox(newmap);
    }
  };

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (!mapbox) {
      return;
    }

    mapbox.addSource("sarawak_district", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    // Add a new layer to visualize the polygon.
    mapbox.addLayer({
      id: "sarawak_district",
      type: "fill",
      source: "sarawak_district", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#DEE9F8", // blue color fill
        "fill-opacity": 0.2,
      },
    });
    // Add a black outline around the polygon.
    mapbox.addLayer({
      id: "outline",
      type: "line",
      source: "sarawak_district",
      layout: {},
      paint: {
        "line-color": "#e9e7fa",
        "line-width": 0.3,
      },
    });

    mapbox.addSource("suspect", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    mapbox.addLayer({
      id: "suspect",
      type: "circle",
      source: "suspect", // reference the data source
      layout: {},
      paint: {
        "circle-color": "#ffff00",
        "circle-opacity": 1,
        "circle-radius": {
          base: 2,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
      },
    });

    mapbox.addSource("premise", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    mapbox.addLayer({
      id: "premise_point",
      type: "circle",
      source: "premise", // reference the data source
      layout: {},
      paint: {
        "circle-color": "#ff0000",
        "circle-opacity": 1,
        "circle-radius": {
          base: 2,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
      },
    });

    return () => {
      if (mapbox) {
        if (mapbox.getLayer("suspect")) {
          mapbox.removeLayer("suspect");
        }
        if (mapbox.getLayer("premise_point")) {
          mapbox.removeLayer("premise_point");
        }
        if (mapbox.getLayer("sarawak_district")) {
          mapbox.removeLayer("sarawak_district");
        }
        if (mapbox.getLayer("outline")) {
          mapbox.removeLayer("outline");
        }

        if (mapbox.getSource("suspect")) {
          mapbox.removeSource("suspect");
        }
        if (mapbox.getSource("premise")) {
          mapbox.removeSource("premise");
        }
        if (mapbox.getSource("sarawak_district")) {
          mapbox.removeSource("sarawak_district");
        }
      }
    };
  }, [mapbox]);

  React.useEffect(() => {
    setLoading(true);
    if (!token) return;

    const dashboardData = () =>
      fetch(`/api/dashboard?district=${filters.district}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (!data.dashboard) {
            throw new Error("No data");
          }

          setInfoBoxes([
            {
              value: data.dashboard.total_registered_users,
              label: "Anggota Berdaftar",
              icon: (
                <Group
                  sx={{ fontSize: 50, color: "#2C2B78" }}
                  fontSize="large"
                />
              ),
            },
            {
              value: data.dashboard.case_closed,
              label: "Kes Ditutup",
              icon: (
                <CheckCircle
                  sx={{ fontSize: 50, color: "#209A33" }}
                  fontSize="large"
                />
              ),
            },
            {
              value: data.dashboard.case_report,
              label: "Laporan Kes",
              icon: (
                <Visibility
                  sx={{ fontSize: 50, color: "#000000" }}
                  fontSize="large"
                />
              ),
            },
            {
              value: data.dashboard.total_district,
              label: "Bilangan Daerah",
              icon: (
                <MapOutlined
                  sx={{ fontSize: 50, color: "#000000" }}
                  fontSize="large"
                />
              ),
            },
          ]);

          const randomColors = data.dashboard.total_surveyed_each_district.map(
            () => getRandomColor()
          );

          setDoughnutChartData({
            labels: data.dashboard.total_surveyed_each_district.map(
              (row: any) => row.district
            ),
            datasets: [
              {
                data: data.dashboard.total_surveyed_each_district.map(
                  (row: any) => row.count
                ),
                backgroundColor: randomColors,
                hoverOffset: 4,
              },
            ],
          });

          setPremiseType(data.dashboard.total_surveyed_premise_type);
          if (
            data.dashboard.cases_map.premise &&
            mapbox &&
            mapbox.getSource("premise")
          ) {
            mapbox
              .getSource("premise")
              .setData(data.dashboard.cases_map.premise);
          }
          if (
            data.dashboard.cases_map.suspect &&
            mapbox &&
            mapbox.getSource("suspect")
          ) {
            mapbox
              .getSource("suspect")
              .setData(data.dashboard.cases_map.suspect);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    const districtPolygon = () =>
      fetch(`/api/map/district?name=${filters.district}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (data && mapbox.getSource("sarawak_district")) {
            mapbox.getSource("sarawak_district").setData(data);
          }
          if (
            typeof data?.features !== "undefined" &&
            data.features.length > 0
          ) {
            var boundbox = bbox(data);
            setBounds(boundbox);
          }
        });

    // Create an array of Promises
    const promises = [dashboardData(), districtPolygon()];
    // Use Promise.all to wait for all Promises to resolve
    Promise.all(promises)
      .then(() => {
        // Both functions have completed fetching
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during fetching
        console.error("Error:", error);
        setLoading(false);
      });

    return () => {
      setLoading(true);
      setInfoBoxes(defaultInfoBoxes);
      setDoughnutChartData({});
    };
  }, [token, mapbox, filters]);

  React.useEffect(() => {
    if (!token) return;

    fetch("/api/map/district/list", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        if (data.list) {
          setDistrictList(
            data.list.map((row: any) => {
              return {
                id: row.id,
                label: row.district,
              };
            })
          );
        }
      });
  }, [token]);

  React.useEffect(() => {
    if (typeof map?.map !== "undefined" && typeof bounds !== "undefined") {
      var bb = new map.google.maps.LatLngBounds();
      bb.extend({
        lat: bounds[1],
        lng: bounds[0],
      });
      bb.extend({
        lat: bounds[3],
        lng: bounds[2],
      });
      map?.map.fitBounds(bb, { padding: 20 });
    }
  }, [bounds, map]);

  let mapCallback = (newmap: any, maphandler: any) => {
    if (typeof map === "undefined" && typeof maphandler !== "undefined") {
      const darkMap = GoogleDarkMap as google.maps.MapTypeStyle[];

      maphandler.setOptions({
        styles: darkMap,
        center: { lat: 2.5559400616701495, lng: 113.02748230696474 },
        zoom: 6.5,
      });

      setMap({
        google: newmap,
        map: maphandler,
      });
    }
  };

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a kategori.");
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={`/dashboard/`}
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      Dashboard
    </Link>,
  ];

  return (
    <>
      <Grid item container xs={12} direction="row">
        <DashboardCustomizeRounded fontSize="large" />
        <Typography sx={{ padding: "10px" }}>DASHBOARD</Typography>
      </Grid>
      <Divider sx={{ marginBottom: "20px" }} variant="fullWidth" />
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {filters?.district ? filters?.district : "SARAWAK"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          // md={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Autocomplete
            disablePortal
            id="districtList"
            options={districtList ? districtList : []}
            loading={!districtList}
            getOptionLabel={(option) => (option ? option.label : "")}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            sx={{ width: 150, backgroundColor: "white" }}
            renderInput={(params) => (
              <TextField {...params} label="Pilih Daerah" size="small" />
            )}
            onChange={(_, value) => {
              setFilters({
                district: value ? value.label : "",
              });
            }}
          />
        </Grid>
      </Grid>

      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              minHeight: "500px",
              marginTop: "20px",
              position: "relative",
            }}
          >
            <Map mapboxCallback={mapboxCallback} mapCallback={mapCallback} />
          </Paper>
        </Grid>

        {infoBoxes &&
          infoBoxes.map((info: any, idx: any) => (
            <Grid item key={idx} xs={12} sm={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  // height: "100%",
                  marginTop: "25px",
                }}
              >
                <InfoBox
                  label={info.label}
                  value={info.value}
                  icon={info.icon}
                  caption={info.caption}
                />
              </Paper>
            </Grid>
          ))}

        <Grid item xs={12} sm={6} xl={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              minHeight: "300px",
            }}
          >
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  className="cardtitle"
                  sx={{ color: "#43425D", fontWeight: "bolder" }}
                >
                  Tinjauan Mengikut Daerah
                  {/* {`${filters.state_id ? "Cawangan" : "Negeri"}`} */}
                </Typography>
              </Grid>
            </Grid>
            {typeof doughnutChartData?.datasets !== "undefined" &&
            doughnutChartData?.datasets.length > 0 &&
            doughnutChartData?.labels.length > 0 ? (
              <div className="cardcontent">
                <DoughnutChart
                  data={doughnutChartData}
                  option={{ hoverOffset: 20 }}
                  // callback={pieChartCallback}
                  // refCallback={refPieChartCallback}
                />
              </div>
            ) : (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>Tiada maklumat di daerah ini</Typography>
              </Grid>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} xl={6}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              minHeight: "300px",
            }}
          >
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={11}>
                <Typography
                  variant="body1"
                  className="cardtitle"
                  sx={{ color: "#43425D", fontWeight: "bolder" }}
                >
                  Jenis Premis Perjudian Online
                  {/* {`${filters.state_id ? "Cawangan" : "Negeri"}`} */}
                </Typography>
              </Grid>
            </Grid>

            <div className="cardcontent">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
              >
                {premisetypes && premisetypes.length > 0 ? (
                  premisetypes.map((row, idx) => (
                    <Grid
                      item
                      key={idx}
                      xs={12}
                      sm={6}
                      style={{ textAlign: "center" }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          fontSize: "large",
                        }}
                      >
                        <Folder />
                        <br />
                        <Typography variant="caption" className="cardcaption">
                          {row.premise_type}
                        </Typography>
                        <br />
                        <Typography
                          variant="caption"
                          className="cardcaptionvalue"
                        >
                          {row.count}
                        </Typography>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                    <Typography>Tiada maklumat di daerah ini</Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default function Default() {
  return <DefaultContent />;
}
