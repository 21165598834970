import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MapIcon from "@mui/icons-material/Room";
import LayersIcon from "@mui/icons-material/Layers";
import CategoryIcon from "@mui/icons-material/Category";

import { Folder } from "@mui/icons-material";

// import Collapse from "@mui/material/Collapse";
// import List from "@mui/material/List";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";

// import AssignmentIcon from "@mui/icons-material/Assignment";
import { Link } from "react-router-dom";

export const MainListItems = ({ path, status }: any) => {
  // const [open, setOpen] = React.useState(true);
  // const [openuser, setOpenUser] = React.useState(true);
  // const handleClick = () => {
  //   setOpen(!open);
  // };
  // const handleClickUser = () => {
  //   setOpenUser(!openuser);
  // };
  // console.log(path);
  return (
    <React.Fragment>
      <ListItemButton
        component={Link}
        to="/dashboard/"
        selected={/^\/dashboard\/$/i.test(path)}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Dashboard" />}
      </ListItemButton>
      {/* <ListItemButton
        component={Link}
        to="/dashboard/dataset"
        selected={/^\/dashboard\/dataset/i.test(path)}
      >
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Rekod Perniagaan" />}
      </ListItemButton> */}

      <ListItemButton
        component={Link}
        to="/dashboard/kategori"
        selected={/^\/dashboard\/kategori/i.test(path)}
      >
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Kategori" />}
      </ListItemButton>

      <ListItemButton
        component={Link}
        to="/dashboard/map"
        selected={/^\/dashboard\/map/i.test(path)}
      >
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Heat Map Kawasan" />}
      </ListItemButton>

      <ListItemButton
        component={Link}
        to="/dashboard/operation"
        selected={/^\/dashboard\/operation/i.test(path)}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Status Terkini Tinjauan" />}
      </ListItemButton>

      {/* <ListItemButton
        component={Link}
        to="/dashboard/tracking"
        selected={/^\/dashboard\/tracking/i.test(path)}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Lokasi Terkini Pegawai" />}
      </ListItemButton> */}
      <ListItemButton
        component={Link}
        to="/dashboard/senarai-laporan"
        selected={/^\/dashboard\/senarai-laporan$/i.test(path)}
      >
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        {status && <ListItemText primary="Senarai Laporan" />}
      </ListItemButton>

      <ListItemButton
      // component={Link}
      // to="/dashboard/operation"
      // selected={/^\/dashboard\/operation/i.test(path)}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Admin" />}
      </ListItemButton>

      {/* enabled for latest fobo changes, disabled for partial deploy */}
      {/* <ListItemButton
        // component={Link}
        // to="/dashboard/stats"
        selected={/^\/dashboard\/stats/i.test(path)}
        onClick={handleClick}
      >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Statistik Penggunaan" />}

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/stats/fobo"
            selected={/^\/dashboard\/stats\/fobo$/i.test(path)}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="FOBO" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/stats"
            selected={/^\/dashboard\/stats$/i.test(path)}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Cawangan - Lama" />
          </ListItemButton>
        </List>
      </Collapse> */}

      {/* <ListItemButton
        component={Link}
        to="/dashboard/user"
        selected={/^\/dashboard\/user$/i.test(path)}
      >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Statistik Pengguna" />
      </ListItemButton> */}

      {/* enabled for latest fobo changes, disabled for partial deploy */}
      {/* <ListItemButton
        selected={/^\/dashboard\/user/i.test(path)}
        onClick={handleClickUser}
      >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        {status && <ListItemText primary="Statistik Pengguna" />}

        {openuser ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openuser} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/user/fobo"
            selected={/^\/dashboard\/user\/fobo$/i.test(path)}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="FOBO" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/user"
            selected={/^\/dashboard\/user$/i.test(path)}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Cawangan - Lama" />
          </ListItemButton>
        </List>
      </Collapse> */}

      <ListItemButton
        component={Link}
        to="/dashboard/logout"
        selected={/^\/dashboard\/logout/i.test(path)}
      >
        <ListItemIcon>
          <ExitToAppIcon style={{ color: "red" }} />
        </ListItemIcon>
        {status && <ListItemText primary="Log Keluar" />}
      </ListItemButton>
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);
