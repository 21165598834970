import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import { FormGroup, Input } from "reactstrap";
// import AuthContainer from "../../components/AuthContainer/Index";
import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";

// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a href="https://hasil.gov.my/" target="_blank" rel="noreferrer">
        CK
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "100px",
    height: "70px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
  },
}));

// export default function SignIn() {
const RegisterPage: React.FunctionComponent<IPageProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [registering, setRegistering] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const signUpWithEmailAndPassword = (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (email === "") {
      setError("Please enter your email address.");
      return;
    }
    if (password !== confirm) {
      setError("Please make sure your passwords match.");
      return;
    }

    if (error !== "") setError("");

    setRegistering(true);

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((result: any) => {
        logging.info(result);

        auth.onAuthStateChanged((user: any) => {
          if (user && !user.emailVerified) {
            user.sendEmailVerification().then(() => {
              history.push("/");
            });
          }
        });
      })
      .catch((error: any) => {
        logging.error(error);

        if (error.code.includes("auth/weak-password")) {
          setError("Please enter a stronger password.");
        } else if (error.code.includes("auth/email-already-in-use")) {
          setError("Email already in use.");
        } else {
          setError("Unable to register.  Please try again later.");
        }

        setRegistering(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar} src="/images/lhdn.png?v=1">
          <LockOutlinedIcon />
        </Avatar> */}
        <img
          src="/images/lhdn.png?v=1"
          alt="CK"
          height="100"
          className="mcmclogo"
        />
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <ErrorText error={error} />
        <form
          className={classes.form}
          noValidate
          onSubmit={(e: any) => signUpWithEmailAndPassword(e)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Confirm Password"
            label="Confirm Password"
            type="password"
            id="Confirm Password"
            autoComplete="new-password"
            onChange={(event) => setConfirm(event.target.value)}
          />
          <Button
            disabled={registering}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="center">
            <br />
            <Grid>
              <Link to="/login" variant="body2">
                Already have an account?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ padding: "25px" }}>
        <Copyright />
      </div>
    </Container>
  );
};

export default RegisterPage;

// import React, { useState } from "react";
// import { Link, useHistory } from "react-router-dom";
// import { Button, FormGroup, Input } from "reactstrap";
// import AuthContainer from "../../components/AuthContainer/Index";
// import ErrorText from "../../components/ErrorText/Index";
// import { auth } from "../../config/Firebase";
// import logging from "../../config/Logging";
// import IPageProps from "../../interfaces/Page";

// const RegisterPage: React.FunctionComponent<IPageProps> = (props) => {
//   const [registering, setRegistering] = useState<boolean>(false);
//   const [email, setEmail] = useState<string>("");
//   const [password, setPassword] = useState<string>("");
//   const [confirm, setConfirm] = useState<string>("");
//   const [error, setError] = useState<string>("");

//   const history = useHistory();

//   const signUpWithEmailAndPassword = () => {
//     if (password !== confirm) {
//       setError("Please make sure your passwords match.");
//       return;
//     }

//     if (error !== "") setError("");

//     setRegistering(true);

//     auth
//       .createUserWithEmailAndPassword(email, password)
//       .then((result:any) => {
//         logging.info(result);
//         history.push("/login");
//       })
//       .catch((error: any) => {
//         logging.error(error);

//         if (error.code.includes("auth/weak-password")) {
//           setError("Please enter a stronger password.");
//         } else if (error.code.includes("auth/email-already-in-use")) {
//           setError("Email already in use.");
//         } else {
//           setError("Unable to register.  Please try again later.");
//         }

//         setRegistering(false);
//       });
//   };

//   return (
//     <AuthContainer header="Register">
//       <FormGroup>
//         <Input
//           type="email"
//           name="email"
//           id="email"
//           placeholder="Email Address"
//           onChange={(event) => setEmail(event.target.value)}
//           value={email}
//         />
//       </FormGroup>
//       <FormGroup>
//         <Input
//           autoComplete="new-password"
//           type="password"
//           name="password"
//           id="password"
//           placeholder="Enter Password"
//           onChange={(event) => setPassword(event.target.value)}
//           value={password}
//         />
//       </FormGroup>
//       <FormGroup>
//         <Input
//           autoComplete="new-password"
//           type="password"
//           name="confirm"
//           id="confirm"
//           placeholder="Confirm Password"
//           onChange={(event) => setConfirm(event.target.value)}
//           value={confirm}
//         />
//       </FormGroup>
//       <Button
//         disabled={registering}
//         color="success"
//         block
//         onClick={() => signUpWithEmailAndPassword()}
//       >
//         Sign Up
//       </Button>
//       <small>
//         <p className="m-1 text-center">
//           Already have an account? <Link to="/login">Login.</Link>
//         </p>
//       </small>
//       <ErrorText error={error} />
//     </AuthContainer>
//   );
// };

// export default RegisterPage;
