import * as React from "react";
// import { useState } from "react";

import Map from "./Map";
import { GoogleDarkMap } from "./GMap.darkMap";

import "./Showcase.css";

const Index = ({
  features,
  options,
  mapcallback,
  reDrawFeatures,
  focusLatestFeature,
  popupLatestFeature,
  popupLatestFeatureField,
  focusfeature,
  focusfeaturepopup,
  popupContentColumns,
  zoomLatestFeature,
}: any) => {
  // const [businesslists, setBusinessLists] = useState<any[]>();

  const [map, setMap] = React.useState<any>();
  const [infoWindow, setInfoWindow] = React.useState<any>();

  const createContent = React.useCallback(
    (properties: any) => {
      let apiurl: string = window.location.hostname;
      if (/(127.0.0.1|localhost|172.16.1.62)/i.test(window.location.hostname)) {
        apiurl = "ck.tuxgeo.dev";
      }

      // console.log(popupContentColumns);
      let htmls: string[] = [];
      let images: string[] = [];
      if (
        typeof popupContentColumns !== "undefined" &&
        popupContentColumns.length > 0
      ) {
        for (let c = 0; c < popupContentColumns.length; c++) {
          let value: string = properties[popupContentColumns[c].id];
          if (/./i.test(popupContentColumns[c].id)) {
            value = resolvePath(popupContentColumns[c].id, properties);
          }

          if (popupContentColumns[c].isImage) {
            if (value !== "" && value !== null) {
              images.push("<tr>");
              images.push("<td colspan=2 align='center'>");
              if (popupContentColumns[c].id === "image_id") {
                images.push(
                  "<img style='max-width: 300px; width: 100%' src='https://" +
                    apiurl +
                    "/api/photos/" +
                    value +
                    "' />"
                );
              } else {
                images.push(
                  "<img style='max-width: 300px; width: 100%' src='" +
                    value +
                    "' />"
                );
              }
              images.push("</td>");
              images.push("</tr>");
            }
          } else {
            if (typeof value !== "object" && value) {
              htmls.push("<tr>");
              if (popupContentColumns[c].label !== "") {
                htmls.push("<td>");
                htmls.push(popupContentColumns[c].label);
                htmls.push(":</td>");
                htmls.push("<td>");
              } else {
                htmls.push("<td colspan=2>");
              }
              if (popupContentColumns[c].label === "") {
                htmls.push("<b>");
              }
              htmls.push(value);
              if (popupContentColumns[c].label === "") {
                htmls.push("</b>");
              }
              htmls.push("</td>");
              htmls.push("</tr>");
            }
          }
        }
      } else {
        for (let p in properties) {
          if (
            typeof properties[p] !== "object" &&
            !/^_/i.test(p) &&
            p !== "image_id"
          ) {
            htmls.push("<tr>");
            htmls.push("<td>");
            htmls.push(p);
            htmls.push(":</td>");
            htmls.push("<td>");
            htmls.push(properties[p]);
            htmls.push("</td>");
            htmls.push("</tr>");
          } else {
            if (
              p === "image_id" &&
              typeof properties[p] !== "object" &&
              properties[p] !== ""
            ) {
              images.push("<tr>");
              images.push("<td colspan=2 align='center'>");
              images.push(
                "<img style='max-width: 300px; width: 100%' src='https://" +
                  apiurl +
                  "/api/photos/" +
                  properties[p] +
                  "' />"
              );
              images.push("</td>");
              images.push("</tr>");
            }
          }
        }
      }
      const html =
        "<table>" + images.join("") + "" + htmls.join("") + "</table>";
      return html;
    },
    [popupContentColumns]
  );

  React.useEffect(() => {
    if (
      typeof map?.map !== "undefined" &&
      typeof focusfeature !== "undefined" &&
      typeof infoWindow !== "undefined"
    ) {
      // console.log(focusfeature);
      if (focusfeaturepopup) {
        infoWindow.setContent(createContent(focusfeature.properties));
        infoWindow.setPosition({
          lat: focusfeature?.geometry?.coordinates[1],
          lng: focusfeature?.geometry?.coordinates[0],
        });
        infoWindow.open({
          // anchor: marker,
          map: map.map,
          shouldFocus: true,
        });
      } else {
        var bounds = new map.google.maps.LatLngBounds();

        if (
          typeof focusfeature.properties?._viewbox !== "undefined" &&
          focusfeature.properties?._viewbox?.type === "Polygon"
        ) {
          for (
            let c = 0;
            c < focusfeature.properties?._viewbox?.coordinates[0].length;
            c++
          ) {
            // console.log(focusfeature.properties?._viewbox?.coordinates[0][c]);
            bounds.extend({
              lat: focusfeature.properties?._viewbox?.coordinates[0][c][1],
              lng: focusfeature.properties?._viewbox?.coordinates[0][c][0],
            });
          }
        } else {
          // console.log(focusfeature);
          if (focusfeature.geometry.type === "Point") {
            bounds.extend({
              lat: focusfeature.geometry.coordinates[1],
              lng: focusfeature.geometry.coordinates[0],
            });
          }
        }

        map.map.fitBounds(bounds);
      }
    }
  }, [focusfeature, focusfeaturepopup, infoWindow, map, createContent]);

  React.useEffect(() => {
    if (
      typeof map !== "undefined" &&
      typeof features !== "undefined" &&
      typeof focusfeature === "undefined"
    ) {
      if (reDrawFeatures) {
        map.map.data.forEach(function (feature: any) {
          map.map.data.remove(feature);
        });
      }

      map.map.data.addGeoJson({
        type: "FeatureCollection",
        features: features,
      });

      map.map.data.setStyle(function (feature: any) {
        // var icon = feature.getProperty("icon");
        // if (feature.getProperty("isHighlighted"))
        let icon: string = feature.getProperty("_icon");
        if (!icon || icon === "") {
          icon = "/images/markers/marker-red.png";
        }
        return {
          icon: icon,
        };
      });

      if (features.length > 0) {
        let feature: any = {};
        var bounds = new map.google.maps.LatLngBounds();
        for (let f = 0; f < features.length; f++) {
          if (/(point)/i.test(features[f].geometry.type)) {
            bounds.extend({
              lat: features[f].geometry.coordinates[1],
              lng: features[f].geometry.coordinates[0],
            });
            feature = features[f];
            if (focusLatestFeature) {
              break;
            }
          }
        }
        if (typeof zoomLatestFeature !== "undefined" && zoomLatestFeature) {
          map.map.fitBounds(bounds, 100);
        }

        map.map.data.addListener("click", (event: any) => {
          // console.log(event.feature);
          let properties: any = {};
          event.feature.forEachProperty((value: any, key: any) => {
            // console.log(key, prop);
            properties[key] = value;
          });
          infoWindow.setContent(createContent(properties));
          infoWindow.setPosition(event.latLng.toJSON());
          infoWindow.open({
            // anchor: new google.maps.Point(8, 24),
            map: map.map,
            shouldFocus: true,
          });
        });

        if (focusLatestFeature && popupLatestFeature) {
          infoWindow.setContent(createContent(feature.properties));
          infoWindow.setPosition({
            lat: feature?.geometry?.coordinates[1],
            lng: feature?.geometry?.coordinates[0],
          });
          // console.log("here");
          if (typeof zoomLatestFeature !== "undefined" && zoomLatestFeature) {
            // console.log("x");
            infoWindow.open({
              // anchor: marker,
              map: map.map,
              shouldFocus: true,
            });
          } else {
            // console.log("y");
            infoWindow.open({
              map: map.map,
              shouldFocus: false,
            });
          }
        }
      }
    }
  }, [
    features,
    map,
    infoWindow,
    focusLatestFeature,
    popupLatestFeature,
    createContent,
    reDrawFeatures,
    zoomLatestFeature,
    focusfeature,
  ]);

  const resolvePath = (path: any, obj: any) => {
    return path.split(".").reduce(function (prev: any, curr: any) {
      return prev ? prev[curr] : null;
    }, obj || this);
  };

  const lbbonclick = (layers: any) => {
    // console.log("here", layers);
    if (typeof layers !== "undefined") {
      let newlists: any[] = [];
      for (let a = 0; a < layers.length; a++) {
        for (let b = 0; b < layers[a].properties.length; b++) {
          newlists.push(layers[a].properties[b]);
        }
      }
      // console.log("newlists", newlists);
      // setBusinessLists(newlists);
    }
  };

  const mapCallback = (newmap: any, maphandler: any) => {
    // console.log("initializing map");
    if (typeof map === "undefined" && typeof maphandler !== "undefined") {
      const darkMap = GoogleDarkMap as google.maps.MapTypeStyle[];
      // console.log(darkMap);
      maphandler.setOptions({ styles: darkMap });

      if (typeof infoWindow === "undefined") {
        let newinfoWindow: any = new google.maps.InfoWindow();
        setInfoWindow(newinfoWindow);
      }

      setMap({
        google: newmap,
        map: maphandler,
      });

      if (mapcallback) {
        mapcallback({
          google: newmap,
          map: maphandler,
        });
      }
    }
  };

  return (
    <div
      style={{
        height: options?.height ? options?.height : "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Map
        mapOptions={{
          zoom: 5,
          center: { lat: 3.8, lng: 109.36869 },
        }}
        mapOnClick={lbbonclick}
        // mapboxCallback={mapboxCallback}
        mapCallback={mapCallback}
        // filters={[{ source: "clappia" }]}
        options={{ mapbox: false }}
      />
    </div>
  );
};

export default Index;

// export {default} from "./Map"
