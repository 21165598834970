import React, { useState } from "react";

import Map from "./Map/Light";

// const google = window.google;

const MapApp = ({
  features,
  options,
  mapcallback,
  reDrawFeatures,
  focusLatestFeature,
  popupLatestFeature,
  popupLatestFeatureField,
  focusfeature,
  focusfeaturepopup,
  popupContentColumns,
  zoomLatestFeature,
}: any) => {
  const [isloaded, setIsLoaded] = useState<any>(false);

  React.useEffect(() => {
    // let isMounted = true;
    const checkGoogle = () => {
      console.log("here");
      setTimeout(() => {
        if (typeof window.google !== "undefined") {
          setIsLoaded(true);
        } else {
          checkGoogle();
        }
      }, 2000);
    };
    if (typeof window.google !== "undefined") {
      setIsLoaded(true);
    } else {
      checkGoogle();
    }
    // return () => {
    //   isMounted = false;
    // };
  }, []);

  if (isloaded) {
    return (
      <Map
        features={features}
        options={options}
        mapcallback={mapcallback}
        reDrawFeatures={reDrawFeatures}
        focusLatestFeature={focusLatestFeature}
        popupLatestFeature={popupLatestFeature}
        popupLatestFeatureField={popupLatestFeatureField}
        focusfeature={focusfeature}
        focusfeaturepopup={focusfeaturepopup}
        popupContentColumns={popupContentColumns}
        zoomLatestFeature={zoomLatestFeature}
      />
    );
  } else {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">loading...</div>
        </div>
      </div>
    );
  }
};

export default MapApp;
