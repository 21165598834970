import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    borderBottom: "none",
    "& td": {
      borderBottom: "none",
      padding: "0px",
      // border: "1px solid #ff0000",
    },
    height: "100%",
  },
  icon: {
    verticalAlign: "top",
  },
  label: {
    font: "normal normal 500 14px Poppins !important",
    letterSpacing: "0px",
    color: "# !important",
    textAlign: "right",
  },
  value: {
    font: "normal normal bold 28px Poppins",
    letterSpacing: "0px",
    color: "#2C2B78 !important",
    textAlign: "right",
    verticalAlign: "bottom",
  },
  label_small: {
    font: "normal normal 500 12px Poppins !important",
    letterSpacing: "0px",
    color: "# !important",
    textAlign: "right",
  },
  value_small: {
    font: "normal normal bold 20px Poppins",
    letterSpacing: "0px",
    color: "#2C2B78 !important",
    textAlign: "right",
    verticalAlign: "bottom",
  },
}));

const InfoBox = ({ label, value, icon, size, caption }: any) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Table size="small" className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.icon} rowSpan={2}>
              {icon}
            </TableCell>
            <TableCell
              className={size === "small" ? classes.label_small : classes.label}
            >
              {label}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={size === "small" ? classes.value_small : classes.value}
            >
              {value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </TableCell>
          </TableRow>
          {caption && (
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: "right" }}>
                <Typography variant="caption" style={{ fontSize: "80%" }}>
                  {caption}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default InfoBox;
