import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// value used by filter

export interface AppForm {
  label: string | any;
  search: string | any;
}

const initialState: AppForm = {
  label: "",
  search: "",
};

const formStateSlice = createSlice({
  name: "formState",
  initialState,
  reducers: {
    setLabel(state, action: PayloadAction<string>) {
      state.label = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
  },
});

export const { setLabel, setSearch } = formStateSlice.actions;

export default formStateSlice.reducer;
