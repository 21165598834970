import React, { Fragment, useCallback } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InfoBox from "./InfoBox";
// import EventIcon from "@mui/icons-material/Event";
// import GroupIcon from "@mui/icons-material/Group";
// import StoreIcon from "@mui/icons-material/Store";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import { onAuthStateChanged } from "firebase/auth"; // New import
import { auth } from "../../config/Firebase";

import { format, isValid } from "date-fns";
import Map from "../MapLight";
// import { count } from "console";

// const useStyles = makeStyles((theme) => ({}));

const MapOperation = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [filters, setFilters] = React.useState<any>({
    date: new Date(),
  });
  const [token, setToken] = React.useState<any>("");
  const [websocket, setWebsocket] = React.useState<any>();
  const [maphandler, setMapHandler] = React.useState<any>();
  const [feature, setFeature] = React.useState<any>();
  const [features, setFeatures] = React.useState<any[]>();
  const [wsfeatures, setWsFeatures] = React.useState<any[]>();
  const [setfocus, setSetFocus] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(7);
  const [stats, setStats] = React.useState<any>({
    total: 0,
    file_exist: 0,
    file_not_exist: 0,
  });
  let { date } = useParams();

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof date !== "undefined" && date !== "") {
      setFilters({ date: new Date(date).setHours(0, 0, 0) });
    }
  }, [date]);

  const infoboxs = [
    {
      value: stats.total,
      label: "Jumlah",
      icon: (
        <AssignmentIcon
          sx={{ fontSize: 60, color: "#111111" }}
          fontSize="large"
        />
      ),
    },
    // {
    //   value: stats.file_exist,
    //   label: "Disahkan",
    //   icon: (
    //     <AssignmentIcon
    //       sx={{ fontSize: 60, color: "#1AAF22" }}
    //       fontSize="large"
    //     />
    //   ),
    // },
    // {
    //   value: stats.file_not_exist,
    //   label: "Belum Disahkan",
    //   icon: (
    //     <AssignmentIcon
    //       sx={{ fontSize: 60, color: "#ff0000" }}
    //       fontSize="large"
    //     />
    //   ),
    // },
  ];

  const formatGeoJSON = useCallback(
    (json: any[], init?: boolean) => {
      let counts: any = JSON.parse(JSON.stringify(stats));
      for (let f = 0; f < json.length; f++) {
        if (json[f].properties.status.toString() === "1") {
          json[f].properties._icon = "/images/markers/marker-green.png";
        } else if (json[f].properties.status.toString() === "2") {
          json[f].properties._icon = "/images/markers/marker-red.png";
        } else {
          json[f].properties._icon = "/images/markers/marker-blue.png";
        }
        if (typeof json[f].properties._status === "undefined") {
          counts.total++;
          if (json[f].properties.status.toString() === "1") {
            counts.file_exist++;
          } else if (json[f].properties.status.toString() === "2") {
            counts.file_not_exist++;
          }
        }
        json[f].properties._status = 1;
      }

      if (typeof init === "undefined" && json.length > 0) {
        setStats(counts);
      }
      return json;
    },
    [stats]
  );

  // const formatGeoJSON = useCallback(
  //   (json: any[]) => {
  //     // console.log(stats);
  //   },
  //   [stats]
  // );

  React.useEffect(() => {
    if (typeof wsfeatures !== "undefined" && wsfeatures.length > 0) {
      let filteredfeatures: any[] = [];
      let tmpfeatures: any[] = [];
      if (typeof features !== "undefined" && features !== null) {
        tmpfeatures = JSON.parse(JSON.stringify(features));
      }
      for (let w = 0; w < wsfeatures.length; w++) {
        let idx: number = -1;
        if (features && features.length > 0) {
          idx = features.findIndex((feat: any, id: number) => {
            return (
              typeof feat?.properties?.id !== "undefined" &&
              typeof wsfeatures[w].properties?.id !== "undefined" &&
              feat?.properties?.id.toString() ===
                wsfeatures[w].properties?.id.toString()
            );
          });
        }
        if (idx <= 0) {
          filteredfeatures.push(wsfeatures[w]);
        } else {
          if (tmpfeatures[idx]) {
            tmpfeatures[idx] = wsfeatures[w];
          }
        }
      }

      const newfeatures = filteredfeatures.concat(tmpfeatures);
      setFeatures(formatGeoJSON(newfeatures));
      setFeature(undefined);
      setWsFeatures(undefined);
    }
  }, [wsfeatures, features, formatGeoJSON]);

  // React.useEffect(() => {
  //   if (typeof features !== "undefined" && features.length > 0) {
  //     console.log("here", features, stats);
  //     let counts: any = JSON.parse(JSON.stringify(stats));
  //     // {
  //     //   total: features.length,
  //     //   file_exist: 0,
  //     //   file_not_exist: 0,
  //     // };
  //     let reload: number = 0;
  //     for (let f = 0; f < features.length; f++) {
  //       if (typeof features[f].properties._status === "undefined") {
  //         if (features[f].properties.status.toString() === "1") {
  //           counts.file_exist++;
  //         } else if (features[f].properties.status.toString() === "2") {
  //           counts.file_not_exist++;
  //         }
  //         reload++;
  //       }
  //     }
  //     if (reload > 0) {
  //       setStats(counts);
  //     }
  //   } else {
  //     // setStats({
  //     //   total: 0,
  //     //   file_exist: 0,
  //     //   file_not_exist: 0,
  //     // });
  //   }
  // }, [features, stats]);

  React.useEffect(() => {
    if (
      typeof features === "undefined" &&
      typeof filters?.date !== "undefined" &&
      filters?.date !== "" &&
      token !== ""
    ) {
      console.log("initialize data for date", filters?.date);
      const begin = format(filters?.date, "yyyy-MM-dd");
      // isValid
      fetch("/api/data/geojson?live=true&date=" + begin, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (body) {
          return body.json();
        })
        .then(function (data) {
          if (!data.features) {
            setFeatures([]);
            return;
          } else {
            if (data?.metadata?.total && typeof features === "undefined") {
              setStats({
                total:
                  typeof data?.metadata?.total?.total !== "undefined"
                    ? data?.metadata?.total?.total
                    : 0,
                file_exist:
                  typeof data?.metadata?.total?.exist !== "undefined"
                    ? data?.metadata?.total?.exist
                    : 0,
                file_not_exist:
                  typeof data?.metadata?.total?.not_exist !== "undefined"
                    ? data?.metadata?.total?.not_exist
                    : 0,
              });
            }
            setFeatures(formatGeoJSON(data?.features, true));
          }

          // for (let i = 0; i < data.features.length; i++) {
          // }
        })
        .catch(function (err) {
          console.log(err);
          alert("Unable to fetch census data");
        });
    }
  }, [filters?.date, token, features, formatGeoJSON]);

  React.useEffect(() => {
    const startWS = () => {
      let base: string = "";
      let hostname: string = "";
      // console.log(window.location);
      if (/(localhost|127.0.0.1|172.16.1.62)/i.test(window.location.hostname)) {
        hostname = "ck.tuxgeo.dev";
        // hostname = "geohasil.hasil.gov.my";
      } else {
        hostname = window.location.hostname;
      }

      if (window.location.protocol === "https:") {
        base = "wss://" + hostname;
      } else if (window.location.protocol === "http:") {
        base = "ws://" + hostname;
      } else {
        alert("unable to support unknown protocol!");
        return;
      }

      if (!base.endsWith("/")) {
        base = base + "/";
      }

      let endpoint: string = base + "service/live/report";

      // console.log(endpoint);
      // const previous = new Date();
      const conn = new WebSocket(endpoint);

      conn.onopen = () => {
        console.log("WebSocket Client Connected");
      };
      conn.onmessage = function (evt: any) {
        let eventfeatures: any[] = JSON.parse(evt.data).features;
        let filteredfeatures: any[] = [];

        if (eventfeatures.length <= 0) {
          // console.log("no update");
          return false;
        } else {
          for (let i = 0; i < eventfeatures.length; i++) {
            // console.log(
            //   eventfeatures[i].properties,
            //   eventfeatures[i].properties?.ts
            // );
            if (
              format(
                new Date(eventfeatures[i].properties?.ts),
                "yyyy-MM-dd"
              ) === format(filters?.date, "yyyy-MM-dd")
            ) {
              eventfeatures[i].properties.created_at =
                eventfeatures[i].properties.ts;
              eventfeatures[i].properties.name =
                eventfeatures[
                  i
                ].properties.data?.maklumat_asas?.nama_perniagaan;

              filteredfeatures.push(eventfeatures[i]);
            }
          }
        }

        // console.log(eventfeatures, filteredfeatures);
        setWsFeatures(filteredfeatures);
        if (filteredfeatures.length > 0) {
          setSetFocus(true);
        }
      };
      conn.onclose = function (e) {
        console.log(
          "Socket is closed. Reconnect will be attempted in 5 second.",
          e.reason
        );
        setTimeout(() => {
          startWS();
        }, 5000);
      };

      setWebsocket(conn);
    };
    if (typeof websocket === "undefined" && typeof maphandler !== "undefined") {
      startWS();
    }
  }, [websocket, maphandler, filters?.date]);

  const mapCallback = (callback: any) => {
    // console.log("c", callback);
    if (typeof maphandler === "undefined" && callback && callback.map) {
      setMapHandler(callback);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const setFocus = (feat: any) => {
    // console.log(feat);
    setFeature(feat);
  };

  // rows = data?.features;
  let rows: any[] | any = features;
  if (typeof features !== "undefined" && features.length > 0) {
    if (features.length > rowsPerPage) {
      rows = features.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      );
    }
  }

  console.log(filters);

  return (
    <Fragment>
      <table
        cellSpacing={5}
        style={{
          width: "100%",
          height: "calc(100vh - 120px)",
        }}
      >
        <tbody>
          <tr>
            {/* <td
              style={{
                height: "1px",
                verticalAlign: "top",
                // border: "1px solid #00ff00",
              }}
            >
              <Grid container spacing={2}>
                {infoboxs &&
                  infoboxs.map((info: any, idx: any) => (
                    <Grid item key={idx} xs={12} lg={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <InfoBox
                          label={info.label}
                          value={info.value}
                          icon={info.icon}
                        />
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </td> */}
            <td
              style={{
                height: "100%",
                verticalAlign: "top",
              }}
              rowSpan={2}
            >
              <Paper
                sx={{
                  p: 2,
                  height: "100%",
                }}
              >
                <Map
                  mapcallback={mapCallback}
                  features={features}
                  reDrawFeatures={true}
                  focusLatestFeature={setfocus}
                  zoomLatestFeature={false}
                  popupLatestFeature={true} // if focusLatestFeature is true
                  popupLatestFeatureField={"image_id"} // if focusLatestFeature is true
                  focusfeature={feature}
                  focusfeaturepopup={true}
                  popupContentColumns={[
                    { label: "", id: "data.maklumat_asas.nama_perniagaan" },
                    { label: "ROB/ROC", id: "data.maklumat_asas.regno" },
                    {
                      label: "Jenis Premis",
                      id: "data.maklumat_asas.premise_type",
                    },
                    { label: "Alamat", id: "data.maklumat_asas.alamat" },
                    { label: "Tarikh", id: "ts" },
                    { label: "", id: "image_id", isImage: true },
                  ]}
                />
              </Paper>
            </td>
            <td
              style={{
                width: "300px",
              }}
            >
              <Grid container spacing={2}>
                {infoboxs &&
                  infoboxs.map((info: any, idx: any) => (
                    <Grid item key={idx} xs={12} lg={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <InfoBox
                          label={info.label}
                          value={info.value}
                          icon={info.icon}
                        />
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "300px",
                height: "99%",
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Grid item xs={12}>
                    <Typography variant="body1" className="cardtitle">
                      Tarikh
                    </Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      // paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Tarikh"
                          value={filters?.date ? filters?.date : ""}
                          onChange={(newValue) => {
                            if (
                              isValid(newValue) &&
                              newValue.getFullYear() >= 2020
                            ) {
                              setFilters({
                                ...filters,
                                date: newValue.setHours(0, 0, 0),
                              });
                              setFeatures(undefined);
                              history.push({
                                pathname: `${match.path.replace(
                                  "/:date",
                                  ""
                                )}/${format(newValue, "yyyy-MM-dd")}`,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="standard"
                            />
                          )}
                          inputFormat="dd/MM/yyyy"
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" className="cardtitle">
                      Senarai
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        // maxHeight: "calc(100vh - 400px)",
                        height: "calc(100vh - 430px)",
                        overflowY: "auto",
                        // border: "1px solid #ff0000",
                        marginBottom: "10px",
                      }}
                    >
                      {rows &&
                        rows.map((feature: any, idx: number) => (
                          <Paper
                            key={idx}
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "10px",
                              border: "1px solid #eeeeee",
                              cursor: "pointer",
                            }}
                            onClick={() => setFocus(feature)}
                          >
                            <Grid
                              container
                              spacing={0}
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={12} sm={3}>
                                <AssignmentIcon
                                  sx={{
                                    fontSize: 40,
                                    color:
                                      feature?.properties?.status.toString() ===
                                      "1"
                                        ? "#1AAF22"
                                        : "#ff0000",
                                  }}
                                  fontSize="large"
                                />
                              </Grid>
                              <Grid item xs={12} sm={9}>
                                <Typography variant="body2">
                                  {feature?.properties?.name
                                    ? feature?.properties?.name
                                    : "Tiada Nama Premis"}
                                </Typography>
                                <Typography variant="caption">
                                  {feature?.properties?.created_at
                                    ? feature?.properties?.created_at
                                    : feature?.properties?.ts}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                    </div>
                    {features && features.length > rowsPerPage && (
                      <Pagination
                        count={
                          typeof features !== "undefined"
                            ? Math.ceil(features.length / rowsPerPage)
                            : 0
                        }
                        defaultPage={1}
                        boundaryCount={1}
                        siblingCount={0}
                        page={page}
                        onChange={handleChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default MapOperation;
