import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { auth } from "../config/Firebase";
import { onAuthStateChanged } from "firebase/auth"; // New import

interface DialogProps {
  index?: any;
  open?: boolean;
  data?: any;
  callback?: (data: any) => void;
  mapdata?: (data: any) => void;
  user?: any;
  filters?: any;
  feature?: any;
}

declare const google: any;

const GMap = (props: DialogProps) => {
  const { callback, filters, mapdata, feature } = props;
  const gmapkey = process.env["REACT_APP_GMAPKEY"];

  // console.log(gmapkey);
  const containerStyle: any = {
    width: "100%",
    height: "100%",
  };

  // const center: any = {
  //   lat: 3.92,
  //   lng: 108.523,
  // };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmapkey ? gmapkey : "",
  });

  const [map, setMap] = React.useState<any>();
  const [geojson, setGeoJSON] = React.useState<any>();
  const [curfilters, setCurFilters] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  const [center] = React.useState<any>({
    lat: 3.92,
    lng: 108.523,
  });

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      typeof map !== "undefined" &&
      typeof geojson !== "undefined" &&
      typeof feature !== "undefined"
    ) {
      const focusfeature: any = geojson.features.find((feat: any) => {
        return feat.properties.id.toString() === feature.id.toString();
      });
      if (focusfeature && focusfeature?.geometry) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(
          new google.maps.LatLng(
            focusfeature.geometry.coordinates[1],
            focusfeature.geometry.coordinates[0]
          )
        );
        // for (let b = 0; b < geojson.features.length; b++) {
        //   if (geojson.features[b].properties._visible) {
        //     bounds.extend(
        //       new google.maps.LatLng(
        //         focusfeature.geometry.coordinates[1],
        //         focusfeature.geometry.coordinates[0]
        //       )
        //     );
        //   }
        // }
        map.panTo(
          new google.maps.LatLng(
            focusfeature?.geometry.coordinates[1],
            focusfeature?.geometry.coordinates[0]
          )
        );
        map.setZoom(15);
        console.log(focusfeature);
      }
    }
  }, [geojson, map, feature]);

  React.useEffect(() => {
    if (typeof filters !== "undefined" && typeof map !== "undefined") {
      if (
        (filters?.q !== curfilters?.q ||
          filters?.area?.state !== curfilters?.area?.state ||
          filters?.area?.q !== curfilters?.area?.state) &&
        (filters?.q !== "" ||
          filters?.area?.state !== "" ||
          filters?.area?.district !== "")
      ) {
        console.log(filters, curfilters);
        map.data.forEach((feature: any) => {
          let matched: number = 0;
          feature.forEachProperty((prop: any, idx: any) => {
            // console.log(idx, prop);
            if (prop.toString().match(new RegExp(filters?.q, "gi"))) {
              matched++;
              return true;
            }
          });

          // if (
          //   feature.getProperty("state").toString().toLowerCase() ===
          //   filters?.area?.state.toString().toLowerCase()
          // ) {
          //   matched++;
          // }
          // if (
          //   feature.getProperty("district").toString().toLowerCase() ===
          //   filters?.area?.district.toString().toLowerCase()
          // ) {
          //   matched++;
          // }

          // console.log(filters?.q, matched, checknum);
          if (
            matched > 0 &&
            (!filters?.area?.state ||
              (filters?.area?.state &&
                feature.getProperty("state").toString().toLowerCase() ===
                  filters?.area?.state.toString().toLowerCase())) &&
            (!filters?.area?.district ||
              (filters?.area?.district &&
                feature.getProperty("district").toString().toLowerCase() ===
                  filters?.area?.district.toString().toLowerCase()))
          ) {
            feature.setProperty("_visible", true);
          } else {
            feature.setProperty("_visible", false);
          }
        });
        map.data.setStyle((feature: any) => {
          if (feature.getProperty("_visible")) {
            return {
              visible: true,
              icon: "/images/small_wifi_icon.png",
              fillColor: "green",
            };
          } else {
            return {
              visible: false,
            };
          }
        });

        map.data.toGeoJson((geojson: any) => {
          if (typeof feature !== "undefined") {
            const focusfeature: any = geojson?.features.find((feat: any) => {
              return feat.properties.id.toString() === feature.id.toString();
            });
            if (focusfeature && focusfeature?.geometry) {
              map.panTo(
                new google.maps.LatLng(
                  focusfeature?.geometry.coordinates[1],
                  focusfeature?.geometry.coordinates[0]
                )
              );
              map.setZoom(15);
              console.log(focusfeature);
            }
          } else {
            const bounds = new google.maps.LatLngBounds();
            for (let b = 0; b < geojson.features.length; b++) {
              if (geojson.features[b].properties._visible) {
                bounds.extend(
                  new google.maps.LatLng(
                    geojson.features[b].geometry.coordinates[1],
                    geojson.features[b].geometry.coordinates[0]
                  )
                );
              }
            }
            console.log("here");
            map.fitBounds(bounds);
          }
        });

        setCurFilters(filters);
        // map.data.setStyle((feature: any) => {
        //   let matched: number = 0;
        //   feature.forEachProperty((prop: any) => {
        //     // console.log(prop);
        //     if (prop.toString().match(new RegExp(filters?.q, "gi"))) {
        //       matched++;
        //       return true;
        //     }
        //     //
        //   });
        //   if (matched > 0) {
        //     return {
        //       visible: true,
        //       icon: "/images/small_wifi_icon.png",
        //       fillColor: "green",
        //     };
        //   } else {
        //     return {
        //       visible: false,
        //     };
        //   }
        // });
      } else {
      }
    }
  }, [filters, curfilters, map, feature]);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      let url: string = "/api/pik/geojson/all";
      console.log(filters?.agency);
      if (filters?.agency) {
        url += "?isagency=true";
      }
      fetch(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            if (data?.features.length > 0) {
              if (mapdata) {
                mapdata(data?.features);
              }
              map.data.addGeoJson(data);
              map.data.setStyle((feature: any) => {
                // console.log(feature.getProperty("_type"));
                if (feature.getProperty("_type") === "onlineentrepreneur") {
                  return {
                    icon: "/images/ent_green.png",
                    fillColor: "green",
                  };
                } else if (
                  feature.getProperty("_type") === "placeofattraction"
                ) {
                  return {
                    icon: "/images/poi_red.png",
                    fillColor: "green",
                  };
                } else {
                  // return {
                  //   icon: "/images/small_wifi_icon.png",
                  //   fillColor: "green",
                  // };
                }
              });
              setGeoJSON(data);

              map.data.addListener("click", (event: any) => {
                event.feature.toGeoJson((a: any) => {
                  console.log(a);
                  if (callback) {
                    callback(a.properties);
                  }
                });

                // map.data.overrideStyle(event.feature, {fillColor: 'red'});
              });
            }
            if (typeof data?.metadata?.bounds !== "undefined") {
              const bounds = new google.maps.LatLngBounds();
              if (data?.metadata?.bounds?.coordinates) {
                const bbox = data?.metadata?.bounds?.coordinates[0];
                if (bbox) {
                  for (let b = 0; b < bbox.length; b++) {
                    bounds.extend(
                      new google.maps.LatLng(bbox[b][1], bbox[b][0])
                    );
                    console.log(bbox[b]);
                  }
                  map.fitBounds(bounds);
                }
              }
              // setMap(map);
            }
          }
        });
    };
    if (
      typeof geojson === "undefined" &&
      typeof map !== "undefined" &&
      typeof token !== "undefined"
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [geojson, map, callback, mapdata, filters, token]);

  const onLoad: any = React.useCallback(function callback(map: any) {
    if (typeof google !== "undefined") {
      map.setOptions({
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        controlSize: 32,
      });

      setMap(map);
    }
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  console.log();
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GMap;
