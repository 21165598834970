import { configureStore, combineReducers } from "@reduxjs/toolkit";

import formState from "./form";
import userState from "./user";

const rootReducer = combineReducers({
  formState,
  userState,
});

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer,
});
