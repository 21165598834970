import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// import SelectAgency from "../Forms/Agency";
// import { Headers, AuthorizationHeader, fetchPlus } from "./../../store/headers";
import { setLabel } from "./../../store/form";

import "./Form.css";
// import { preProcessFile } from "typescript";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  margin: {
    // margin: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  rootheader: {
    flexGrow: 1,
    // height: "100%",
    width: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    border: "0x solid #ff0000",
    margin: "0px !important",
    padding: "0px !important",
    marginBottom: "30px !important;",
    marginTop: "20px !important",
  },
  center: {
    textAlign: "center",
  },
  label: {
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

interface PropsTotal {
  id: string;
  sportData: (sportdata: object | null) => void;
}
interface UserProfile {
  id?: string;
  user_id?: string;
  access?: string[];
  name?: string;
  email?: string;
  picture?: string;
  token?: string;
  agency?: string;
  [key: string]: number | string | object | any;
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserForm: React.FC<PropsTotal> = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState<string>();
  const [userprofile, setUserProfile] = React.useState<UserProfile>({
    id: "",
    user_id: "",
    access: [],
    name: "",
    email: "",
    picture: "",
    agency: "",
    position: "",
  });
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [piks, setPiks] = React.useState<any[]>();
  const [provider, setProvider] = React.useState<string>();
  const [agencylist, setAgencyList] = React.useState<any[]>();
  const statelists = [
    { code: "01", label: "JOHOR" },
    { code: "02", label: "KEDAH" },
    { code: "03", label: "KELANTAN" },
    { code: "04", label: "MELAKA" },
    { code: "05", label: "NEGERI SEMBILAN" },
    { code: "06", label: "PAHANG" },
    { code: "07", label: "PULAU PINANG" },
    { code: "08", label: "PERAK" },
    { code: "09", label: "PERLIS" },
    { code: "10", label: "SELANGOR" },
    { code: "11", label: "TERENGGANU" },
    { code: "12", label: "SABAH" },
    { code: "13", label: "SARAWAK" },
    { code: "14", label: "WILAYAH PERSEKUTUAN KUALA LUMPUR" },
    { code: "15", label: "WILAYAH PERSEKUTUAN LABUAN" },
    { code: "16", label: "WILAYAH PERSEKUTUAN PUTRAJAYA" },
  ];

  const classes = useStyles();
  // <{ id?: string }>
  let { id } = useParams();
  if (!id) {
    id = props.id;
  }

  React.useEffect(() => {
    if (id) {
      setUser(id);
    }
  }, [id, setUser]);

  React.useEffect(() => {
    let isMounted = true;

    if (typeof agencylist === "undefined") {
      fetch("/api/agency/list")
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            console.log("---");
            setAgencyList(data.agencies);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [agencylist]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      if (user === "new") {
        let newuserprofile: UserProfile = JSON.parse(
          JSON.stringify(userprofile)
        );
        newuserprofile.user_id = user;
        setUserProfile(newuserprofile);
      } else {
        fetch(
          "/api/user/profile/" + user
          // Headers()
        )
          .then((response) => response.json())
          .then((data) => {
            if (isMounted) {
              setUserProfile(data.profile);
              setProvider(data.profile.agency);
              if (data?.profile?.name) {
                dispatch(setLabel(data.profile.name));
              }
            }
          });
      }
    };

    if (user && userprofile?.user_id === "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [user, dispatch, userprofile]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch(
        "/api/pik/list/" + provider
        // Headers()
      )
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setPiks(data.pusatinternets);
          }
        });
    };
    if (provider !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [provider]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.id);
    if (typeof userprofile !== "undefined") {
      console.log(event.target.id, event.target.value);
      let newuserprofile: UserProfile = JSON.parse(JSON.stringify(userprofile));
      newuserprofile[event.target.id] = event.target.value;
      setUserProfile(newuserprofile);
    }
  };

  const handlePositionChange = (event?: React.ChangeEvent<any>) => {
    // console.log(event?.target, userprofile[event?.target?.id]);
    setUserProfile({
      ...userprofile,
      [event?.target?.id ? event?.target?.id : event?.target?.name]:
        event?.target?.value,
    });
  };

  const handleStateChange = (event?: React.ChangeEvent<any>) => {
    console.log(event?.target, userprofile[event?.target?.id]);
    setUserProfile({
      ...userprofile,
      [event?.target?.id ? event?.target?.id : event?.target?.name]:
        event?.target?.value,
    });
  };

  const handleSelectChange = (
    event?: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    if (event && event.target && event.target.name) {
      console.log(event.target.name, event.target.value);
      let newuserprofile: UserProfile = JSON.parse(JSON.stringify(userprofile));
      newuserprofile[event.target.name] = parseInt(event.target.value);
      setUserProfile(newuserprofile);
    }
  };

  const updateUserProfile = () => {
    setQuerystatus("");
    const fields: string[] = [
      "id",
      "user_id",
      "pi1m_refid",
      "pi1m_refids",
      "name",
      "email",
      "access",
      "status",
      "agency",
      "position",
      "state",
    ];
    const data = new URLSearchParams();
    for (let f in fields) {
      if (userprofile && userprofile[fields[f]] !== "") {
        console.log(f, fields[f], userprofile[fields[f]]);
        data.append(fields[f], userprofile[fields[f]]);
      }
    }
    console.log(userprofile);
    if (userprofile.name === "" || userprofile.email === "") {
      window.alert("Missing required fields.");
      return false;
    }

    // data.forEach((d,i) => {
    //   console.log(d);
    // });
    fetch("/api/user/profile", {
      method: "post",
      body: data,
      // headers: AuthorizationHeader(),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.profile);
        setQuerystatus(data.profile.status);
      });
  };

  // const setValue = (id: string, value: string | any) => {
  //   console.log(id, value);
  //   if (typeof userprofile !== "undefined") {
  //     let newuserprofile: UserProfile = JSON.parse(JSON.stringify(userprofile));
  //     newuserprofile[id] = value;
  //     setUserProfile(newuserprofile);
  //     if (id === "agency") {
  //       setProvider(value);
  //     }
  //   }
  // };

  const UserStatus: string[] = ["Pending", "Approved", "Revoke"];
  const positions: any[] = [
    { id: "HASIL", label: "HASIL Administrator" },
    { id: "PROVIDER", label: "Service Provider" },
    { id: "MANAGER", label: "PEDi Manager" },
    { id: "ASSISTANT MANAGER", label: "PEDi Assistant Manager" },
    { id: "AGENCY", label: "PUPUK Agency" },
    { id: "RAKANDIGITAL", label: "Rakan Digital Pupuk" },
  ];

  let selectagency: any[] = [];
  if (
    typeof agencylist !== "undefined" &&
    typeof userprofile?.position !== "undefined" &&
    userprofile?.position !== "" &&
    userprofile?.position !== "HASIL"
  ) {
    // selectagency = agencylist;

    selectagency = agencylist.filter((row: any) => {
      if (userprofile?.position === "AGENCY" && row?.pupuk > 0) {
        return row;
      } else if (userprofile?.position !== "AGENCY" && row?.pupuk <= 0) {
        return row;
      }
      return null;
    });
  }

  // if(userprofile && typeof userprofile.user_id !== 'undefined'){
  return (
    <>
      <Typography variant="h5">User Profile</Typography>
      <Grid container className={classes.rootheader} spacing={4}>
        {userprofile?.picture && (
          <Grid item xs={4} sm={2}>
            <img src={userprofile?.picture} alt="" className="userprofile" />
          </Grid>
        )}
        <Grid
          item
          xs={id === "new" || !userprofile?.picture ? 12 : 8}
          sm={id === "new" || !userprofile?.picture ? 12 : 10}
        >
          <Grid container spacing={1}>
            {querystatus !== "" && (
              <Grid item xs={12} style={{ paddingBottom: "20px" }}>
                {querystatus === "NEW_DUPLICATE" && (
                  <Alert severity="error">
                    Query failed - Profile already registered.
                  </Alert>
                )}
                {querystatus === "NEW_REGISTERED" && (
                  <Alert severity="success">Profile has been registered.</Alert>
                )}
                {querystatus === "UPDATED" && (
                  <Alert severity="success">Profile has been updated.</Alert>
                )}
                {querystatus === "SAVED" && (
                  <Alert severity="success">Profile has been saved.</Alert>
                )}
                {querystatus === "ERROR" && (
                  <Alert severity="error">Query failed - Unknown Reason.</Alert>
                )}
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                className={clsx(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="name" className={classes.label}>
                  Name
                </InputLabel>
                <OutlinedInput
                  id="name"
                  type="name"
                  value={userprofile?.name ? userprofile?.name : ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel htmlFor="email" className={classes.label}>
                  e-mail
                </InputLabel>
                <OutlinedInput
                  id="email"
                  type="email"
                  value={userprofile?.email ? userprofile?.email : ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel htmlFor="position" className={classes.label}>
                  User Group
                </InputLabel>
                <Select
                  labelId="position"
                  label="Position"
                  id="position"
                  name="position"
                  value={userprofile?.position ? userprofile?.position : ""}
                  onChange={handlePositionChange}
                  style={{ width: "100%" }}
                >
                  {positions &&
                    positions.map((post: any, idx) => (
                      <MenuItem key={idx} value={post.id ? post.id : ""}>
                        {post.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {userprofile?.position === "HASIL" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel htmlFor="state" className={classes.label}>
                    States
                  </InputLabel>
                  <Select
                    labelId="state"
                    label="state"
                    id="state"
                    name="state"
                    value={userprofile?.state ? userprofile?.state : ""}
                    onChange={handleStateChange}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value=""></MenuItem>
                    {statelists &&
                      statelists.map((count, idx) => (
                        <MenuItem
                          key={idx}
                          value={count.code ? count.code.toString() : ""}
                        >
                          {count.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {selectagency.length > 0 && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel htmlFor="agency" className={classes.label}>
                    Agency
                  </InputLabel>
                  {/* <SelectAgency
                    id="agency"
                    data={selectagency ? selectagency : []}
                    setValue={setValue}
                    defaultValue={
                      userprofile?.agency ? userprofile?.agency : ""
                    }
                  /> */}
                </FormControl>
              </Grid>
            )}

            {selectagency.length > 0 &&
              userprofile?.position !== "HASIL" &&
              userprofile?.position !== "PROVIDER" &&
              userprofile?.position !== "AGENCY" &&
              userprofile?.position !== "RAKANDIGITAL" && (
                <Grid item xs={12} sm={6}>
                  {userprofile?.agency && (
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel
                        htmlFor="pi1m_refid"
                        className={classes.label}
                      >
                        Pusat Internet Komuniti
                      </InputLabel>

                      <Select
                        labelId="pi1mid"
                        label="Position"
                        id="pi1m_refid"
                        name="pi1m_refid"
                        value={
                          userprofile?.pi1m_refid ? userprofile?.pi1m_refid : ""
                        }
                        onChange={handlePositionChange}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="">Select PEDi</MenuItem>
                        {piks &&
                          piks.map((pik: any, idx: number) => (
                            <MenuItem value={pik?.refid} key={idx}>
                              {pik?.refid} - {pik?.site_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              )}

            {selectagency.length > 0 &&
              userprofile?.position === "RAKANDIGITAL" && (
                <Grid item xs={12}>
                  {userprofile?.agency && (
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel
                        htmlFor="pi1m_refid"
                        className={classes.label}
                      >
                        Pusat Internet Komuniti
                      </InputLabel>
                      <Select
                        labelId="pi1mid"
                        label="Position"
                        id="pi1m_refids"
                        name="pi1m_refids"
                        value={
                          userprofile?.pi1m_refids
                            ? userprofile?.pi1m_refids
                            : ""
                        }
                        onChange={handlePositionChange}
                        style={{ width: "100%" }}
                        multiple={true}
                      >
                        <MenuItem value="">Select PEDi</MenuItem>
                        {piks &&
                          piks.map((pik: any, idx: number) => (
                            <MenuItem value={pik?.refid} key={idx}>
                              {pik?.refid + " - " + pik?.site_name}
                            </MenuItem>
                          ))}
                      </Select>
                      {userprofile?.pi1m_refids.length > 1 ? (
                        <div>
                          <ol>
                            {piks &&
                              piks.map((pik: any, idx: number) => (
                                <Fragment key={idx}>
                                  {userprofile?.pi1m_refids.indexOf(
                                    pik?.refid
                                  ) > -1 ? (
                                    <li>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                      >
                                        {pik?.refid + " - " + pik?.site_name}
                                      </Typography>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </Fragment>
                              ))}
                          </ol>
                        </div>
                      ) : (
                        <FormHelperText>
                          You can assign more than one pusat internet per user.
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Grid>
              )}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <b>User Status</b>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="Status"
                  name="status"
                  value={userprofile?.status ? userprofile.status : 0}
                  onChange={handleSelectChange}
                >
                  {UserStatus &&
                    UserStatus.map((status, idx) => (
                      <FormControlLabel
                        key={idx}
                        value={idx}
                        control={<Radio />}
                        label={UserStatus[idx]}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateUserProfile()}
              >
                {id === "new" ? "Create" : "Update"} User Profile
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  // }else{
  //   return (
  //     <></>
  //   );
  // }
};
export default UserForm;
