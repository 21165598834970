import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import CodeIcon from "@material-ui/icons/Code";
import BlockIcon from "@material-ui/icons/Block";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
// import { compareObjectVals } from "crud-object-diff";
// import { Link } from "react-router-dom";
// import ShowcaseData from "../../Admin/Datas/Data";

import "./Modal.css";

interface FormProps {
    module?: any;
    label?: string;
    callback?: (value: object | null) => void;
}
const FormDialog = ({ module, label, callback }: FormProps) => {
    // export default function FormDialog() {
    const [open, setOpen] = React.useState(false);
    // const [module, setModule] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const useThis = () => {
    //   setOpen(false);
    //   if (typeof callback !== "undefined") {
    //     // callback(sqlfilter);
    //   }
    // };

    return (
        <>
            <IconButton
                size="small"
                onClick={handleClickOpen}
                className="modaldata"
            >
                <OpenInNewIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    {label ? label : "Datas"}
                </DialogTitle>
                <DialogContent>
                    {typeof module !== "undefined" && <div>showcase data</div>}
                </DialogContent>
                <DialogActions style={{ padding: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClose()}
                        startIcon={<BlockIcon />}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FormDialog;
