import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Autocomplete,
  LinearProgress,
  TextField
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { addDays, getTime } from "date-fns";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import bbox from "@turf/bbox";
import booleanWithin from "@turf/boolean-within";
import circle from "@turf/circle";
import { point as turfPoint } from "@turf/helpers";
import pointsWithinPolygon from "@turf/points-within-polygon";
import { onAuthStateChanged } from "firebase/auth"; // New import
import * as React from "react";
import { DateRange } from "react-day-picker";
import { useHistory, useParams } from "react-router-dom";

import area from "@turf/area";
import envelope from "@turf/envelope";
import { auth } from "../../config/Firebase";
import Map from "../Map/Map";
import InputDateRange from "./DateRange";
import Dialog from "./Dialog";

const drawerWidth = 270;

function Index() {
  const [selected, setSelected] = React.useState<any>({
    distric: "",
    premises: 0,
    suspects: 0,
  });
  const [layers, setLayers] = React.useState<any>({
    suspect: true,
    premise: true,
  });
  const [opendialog, setDialogOpen] = React.useState<boolean>(false);
  const [info, setInfo] = React.useState<any[]>();
  const [init, setInit] = React.useState<boolean>(false);
  const [bounds, setBounds] = React.useState<any>();
  const [point, setPoint] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  const { kategori_name } = useParams();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const [mapbox, setMapbox] = React.useState<any>();
  const [map, setMap] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [districts, setDistricts] = React.useState<any>();
  const [premises, setPremises] = React.useState<any>();
  const [suspects, setSuspects] = React.useState<any>();
  const [districtList, setDistrictList] = React.useState<any[]>();
  // const [premiseType, setPremiseType] = React.useState<any[]>();
  const [filters, setFilters] = React.useState<any>({
    district: "",
    category: kategori_name,
    premise_name: "",
    reporter_name: "",
    premiseFrom: "",
    premiseTo: "",
  });
  const defaultSelected: DateRange = {
    from: undefined,
    to: undefined,
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [range, setRange] = React.useState<DateRange | undefined>(
    defaultSelected
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (!mapbox) {
      return;
    }

    mapbox.addSource("premise", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    mapbox.addLayer({
      id: "premise_point",
      type: "circle",
      source: "premise", // reference the data source
      layout: {},
      paint: {
        "circle-color": "#ff0000",
        "circle-opacity": 1,
        "circle-radius": {
          base: 2,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
      },
    });

    mapbox.addSource("suspect", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    mapbox.addLayer({
      id: "suspect",
      type: "circle",
      source: "suspect", // reference the data source
      layout: {},
      paint: {
        "circle-color": "#ffff00",
        "circle-opacity": 1,
        "circle-radius": {
          base: 2,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
      },
    });

    mapbox.addSource("sarawak_district", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    // Add a new layer to visualize the polygon.
    mapbox.addLayer({
      id: "sarawak_district",
      type: "fill",
      source: "sarawak_district", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#DEE9F8", // blue color fill
        "fill-opacity": 0.2,
      },
    });
    // Add a black outline around the polygon.
    mapbox.addLayer({
      id: "outline",
      type: "line",
      source: "sarawak_district",
      layout: {},
      paint: {
        "line-color": "#e9e7fa",
        "line-width": 0.3,
      },
    });

    return () => {
      if (mapbox) {
        if (mapbox.getLayer("suspect")) {
          mapbox.removeLayer("suspect");
        }
        if (mapbox.getLayer("premise_point")) {
          mapbox.removeLayer("premise_point");
        }
        if (mapbox.getLayer("sarawak_district")) {
          mapbox.removeLayer("sarawak_district");
        }
        if (mapbox.getLayer("outline")) {
          mapbox.removeLayer("outline");
        }

        if (mapbox.getSource("suspect")) {
          mapbox.removeSource("suspect");
        }
        if (mapbox.getSource("premise")) {
          mapbox.removeSource("premise");
        }
        if (mapbox.getSource("sarawak_district")) {
          mapbox.removeSource("sarawak_district");
        }
      }
    };
  }, [mapbox]);

  const mapboxCallback = (newmap: any) => {
    if (
      typeof mapbox === "undefined" &&
      typeof google !== "undefined" &&
      typeof newmap !== "undefined"
    ) {
      setMapbox(newmap);
    }
  };

  const fitBounds = React.useCallback(
    (bbox: any[], padding?: number) => {
      var bb = new map.google.maps.LatLngBounds();
      // console.log(bbox);
      bb.extend({
        lat: bbox[1],
        lng: bbox[0],
      });
      bb.extend({
        lat: bbox[3],
        lng: bbox[2],
      });
      map?.map.fitBounds(bb, { padding: padding ? padding : 10 });
    },
    [map]
  );

  const updateFilters = React.useCallback(
    (field: string, value: any) => {
      if (filters[field] !== value) {
        setFilters({ ...filters, [field]: value });
      }
    },
    [filters]
  );

  React.useEffect(() => {
    if (typeof map?.map !== "undefined" && typeof bounds !== "undefined") {
      var bb = new map.google.maps.LatLngBounds();
      bb.extend({
        lat: bounds[1],
        lng: bounds[0],
      });
      bb.extend({
        lat: bounds[3],
        lng: bounds[2],
      });
      map?.map.fitBounds(bb, { padding: 20 });
    }
  }, [bounds, map]);

  React.useEffect(() => {
    if (
      typeof point !== "undefined" &&
      point?.lat &&
      point?.lng &&
      typeof mapbox !== "undefined" &&
      typeof districts !== "undefined"
    ) {
      var loc = turfPoint([point?.lng, point?.lat]);

      const thisdistrict = districts?.features.find((row: any) => {
        return booleanWithin(loc, row);
      });

      if (thisdistrict) {
        updateFilters("district", thisdistrict?.properties?.district);
      }

      // console.log("check", point);
    }
  }, [mapbox, point, districts, fitBounds, updateFilters]);

  React.useEffect(() => {
    const prepProps = (features: any) => {
      let newprops: any[] = [...features];
      for (let a = 0; a < newprops.length; a++) {
        if (newprops[a]?.properties._type === "premise") {
          newprops[a].properties.name = newprops[a]?.properties?.premise_name;
          newprops[a].properties.image_id =
            "cawangankhas/premise_picture/" + newprops[a]?.properties?.cases_id;
          if (typeof newprops[a].properties.description === "undefined") {
            newprops[a].properties.description =
              newprops[a]?.properties?.premise_type;
          }
        } else if (newprops[a]?.properties._type === "suspect") {
          newprops[a].properties.name = newprops[a]?.properties?.suspect_name;
          newprops[a].properties.image_id =
            "cawangankhas/suspect_profile_picture/" +
            newprops[a]?.properties?.cases_id;
          if (typeof newprops[a].properties.description === "undefined") {
            newprops[a].properties.description =
              newprops[a]?.properties?.suspect_ic_no;
          }
        }
      }
      return newprops;
    };
    if (
      typeof point !== "undefined" &&
      point?.lat &&
      point?.lng &&
      typeof mapbox !== "undefined" &&
      (typeof premises !== "undefined" || typeof suspects !== "undefined")
    ) {
      // const loc = turfPoint([point?.lng, point?.lat]);
      const zoom: number = mapbox.getZoom();
      // const radius = (1 / zoom ) * (100 / zoom);
      // const radius = (0.01 / zoom) * Math.pow(22 - zoom, 2);
      // const radius = Math.sqrt(30 - zoom) / 5;
      // const radius: number = 3.95833 * ((21 - zoom) / 21);
      //21 = 0.003
      //10 = 0.5
      //9 = 1.5
      //8 = 2.5
      //7 = 4
      // const radius: number = 0.5;
      const radius: number = Math.pow(2, 23 - zoom) * 0.0001;

      // console.log("here", zoom, radius);

      const center = [point?.lng, point?.lat];
      // const radius = 0.5;
      const options: any = {
        steps: 20,
        units: "kilometers",
        // properties: { foo: "bar" },
      };
      const thiscircle = circle(center, radius, options);

      let premisepoints: any = pointsWithinPolygon(premises, thiscircle);
      premisepoints.features = premisepoints.features.map((feature: any) => {
        feature.properties._type = "premise";
        return feature;
      });

      // console.log(premisepoints);
      const suspectpoints = pointsWithinPolygon(suspects, thiscircle);
      suspectpoints.features = suspectpoints.features.map((feature: any) => {
        feature.properties._type = "suspect";
        return feature;
      });
      // console.log(suspectpoints);

      let results: any[] = [];
      results = results.concat(premisepoints.features, suspectpoints.features);
      // console.log("xxx", results);

      // setSelected({
      //   district: filters?.district,
      //   premises: premisepoints.features.length,
      //   suspects: suspectpoints.features.length,
      // });

      if (results && results.length == 1) {
        setDialogOpen(true);
        setInfo(prepProps(results));
      } else if (results && results.length > 1 && zoom <= 17) {
        const gjson: any = {
          type: "FeatureCollection",
          features: results,
        };

        const env: any = envelope(gjson);
        // console.log("env", env);
        const areasize: number = area(env?.geometry);
        // console.log(areasize);

        if (areasize > 100) {
          var boundbox = bbox(gjson);
          fitBounds(boundbox, 10);
        } else {
          // console.log("areasize too small");
          setDialogOpen(true);
          setInfo(prepProps(results));
        }
      }

      // var boundbox = bbox(premisepoints);
      // fitBounds(boundbox);
      // const thisdistrict = districts?.features.find((row: any) => {
      //   return booleanWithin(loc, row);
      // });

      // if (thisdistrict) {
      //   console.log("check", point);

      //   const premisepoints = pointsWithinPolygon(premises, thisdistrict);
      //   console.log(premisepoints);
      //   // updateFilters("district", thisdistrict?.properties?.district);
      // }
    }
  }, [mapbox, point, premises, suspects, fitBounds]);

  let mapCallback = (newmap: any, maphandler: any) => {
    if (typeof map === "undefined" && typeof maphandler !== "undefined") {
      //   const darkMap = GoogleDarkMap as google.maps.MapTypeStyle[];

      maphandler.setOptions({
        // styles: darkMap,
        center: { lat: 2.5559400616701495, lng: 113.02748230696474 },
        zoom: 7,
      });

      maphandler.addListener("click", (e: any) => {
        // console.log("ssss", e.latLng.lat(), e.latLng.lng(), e.latLng.toJSON());
        setPoint(e.latLng.toJSON());
      });

      setMap({
        google: newmap,
        map: maphandler,
      });
    }
  };

  const handleKembali = () => {
    history.push("/dashboard/kategori");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearch = (key: any, value: any) => {
    // Assuming filters is an object representing your search filters
    setFilters({ ...filters, [key]: value });
    setPoint(undefined);
  };

  // const createQueryString = (filters: any) => {
  //   const params = new URLSearchParams();
  //   for (const key in filters) {
  //     if (filters[key]) {
  //       // Only add parameters with values
  //       params.append(key, filters[key]);
  //     }
  //   }
  //   return params.toString();
  // };

  // const handleClickCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // function handleCalendarChange(newRange: DateRange | undefined) {
  //   setRange(newRange);

  //   if (newRange && newRange.from && newRange.to) {
  //     const formattedFrom = format(newRange.from, "yyyy/MM/dd");
  //     const formattedTo = format(newRange.to, "yyyy/MM/dd");
  //     // setFilters({ ...filters, dateRange: `${formattedFrom} - ${formattedTo}` });
  //     setFilters({
  //       ...filters,
  //       premiseFrom: `${formattedFrom}`,
  //       premiseTo: `${formattedTo}`,
  //     });
  //   } else {
  //     setFilters({ ...filters, premiseFrom: "", premiseTo: "" });
  //   }
  // }

  function calendarChangeCreated(id: string, newRange: DateRange | undefined) {
    if (newRange && newRange.from && newRange.to) {
      const formattedFrom = getTime(newRange.from);
      const formattedTo = getTime(addDays(newRange.to, 1));
      setFilters({
        ...filters,
        [id + "From"]: `${formattedFrom}`,
        [id + "To"]: `${formattedTo}`,
      });
    } else {
      setFilters({ ...filters, [id + "From"]: "", [id + "To"]: "" });
    }
  }

  // React.useEffect(() => {
  //   if (!token) return;

  //   setLoading(true);

  //   handleMapData();
  //   setInit(true);

  //   return () => {
  //     setLoading(true);
  //   };
  // }, [token, mapbox, kategori_name, handleMapData]);

  React.useEffect(() => {
    if (token && typeof suspects === "undefined") {
      fetch(`/api/map/crime/suspect`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          data.features = data.features.map((row: any) => {
            row.properties.name = row?.properties?.suspect_name;
            row.properties._epoch = new Date(
              row?.properties?.created_at
            ).getTime();
            return row;
          });
          setSuspects(data);
        });
    }
  }, [token, suspects]);

  React.useEffect(() => {
    if (typeof suspects !== "undefined" && typeof mapbox !== "undefined") {
      if (suspects && mapbox && mapbox.getSource("suspect")) {
        mapbox.getSource("suspect").setData(suspects);
      }
    }
  }, [token, suspects, mapbox]);

  React.useEffect(() => {
    if (token && typeof premises === "undefined") {
      fetch(`/api/map/crime/premise`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          // premise_district
          data.features = data.features.map((row: any) => {
            row.properties.district = row?.properties?.premise_district;
            row.properties.name = row?.properties?.premise_name;
            row.properties._epoch_premise = new Date(
              row?.properties?.premise_started_date
            ).getTime();
            row.properties._epoch = new Date(
              row?.properties?.created_at
            ).getTime();
            return row;
          });
          setPremises(data);

          // setPremises(data);
        });
    }
  }, [token, premises]);

  React.useEffect(() => {
    if (typeof premises !== "undefined" && typeof mapbox !== "undefined") {
      if (premises && mapbox && mapbox.getSource("premise")) {
        mapbox.getSource("premise").setData(premises);
      }
    }
  }, [token, premises, mapbox]);

  React.useEffect(() => {
    if (token && typeof districts === "undefined") {
      fetch(`/api/map/district`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          var boundbox = bbox(data);
          // setBounds(boundbox);
          data.metadata = { bbox: boundbox };
          setDistricts(data);
        });
    }
  }, [token, districts]);

  React.useEffect(() => {
    if (typeof districts !== "undefined" && typeof mapbox !== "undefined") {
      if (districts && mapbox && mapbox.getSource("sarawak_district")) {
        mapbox.getSource("sarawak_district").setData(districts);
      }
    }
  }, [token, districts, mapbox]);

  React.useEffect(() => {
    if (
      typeof districts !== "undefined" &&
      typeof districtList === "undefined"
    ) {
      if (
        typeof districts?.features !== "undefined" &&
        districts.features.length > 0
      ) {
        setDistrictList(
          districts.features.map((row: any, idx: any) => {
            var boundbox = bbox(row);
            return {
              id: row.properties?.id !== "" ? row.properties?.id : idx,
              label: row.properties.district,
              bbox: boundbox,
            };
          })
        );
      }
    }
  }, [token, districts, districtList]);

  React.useEffect(() => {
    if (
      token &&
      typeof mapbox !== "undefined" &&
      typeof map !== "undefined" &&
      typeof districtList !== "undefined"
    ) {
      setLoading(true);

      let feature: any = districts?.metadata;

      let filtered: any[] = ["all"];

      if (filters?.district && filters?.district !== "") {
        mapbox.setFilter("sarawak_district", [
          "match",
          ["get", "district"],
          filters?.district,
          true,
          false,
        ]);

        const thisdistrict = districtList.find((row: any) => {
          return row.label === filters?.district;
        });

        if (thisdistrict) {
          feature = thisdistrict;
          filtered.push([
            "match",
            ["get", "district"],
            thisdistrict?.label,
            true,
            false,
          ]);
        }
      } else {
        mapbox.setFilter("sarawak_district", ["all"]);
      }

      if (filters?.premise_name && filters?.premise_name !== "") {
        // console.log(filters?.premise_name);
        // filtered.push([
        //   "match",
        //   ["get", "name"],
        //   filters?.premise_name,
        //   true,
        //   false,
        // ]);
        filtered.push([
          "in",
          filters?.premise_name.toLowerCase(),
          ["downcase", ["string", ["get", "name"]]],
        ]);
      }

      if (
        filters?.createdFrom &&
        filters?.createdFrom !== "" &&
        filters?.createdTo &&
        filters?.createdTo !== ""
      ) {
        console.log(filters?.createdFrom, filters?.createdTo);
        filtered.push([
          "all",
          [">=", ["get", "_epoch"], ["number", filters?.createdFrom - 0]],
          ["<=", ["get", "_epoch"], ["number", filters?.createdTo - 0]],
        ]);
      }
      let suspectfiltered: any[] = [...filtered];

      if (
        filters?.premiseFrom &&
        filters?.premiseFrom !== "" &&
        filters?.premiseTo &&
        filters?.premiseTo !== ""
      ) {
        console.log(filters?.premiseFrom, filters?.premiseTo);
        filtered.push([
          "all",
          [
            ">=",
            ["get", "_epoch_premise"],
            ["number", filters?.premiseFrom - 0],
          ],
          ["<=", ["get", "_epoch_premise"], ["number", filters?.premiseTo - 0]],
        ]);
      }
      mapbox.setFilter("premise_point", filtered);

      if (filters?.syndicate && filters?.syndicate !== "") {
        console.log(filters?.syndicate);
        suspectfiltered.push([
          "in",
          filters?.syndicate.toLowerCase(),
          ["downcase", ["string", ["get", "syndicate"]]],
        ]);
      }

      mapbox.setFilter("suspect", suspectfiltered);

      setTimeout(() => {
        const suspectFeatures = mapbox.queryRenderedFeatures({
          layers: ["suspect"],
        });

        const premiseFeatures = mapbox.queryRenderedFeatures({
          layers: ["premise_point"],
        });

        setSelected({
          district: filters?.district ? filters?.district : "SARAWAK",
          premises: premiseFeatures ? premiseFeatures.length : 0,
          suspects: suspectFeatures ? suspectFeatures.length : 0,
        });
      }, 500);

      fitBounds(feature.bbox);

      setLoading(false);
    }
  }, [
    token,
    mapbox,
    map,
    filters,
    districtList,
    districts,
    premises,
    suspects,
    fitBounds,
  ]);

  // function resetRange() {
  //   setRange({ from: undefined, to: undefined });
  //   setFilters({ ...filters, premiseFrom: "", premiseTo: "" });
  // }

  // function resetRangeCreated() {
  //   setRangeCreated({ from: undefined, to: undefined });
  //   setFilters({ ...filters, createdFrom: "", createdTo: "" });
  // }

  const focusStats = (layer: string) => {
    let focusfeatures: any[] = [];
    if (layer === "suspect") {
      focusfeatures = mapbox.queryRenderedFeatures({
        layers: ["suspect"],
      });
    } else if (layer === "premise") {
      focusfeatures = mapbox.queryRenderedFeatures({
        layers: ["premise_point"],
      });
    }

    if (focusfeatures.length > 0) {
      let gjson: any = {
        type: "FeatureCollection",
        features: [],
      };
      for (let a = 0; a < focusfeatures.length; a++) {
        const feature: any = focusfeatures[a].toJSON();
        gjson.features.push({
          type: feature?.type,
          properties: feature?.properties,
          geometry: feature?.geometry,
        });
      }
      var boundbox = bbox(gjson);
      fitBounds(boundbox, 10);
      console.log(gjson);
    }
  };

  const getDistrict = () => {
    if (typeof districtList !== "undefined") {
      const currentDistrict = districtList.find((row: any) => {
        return row.label === filters?.district;
      });
      return currentDistrict;
    } else {
      return {};
    }
  };

  const toggleDialog = (status?: boolean) => {
    // console.log(status);
    if (typeof status !== "undefined") {
      setDialogOpen(status);
    }
  };

  const toggleLayer = (id?: string) => {
    if (id) {
      console.log(id);
      if (id === "premise") {
        if (layers[id]) {
          mapbox.setLayoutProperty("premise_point", "visibility", "none");
        } else {
          mapbox.setLayoutProperty("premise_point", "visibility", "visible");
        }
      }
      if (id === "suspect") {
        if (layers[id]) {
          mapbox.setLayoutProperty("suspect", "visibility", "none");
        } else {
          mapbox.setLayoutProperty("suspect", "visibility", "visible");
        }
      }
      setLayers({ ...layers, [id]: !layers[id] });
    }
  };

  const drawer = (
    <div>
      <Toolbar>
        <IconButton onClick={handleKembali}>
          <ArrowBackIcon />
          <Typography marginLeft="15px">Kembali</Typography>
        </IconButton>
      </Toolbar>
      <Divider />
      <Box margin="10px 15px 0px 15px">
        <Autocomplete
          size="small"
          disablePortal
          id="district"
          value={districtList ? getDistrict() : null}
          // inputValue={filters?.district ? filters?.district : ""}
          options={districtList ? districtList : []}
          loading={!districtList}
          getOptionLabel={(option) => (option ? option?.label : "")}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          fullWidth
          sx={{ backgroundColor: "white", marginBottom: "10px" }}
          renderInput={(params) => (
            <TextField {...params} label="Pilih Daerah" />
          )}
          onChange={(_, value) =>
            handleSearch("district", value ? value?.label : "")
          }
        />

        <TextField
          size="small"
          id="premise_name"
          label="Nama premis/suspek"
          type="search"
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e) => handleSearch("premise_name", e.target.value)}
        />
        <TextField
          size="small"
          id="syndicate"
          label="Sindiket"
          type="search"
          fullWidth
          sx={{ marginBottom: "10px" }}
          onChange={(e) => handleSearch("syndicate", e.target.value)}
        />
        {/* <TextField
          size="small"
          id="suspect_name"
          label="Nama Suspek"
          type="search"
          fullWidth
          sx={{ marginBottom: "10px" }}
          onChange={(e) => handleSearch("suspect_name", e.target.value)}
        /> */}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <InputDateRange
            callback={(e: any) => {
              console.log(e);
              calendarChangeCreated("premise", e);
            }}
            label="Julat Tarikh Mula Beroperasi"
          />
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <InputDateRange
            callback={(e: any) => {
              console.log(e);
              calendarChangeCreated("created", e);
            }}
            label="Julat Laporan Dibuat"
          />
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          padding="0px"
          sx={{ marginTop: "15px" }}
        >
          <Paper elevation={2} sx={{ padding: "5px" }}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={4}>
                Sektor:
              </Grid>
              <Grid item xs={12} sm={8}>
                <strong>{selected?.district}</strong>
              </Grid>
              <Grid item xs={12} sm={4}>
                Premis:
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                sx={{ cursor: "pointer" }}
                onClick={() => focusStats("premise")}
              >
                <strong>{selected?.premises}</strong>
              </Grid>
              <Grid item xs={12} sm={4}>
                Suspek:
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                sx={{ cursor: "pointer" }}
                onClick={() => focusStats("suspect")}
              >
                <strong>{selected?.suspects}</strong>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#000000",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            letterSpacing={2}
            fontSize={18}
          >
            Map Checker
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Box display={loading ? "block" : "none"} width="100%">
          <LinearProgress />
        </Box>
        <Box
          width="100%"
          height="calc(100vh - 64px)"
          display={loading ? "none" : "block"}
          style={{ position: "relative" }}
        >
          <Map
            mapboxCallback={mapboxCallback}
            mapCallback={mapCallback}
            turnoflegend={true}
          />
          <div
            style={{
              position: "absolute",
              bottom: "40px",
              right: "30px",
              color: "#f5f5f5",
              zIndex: "5",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Paper elevation={2} sx={{ padding: "10px" }}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <CircleIcon
                    sx={{
                      color: "#d50000",
                      fontSize: "15px",
                      paddingRight: "5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleLayer("premise")}
                  >
                    {/* Premis */}
                    {layers?.premise ? "Premis" : <s>Premis</s>}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <CircleIcon
                    sx={{
                      color: "#ffea00",
                      fontSize: "15px",
                      paddingRight: "5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleLayer("suspect")}
                  >
                    {/* <s>Suspek</s> */}
                    {layers?.suspect ? "Suspek" : <s>Suspek</s>}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Box>
      </Box>
      <Dialog
        open={opendialog}
        callback={toggleDialog}
        profiles={info ? info : []}
      />
    </Box>
  );
}

export default Index;
