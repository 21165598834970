import React from "react";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// import type { RootState } from "../store/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 270,
  },
  media: {
    height: 140,
  },
  rootheader: {
    marginTop: "20px",
  },
});

const Admin: React.FunctionComponent = (props) => {
  const classes = useStyles();

  // const { profile } = useSelector((state: RootState) => state.userState);

  // console.log(profile);

  const adminmodules: any[] = [
    {
      path: "/module",
      name: "PEDi Modules",
      description: "PEDi data entry management by modules",
    },
    {
      path: "/agency/pusatinternet",
      name: "PEDi Activity Calendar",
      description: "PEDi's training/activity/programme calendar management",
    },
    {
      path: "/user/list",
      name: "User Management",
      description: "Admin user management module for managing system users.",
    },
    {
      path: "/user/acl",
      name: "Access Control Level",
      description: "Managing access control from user",
    },
  ];

  return (
    <div>
      <Typography variant="h5" component="h5">
        Administration
      </Typography>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.rootheader}
      >
        {adminmodules.map((route: any, index: number) => (
          <Grid item xs={12} sm={3} key={index}>
            <Card className={classes.root} key={index}>
              <CardActionArea component={Link} to={route?.path}>
                <CardMedia
                  className={classes.media}
                  image={`/images/admin${route.path}.png`}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="p">
                    {route?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {route?.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Admin;
