import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useRef } from "react";
import { useMap } from "./GoogleMaps";
import AccordionItem from "./Accordion";

// import Features from "../../Geocoding/Features";
import MapboxGLOverlayView from "@tuxuri/tuxgeo/dist/Map/MapboxGLOverlayView";

import * as mapboxgl from "mapbox-gl";
import VectorTiles from "./VectorTiles";

import { GoogleDarkMap } from "./GMap.darkMap";

// import "mapbox-gl/dist/mapbox-gl.css";
import "./mapbox-gl.css";
// import { relative } from "path";

const useStyles = makeStyles({
  box: {
    position: "absolute",
    width: "20vw",
    minWidth: "300px",
    top: 0,
    right: 0,
    // bottom: "14px",
    zIndex: 9,
    backgroundColor: "rgba(255,255,255,0.5)",
    flexGrow: 1,
    overflow: "auto",
    maxHeight: "calc(100% - 14px)",
    display: "flex",
    flexDirection: "column",
    // border: "1px solid #ff0000",
  },
  box_grid: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  // subcontent: {
  //   width: "100%",
  //   border: "1px solid #00ff00",
  //   height: "cacl(100% - 100px) !important",
  //   flexGrow: 1,
  //   overflow: "auto",
  // },
});

interface GeocodingProps {
  profile?: any;
  category?: any;
  grid?: boolean;
  features?: any[];
  filters?: any[];
  layerCallback?: (value: any) => void;
  mapOnClick?: (value: any) => void;
  mapboxCallback?: (value: any) => void;
}
const Geocoding = ({
  category,
  profile,
  grid,
  features,
  filters,
  layerCallback,
  mapOnClick,
  mapboxCallback,
}: GeocodingProps) => {
  // function Geocoding() {
  const [mapbox, setMapbox] = React.useState<any>(null);
  const map = useMap();
  const classes = useStyles();
  const iw = useRef<google.maps.InfoWindow>();
  const overlay = useRef<MapboxGLOverlayView>();
  const [panel, setPanel] = React.useState<string>("layer");

  useEffect(() => {
    function handleMouseMove() {
      if (!map) {
        return;
      }
      const mo = overlay.current;
      if (mo) {
        map.addListener("mousemove", (e: google.maps.MapMouseEvent) => {
          if (!mo.mapboxgl) {
            return;
          }
          if (e !== null && e.latLng) {
            const ftrs = mo.mapboxgl.queryRenderedFeatures(
              mo.getPixel(e?.latLng)
            );
            map.set("draggableCursor", ftrs.length > 0 ? "pointer" : null);
          }
        });
      }
    }

    function handleMouseClick(e: google.maps.MapMouseEvent) {
      if (e !== null && e.latLng) {
        const mo = overlay.current;
        //const m = mo?.mapboxgl as mapboxgl.Map;
        const ftrs = mo?.mapboxgl?.queryRenderedFeatures(mo.getPixel(e.latLng));
        if (map && ftrs && ftrs.length > 0) {
          console.log(ftrs);
          let listofids: any[] = [];
          for (let a = 0; a < ftrs.length; a++) {
            if (ftrs[a].properties && ftrs[a].properties?.id) {
              if (/(^\[|\]$)/i.test(ftrs[a].properties?.id)) {
                const ids = JSON.parse(ftrs[a].properties?.id);
                listofids.push({
                  layer: ftrs[a].sourceLayer,
                  properties: ids,
                });
              } else {
                listofids.push({
                  layer: ftrs[a].sourceLayer,
                  properties: [ftrs[a].properties],
                });
              }
            }
          }

          console.log(listofids);
          if (mapOnClick) {
            mapOnClick(listofids);
          } else {
            iw.current?.setContent(`Total: ${ftrs[0].properties?.total}`);
            iw.current?.setPosition(e.latLng);
            iw.current?.open(map);
          }
        }
      }
    }

    let listeners: google.maps.MapsEventListener[] = [];
    if (map && mapbox === null) {
      // copied from /tiles/gmap.html

      // console.log("here");
      const darkMap = GoogleDarkMap as google.maps.MapTypeStyle[];

      map.setOptions({ styles: darkMap });
      map.setCenter({ lat: 3.0296, lng: 101.74501 });

      // console.log("here", mapboxgl);
      const mo = new MapboxGLOverlayView(map, google.maps, mapboxgl, {
        maxZoom: 30,
        transformRequest: (
          url: string,
          resourceType: mapboxgl.ResourceType
        ) => {
          if (/(Source|Tile)/i.test(resourceType) && /token=/i.test(url)) {
            let inputs: any = {};
            let newinputs: any[] = [];
            let paths: string[] = url.split("?");
            if (typeof paths[1] !== "undefined" && paths[1] !== "") {
              let params: string[] = paths[1].split("&");
              for (let p in params) {
                const param = params[p].split("=");
                inputs[param[0]] = param[1];
                if (param[0] !== "token") {
                  newinputs.push(param[0] + "=" + param[1]);
                }
              }
            }
            if (typeof inputs.token !== "undefined" && inputs.token !== "") {
              return {
                url: paths[0] + "?" + newinputs.join("&"),
                headers: { Authorization: "Bearer " + inputs.token },
              };
            } else {
              return { url: url };
            }
          } else {
            return { url: url };
          }
        },
      });
      overlay.current = mo;

      var stops: number[][] = [];
      for (var z = 1; z <= 20; z++) {
        stops.push([z, z / 4]);
      }
      iw.current = new google.maps.InfoWindow();

      setMapbox(mo.mapboxgl);

      // console.log("here", mo.mapboxgl);

      if (typeof mapboxCallback !== "undefined") {
        mapboxCallback(mo.mapboxgl);
      }

      // if (mo && mo.mapboxgl) {
      //   const container = mo.mapboxgl.getContainer();
      //   console.log(container);

      //   var x = document.getElementsByClassName(container.className);
      //   console.log(x);
      //   if (typeof x !== "undefined" && x[0]) {
      //     console.log(x[0].parentElement);
      //   }
      // }

      listeners.push(map.addListener("mousemove", handleMouseMove));
      listeners.push(map.addListener("click", handleMouseClick));
    }

    return () => {
      // overlay.current?.overlay.onRemove();
      listeners.forEach((evt) => google.maps.event.removeListener(evt));
      // map?.setOptions({ styles: [] });
    };
    // }, [map, mapOnClick]);
  }, [map, mapbox, mapOnClick, mapboxCallback]);

  useEffect(() => {
    if (typeof mapbox !== "undefined" && mapbox !== null) {
      // Find the index of the first symbol layer in the map style
      // let firstSymbolId: any = "";
      // const layers = mapbox.getStyle().layers;
      // for (const layer of layers) {
      //     if (layer === "symbol") {
      //         firstSymbolId = layer.id;
      //         break;
      //     }
      // }
      // console.log(mapbox.getCanvas());
      // let tileserver: string =
      //   window.location.protocol + "//" + window.location.host;
      // console.log(tileserver);
      // mapbox.addSource("lbb", {
      //   type: "vector",
      //   tiles: [
      //     tileserver + "/api/tiles/mvt/{z}/{x}/{y}?apikey=test", //&nocache=1
      //   ],
      //   minzoom: 1,
      //   maxzoom: 20,
      // });
      // // mapbox.addLayer(
      // //     {
      // //         id: `geohasil_lbb`,
      // //         type: "circle",
      // //         source: "lbb",
      // //         "source-layer": "new",
      // //         paint: {
      // //             "circle-radius": 3,
      // //             "circle-color": "#ff0000",
      // //         },
      // //     }
      // //     // firstSymbolId
      // // );
      // mapbox.addLayer(
      //   {
      //     id: `geohasil_existing`,
      //     type: "circle",
      //     source: "lbb",
      //     "source-layer": "lbb",
      //     // paint: {
      //     //     "circle-radius": 3,
      //     //     "circle-color": "#FF0000",
      //     // },
      //     // filter: ["==", ["get", "source"], "clappia"],
      //     // layout: {
      //     //     "text-field": ["get", "name"],
      //     //     "text-variable-anchor": [
      //     //         "top",
      //     //         "bottom",
      //     //         "left",
      //     //         "right",
      //     //     ],
      //     //     "text-radial-offset": 0.5,
      //     //     "text-justify": "auto",
      //     // },
      //     paint: {
      //       "circle-color": {
      //         property: "last_assesment",
      //         type: "exponential",
      //         stops: [
      //           [{ zoom: 6, value: 0 }, "#00ff00"],
      //           [{ zoom: 6, value: 4 }, "#ffff00"],
      //           [{ zoom: 6, value: 7 }, "#ff0000"],
      //         ],
      //       },
      //       "circle-radius": {
      //         base: 0.5,
      //         stops: [
      //           [1, 1.0],
      //           [10, 2.0],
      //           [15, 3.0],
      //           [16, 4.0],
      //           [17, 4.5],
      //           [18, 4.5],
      //         ],
      //       },
      //       // "circle-color": [
      //       //     "match",
      //       //     ["get", "type"],
      //       //     "new",
      //       //     "#ff0000",
      //       //     "exists",
      //       //     "#00ff00",
      //       //     "test",
      //       //     "#e55e5e",
      //       //     /* other */ "#0000ff",
      //       // ],
      //       "circle-stroke-color": "rgba(206, 9, 9, 1)",
      //       "circle-opacity": 1,
      //     },
      //   }
      // firstSymbolId
      // );
    }
  }, [mapbox]);

  useEffect(() => {
    if (typeof features !== "undefined") {
      if (features.length > 0) {
        setPanel("feature");
      } else {
        // setPanel('');
      }
    }
  }, [features]);

  useEffect(() => {
    if (typeof mapbox !== "undefined" && typeof filters !== "undefined") {
      if (filters.length > 0) {
        // setPanel("feature");
        // mapbox.setFilter("geohasil_existing", [
        //     "==",
        //     "source",
        //     "clappia",
        // ]);
      } else {
        // setPanel('');
      }
    }
  }, [mapbox, filters]);

  const accordionCallback = (panel: string) => {
    setPanel(panel);
  };

  return (
    <div className={`${grid ? classes.box_grid : classes.box}`}>
      {features && (
        <AccordionItem
          label="Feature Profile"
          id="feature"
          toggle={panel}
          callback={accordionCallback}
        >
          <div className="accordiandetails">
            {map && google.maps && features && (
              <div>config feature info here</div>
              // <Features
              //   // map={map}
              //   features={features}
              //   find={[]}
              // />
            )}
          </div>
        </AccordionItem>
      )}
      {category && (
        <AccordionItem
          label="Layers"
          id="layer"
          toggle={panel}
          callback={accordionCallback}
        >
          <div className="accordiandetails">
            <VectorTiles
              mapbox={mapbox}
              category={category}
              profile={profile}
              layerCallback={layerCallback}
              grid={grid}
            />
          </div>
        </AccordionItem>
      )}
    </div>
  );

  // let items: any[] = [];

  // if (mapbox) {
  //   items.push({
  //     label: "Feature Profile",
  //     component: () => <Features map={mapbox} features={features} find={[]} />,
  //   });
  //   items.push({
  //     label: "Layers",
  //     component: () => (
  //       <VectorTiles
  //         mapbox={mapbox}
  //         category={category}
  //         profile={profile}
  //         layerCallback={layerCallback}
  //         grid={grid}
  //       />
  //     ),
  //   });
  // }
  // if (items && items.length > 0) {
  //   return (
  //     <div className={`${grid ? classes.box_grid : classes.box}`}>
  //       <Accordion items={items} />
  //       {/* {mapbox && (
  //       <VectorTiles
  //         mapbox={mapbox}
  //         category={category}
  //         profile={profile}
  //         layerCallback={layerCallback}
  //         grid={grid}
  //       />
  //     )} */}
  //     </div>
  //   );
  // } else {
  //   return <></>;
  // }
};

export default Geocoding;
