import React, { Fragment } from "react";

import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import TableHead from "@mui/material/TableHead";
// import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
// import TablePagination from "@mui/material/TablePagination";
// import IconButton from "@mui/material/IconButton";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
import StoreIcon from "@mui/icons-material/Store";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { format } from "date-fns";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import { onAuthStateChanged } from "firebase/auth"; // New import
import { auth } from "../../config/Firebase";
import Map from "../MapLight";
import "./Business.css";

const BusinessData = () => {
  const { id } = useParams();
  const [token, setToken] = React.useState<any>("");
  const [data, setData] = React.useState<any[]>();
  const [columns, setColumns] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);
      fetch("/api/business-description/detail/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            // console.log(data);
            if (data.features.length > 0) {
              let newcolumns: any[] = [];
              for (let p in data.features[0].properties) {
                // console.log(p);
                if (
                  typeof data.features[0].properties[p] !== "object" &&
                  data.features[0].properties[p] !== "" &&
                  !/^(id|it_ref_no)$/i.test(p)
                ) {
                  newcolumns.push({
                    id: p.toString(),
                    label: p.toUpperCase(),
                  });
                }
              }
              setColumns(newcolumns);
            }
            setData(data.features);
            setLoading(false);
          }
        });
      // .catch((e: any) => {
      //   setLoading(false);
      //   window.alert("Fail to fetch data");
      //   console.log(e);
      // });
    };

    if (token && typeof data === "undefined" && typeof id !== "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, data, id]);

  const openPhotos = (photo: any) => {
    window.open(photo?.path, "photo");
  };

  let left: any = { md: 0, sm: 0 };
  let right: any = { md: 12, sm: 12 };

  if (
    typeof data !== "undefined" &&
    data.length > 0 &&
    ((typeof data[0].properties.photoBusiness !== "undefined" &&
      data[0].properties.photoBusiness.length > 0) ||
      (typeof data[0].properties.photoVehicles !== "undefined" &&
        data[0].properties.photoVehicles.length > 0))
  ) {
    left = { md: 4, sm: 3 };
    right = { md: 8, sm: 9 };
  }

  return (
    <React.Fragment>
      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "300px",
          overflow: "auto",
          marginBottom: "20px",
        }}
      >
        <Map options={{ height: "300px" }} features={data} />
      </Paper>

      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data && data.length > 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={1} style={{ textAlign: "center" }}>
                <StoreIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" className="cardtitle">
                  Rekod Kunjungan
                </Typography>
              </Grid>
              <Grid item xs={12} sm={left.sm} md={left.md}>
                <Grid container spacing={3}>
                  {typeof data[0].properties.photoBusiness !== "undefined" &&
                    data[0].properties.photoBusiness.map(
                      (photo: any, idx: any) => (
                        <Grid item key={idx} xs={12} lg={6}>
                          <div
                            className="businessphotocontainer"
                            style={{ backgroundImage: `url(${photo.path})` }}
                            onClick={() => openPhotos(photo)}
                          ></div>
                        </Grid>
                      )
                    )}
                  {typeof data[0].properties.photoVehicles !== "undefined" &&
                    data[0].properties.photoVehicles.map(
                      (photo: any, idx: any) => (
                        <Grid item key={idx} xs={12} sm={6} md={3} lg={2}>
                          <div
                            className="businessphotocontainer"
                            style={{ backgroundImage: `url(${photo.path})` }}
                            onClick={() => openPhotos(photo)}
                            title={
                              typeof photo?.vehicleNo !== "undefined" &&
                              photo?.vehicleNo !== ""
                                ? photo?.vehicleNo
                                : ""
                            }
                          >
                            {typeof photo?.vehicleNo !== "undefined" &&
                              photo?.vehicleNo !== "" && (
                                <div className="businessphotocontainer_caption">
                                  {photo?.vehicleNo}
                                </div>
                              )}
                          </div>

                          {/* <a
                            href={`${photo.path}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={photo.path}
                              alt={data[0].properties.name}
                              width="100px"
                              className="businessphoto"
                            />
                          </a> */}
                        </Grid>
                      )
                    )}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={right.sm} md={right.md}>
                <TableContainer component={Paper}>
                  <Table size="small" className="tabledata">
                    <TableBody>
                      {typeof data[0].properties.cases !== "undefined" &&
                        data[0].properties.cases && (
                          <TableRow>
                            <TableCell>IT REF. NO.</TableCell>
                            <TableCell>
                              {data[0].properties.cases.file_type}{" "}
                              {data[0].properties.cases.it_ref_no}
                            </TableCell>
                          </TableRow>
                        )}
                      {columns.map((col: any, idx: any) => (
                        <TableRow key={idx}>
                          <TableCell>{col.label.replace("_", " ")}</TableCell>
                          {/^(created_at|updated_at)/i.test(col.id) ? (
                            <TableCell>
                              {format(
                                new Date(data[0].properties[col.id]),
                                "dd MMMM yyyy HH:mm aa"
                              )}
                            </TableCell>
                          ) : (
                            <TableCell>{data[0].properties[col.id]}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "center" }}>
                <AlternateEmailIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" className="cardtitle">
                  Rekod Alamat
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small" className="tabledata">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Alamat 1</TableCell>
                        <TableCell>Alamat 2</TableCell>
                        <TableCell>Alamat 3</TableCell>
                        <TableCell>Jenis</TableCell>
                        <TableCell>Bandar</TableCell>
                        <TableCell>Poskod</TableCell>
                        <TableCell>Negeri</TableCell>
                        <TableCell>lat</TableCell>
                        <TableCell>lng</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[0].properties?.cases?.addresses.map(
                        (address: any, idx: any) => (
                          <TableRow key={idx}>
                            <TableCell style={{ textAlign: "right" }}>
                              {idx + 1}.
                            </TableCell>
                            <TableCell>{address.address_line1}</TableCell>
                            <TableCell>{address.address_line2}</TableCell>
                            <TableCell>{address.address_line3}</TableCell>
                            <TableCell>{address.address_type}</TableCell>
                            <TableCell>{address.city}</TableCell>
                            <TableCell>{address.postcode}</TableCell>
                            <TableCell>{address.state}</TableCell>
                            <TableCell>{address.lat}</TableCell>
                            <TableCell>{address.lng}</TableCell>
                          </TableRow>
                        )
                      )}
                      {(data[0].properties?.cases === null ||
                        typeof data[0].properties?.cases?.addresses ===
                          "undefined") && (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={10}
                          >
                            Tiada Data Berkaitan
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "center" }}>
                <CurrencyExchangeIcon
                  style={{ color: "rgba(0, 0, 0, 0.54)" }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" className="cardtitle">
                  Rekod Pencukaian
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small" className="tabledata">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Tahun Pencukaian</TableCell>
                        <TableCell>main business code</TableCell>
                        <TableCell>Installment Paid</TableCell>
                        <TableCell>std_from_spouse</TableCell>
                        <TableCell>std_paid</TableCell>
                        <TableCell>tax_bal</TableCell>
                        <TableCell>tax_payable_repayable</TableCell>
                        <TableCell>total_income</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[0].properties?.cases?.assesments.map(
                        (assesment: any, idx: any) => (
                          <TableRow key={idx}>
                            <TableCell style={{ textAlign: "right" }}>
                              {idx + 1}.
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {assesment.assessment_year}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {assesment.main_business_code}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {typeof assesment.installment_paid !==
                                "undefined" &&
                              assesment.installment_paid !== null &&
                              assesment.installment_paid
                                ? parseFloat(
                                    assesment.installment_paid
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {typeof assesment.std_from_spouse !==
                                "undefined" &&
                              assesment.std_from_spouse !== null &&
                              assesment.std_from_spouse
                                ? parseFloat(
                                    assesment.std_from_spouse
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {typeof assesment.std_paid !== "undefined" &&
                              assesment.std_paid !== null
                                ? parseFloat(assesment.std_paid).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {typeof assesment.tax_bal !== "undefined" &&
                              assesment.tax_bal !== null
                                ? parseFloat(assesment.tax_bal).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {typeof assesment.tax_payable_repayable !==
                                "undefined" &&
                              assesment.tax_payable_repayable !== null
                                ? parseFloat(
                                    assesment.tax_payable_repayable
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              {typeof assesment.total_income !== "undefined" &&
                              assesment.total_income !== null
                                ? parseFloat(
                                    assesment.total_income
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                : ""}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>

                    {(data[0].properties?.cases === null ||
                      typeof data[0].properties?.cases?.assesments ===
                        "undefined") && (
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }} colSpan={10}>
                          Tiada Data Berkaitan
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default BusinessData;
