import React, { Fragment } from "react";

import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { format, isValid, subDays } from "date-fns";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CsvDownloader from "react-csv-downloader";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CircularProgress from "@material-ui/core/CircularProgress";

import { onAuthStateChanged } from "firebase/auth"; // New import
import { auth } from "../../config/Firebase";
import "./Dataset.css";

const businessStatus = [
  {
    id: 1,
    label: "Fail Wujud",
  },
  {
    id: 2,
    label: "Fail Tak Wujud",
  },
];

const businessSerahanActions = [
  {
    id: "ya",
    label: "YA",
  },
  {
    id: "tidak",
    label: "TIDAK",
  },
];

const TableData = () => {
  // const [keyword, setKeyword] = React.useState<string>();
  const [datefilters, setDateFilters] = React.useState<any>({
    // startdate: new Date(),
    startdate: subDays(new Date(), 3),
    enddate: new Date(),
    init: false,
  });
  const [filters, setFilters] = React.useState<any>({
    // startdate: new Date(),
    // enddate: new Date(),
    tindakan: "",
    tindakan_serahan: "",
  });
  const [token, setToken] = React.useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [data, setData] = React.useState<any>();
  const [businessactions, setBusinessActions] = React.useState<any[]>();
  const [departments, setDepartments] = React.useState<any[]>();
  const [columns] = React.useState<any[]>([
    {
      id: "status",
      label: "Status",
      width: "1%",
    },
    {
      id: "name",
      label: "Nama",
    },
    {
      id: "rob_no",
      label: "ROB/ROC",
    },
    {
      id: "address",
      label: "Address",
    },
    {
      id: "industry_type",
      label: "Industry",
    },
    {
      id: "tel_no",
      label: "Phone",
    },
    {
      id: "created_at",
      label: "Created",
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [togglefilter, setToggleFilter] = React.useState<boolean>(true);
  const { type, user } = useParams();

  // console.log(type);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/business-description/action", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (isMounted) {
            setBusinessActions(data);
          }
        })
        .catch((e: any) => {
          setLoading(false);
          console.log(e);
        });
    };

    if (token && typeof businessactions === "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [businessactions, token]);

  React.useEffect(() => {
    // let isMounted = true;
    const fetchData = () => {
      setLoading(true);
      setDateFilters({ ...datefilters, init: true });
      let param: string = "";
      let params: string[] = [];

      if (typeof type !== "undefined" && type !== null && type !== "") {
        params.push("type=" + type.replace("+", " "));
      }
      if (typeof user !== "undefined" && user !== null && user !== "") {
        params.push("user=" + user.replace("+", " "));
      }

      // if (typeof datefilters !== "undefined" && datefilters?.startdate) {
      //   console.log(datefilters?.startdate);
      //   // params.push("user=" + user.replace("+", " "));
      // }
      if (typeof datefilters?.startdate !== "undefined") {
        params.push(
          "start=" + format(datefilters?.startdate, "yyyy-MM-dd 00:00:00")
        );
      }
      if (typeof datefilters?.enddate !== "undefined") {
        params.push(
          "end=" + format(datefilters?.enddate, "yyyy-MM-dd 23:59:59")
        );
      }
      if (params.length > 0) {
        param = "?" + params.join("&");
      }
      fetch("/api/business-description/geojson" + param, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.body);
          return response.json();
        })
        .then((data) => {
          // console.log(isMounted);
          setData(data);
          setLoading(false);
          // if (isMounted) {
          //   console.log(data);
          //   setData(data);

          //   // console.log(data?.metadata?.created);
          //   // if (
          //   //   typeof data?.metadata?.created?.mindate !== "undefined" &&
          //   //   typeof data?.metadata?.created?.maxdate !== "undefined"
          //   // ) {
          //   //   setDateFilters({
          //   //     ...datefilters,
          //   //     startdate: new Date(data?.metadata?.created?.mindate).setHours(
          //   //       0,
          //   //       0,
          //   //       0
          //   //     ),
          //   //     enddate: new Date(data?.metadata?.created?.maxdate).setHours(
          //   //       23,
          //   //       59,
          //   //       59
          //   //     ),
          //   //   });
          //   // }
          //   setLoading(false);
          // }
        })
        .catch((e: any) => {
          setLoading(false);
          window.alert("Fail to fetch data");
          console.log(e);
        });
    };

    if (
      token &&
      typeof datefilters !== "undefined" &&
      !datefilters?.init &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      // isMounted = false;
    };
  }, [token, data, datefilters, type, user]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setKeyword(event.target.value);
    setFilters({
      ...filters,
      keyword: event.target.value,
    });
    // dispatch(setSearch(event.currentTarget.value));
  };

  const formatValue = (item: any, col: any) => {
    if (col === "status") {
      const status: any = {
        "1": <CheckCircleIcon style={{ color: "#00ff00" }} />,
        "2": <ErrorIcon style={{ color: "#ff0000" }} />,
      };
      return <>{status[item[col]]}</>;
    } else {
      return <>{item[col]}</>;
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    // console.log(event.target.name);
    if (event.target.name === "state_id") {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value,
        department_id: "",
      });
      const idx = data?.metadata?.states.findIndex((state: any, ix: any) => {
        return state.id.toString() === event.target.value.toString();
      });
      if (idx >= 0) {
        setDepartments(data?.metadata?.states[idx].departments);
      }
    } else if (event.target.name === "tindakan") {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value ? event.target.value : "",
        tindakan_serahan: "",
      });
    } else {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value ? event.target.value : "",
      });
    }
  };

  const asyncFnGetColumns = (download: any[]) => {
    let newkeys: any = {};
    for (let f = 0; f < download.length; f++) {
      let newfeature: any = JSON.parse(JSON.stringify(download[f]));
      for (let p in newfeature.properties) {
        newkeys[p] = p;
      }
    }
    const ordered: any[] = Object.keys(newkeys)
      .sort()
      .reduce((obj: any, key: any) => {
        obj[key] = newkeys[key];
        return obj;
      }, {});

    let propkeys = [
      {
        id: "id",
        displayName: "id",
      },
      {
        id: "name",
        displayName: "name",
      },
      {
        id: "ic_no",
        displayName: "ic_no",
      },
      {
        id: "it_ref_no",
        displayName: "it_ref_no",
      },
      {
        id: "rob_no",
        displayName: "rob_no",
      },
    ];
    for (let p in ordered) {
      if (
        !/^(id|name|ic_no|it_ref_no|rob_no|lat|long|actions|created_at|tindakan|tindakan_serahan|semakan_lanjut|pcta_action_name|pcta_action)$/i.test(
          newkeys[p]
        )
      ) {
        // const name: string = newkeys[p].replace(/_/g, " ");
        propkeys.push({
          id: newkeys[p],
          // displayName: name.charAt(0).toUpperCase() + name.slice(1),
          displayName: newkeys[p], //name.toUpperCase(),
        });
      }
    }

    propkeys.push({
      id: "tindakan",
      displayName: "tindakan",
    });
    propkeys.push({
      id: "tindakan_serahan",
      displayName: "tindakan_serahan",
    });
    propkeys.push({
      id: "semakan_lanjut",
      displayName: "semakan_lanjut",
    });
    propkeys.push({
      id: "pcta_action",
      displayName: "pcta_action",
    });
    propkeys.push({
      id: "pcta_action_name",
      displayName: "pcta_action_name",
    });
    // propkeys.push({
    //   id: "actions",
    //   displayName: "actions",
    // });
    propkeys.push({
      id: "lat",
      displayName: "lat",
    });
    propkeys.push({
      id: "long",
      displayName: "long",
    });
    propkeys.push({
      id: "created_at",
      displayName: "created_at",
    });

    return propkeys;
  };

  const asyncFnComputeDate = (download: any[]) => {
    let newdata: any[] = [];
    for (let f = 0; f < download.length; f++) {
      let newfeature: any = JSON.parse(JSON.stringify(download[f]));
      // let propkeys = [];
      // for (let p in newfeature.properties) {
      //   // propkeys.push(p);
      //   propkeys.push({
      //     id: p,
      //     displayName: p.replace("_", " "),
      //   });
      // }

      // setDownloadColumns(propkeys);

      for (let p in newfeature.properties) {
        // for (let kp in propkeys.sort()) {
        // const p = propkeys[kp];
        if (typeof newfeature.properties[p] === "object") {
          if (p === "actions") {
            if (
              typeof newfeature.properties[p] !== "undefined" &&
              newfeature.properties[p]
            ) {
              for (let a = 0; a < newfeature.properties[p].length; a++) {
                for (let ap in newfeature.properties[p][a]) {
                  if (
                    (typeof newfeature.properties[p][a][ap] === "boolean" &&
                      newfeature.properties[p][a][ap]) ||
                    newfeature.properties[p][a][ap] === "TRUE"
                  ) {
                    newfeature.properties[ap] = "YA";
                  } else if (
                    (typeof newfeature.properties[p][a][ap] === "boolean" &&
                      !newfeature.properties[p][a][ap]) ||
                    newfeature.properties[p][a][ap] === "FALSE"
                  ) {
                    newfeature.properties[ap] = "TIDAK";
                  } else {
                    if (/^(ya|tidak)$/i.test(newfeature.properties[p][a][ap])) {
                      newfeature.properties[ap] =
                        newfeature.properties[p][a][ap].toUpperCase();
                    } else {
                      newfeature.properties[ap] =
                        newfeature.properties[p][a][ap];
                    }
                  }
                }
              }
            }
            delete newfeature.properties[p];
          } else {
            newfeature.properties[p] = JSON.stringify(newfeature.properties[p]);
          }
        } else {
          newfeature.properties[p] = newfeature.properties[p]
            .toString()
            .replace(/\n/g, "\\n");
        }
        if (
          newfeature.properties[p] === null ||
          newfeature.properties[p] === "null"
        ) {
          newfeature.properties[p] = "";
        }
      }
      newdata.push(newfeature.properties);
    }
    return Promise.resolve(newdata);
  };

  let rows: any[] = [];
  let results: any[] = [];

  if (data?.features) {
    let countttlfilter: number = Object.keys(filters).length;

    if (countttlfilter > 0) {
      // console.log(filters);
      results = data?.features.filter((item: any) => {
        const row = item.properties;

        let matched: number = 0;
        if (filters?.keyword && filters?.keyword !== "") {
          const word = filters?.keyword.toLowerCase();
          for (let r in row) {
            if (
              typeof row[r] !== "object" &&
              row[r] &&
              row[r].toString().toLowerCase().indexOf(word) >= 0
            ) {
              matched++;
              break;
              // return row;
            }
          }
        }

        let countfilters: number = 0;

        for (let f in filters) {
          if (!/^(keyword|tindakan)/i.test(f)) {
            if (
              filters[f] !== "" &&
              typeof row[f] !== "undefined" &&
              row[f] !== null
            ) {
              if (/(date)$/i.test(f)) {
              } else if (row[f].toString() === filters[f].toString()) {
                matched++;
              }
            }
          } else if (/^tindakan/i.test(f)) {
            // console.log(row.actions);
            if (
              row.actions !== null &&
              row.actions !== "null" &&
              typeof row.actions === "string"
            ) {
              row.actions = JSON.parse(row.actions);
            }
            if (
              filters[f] !== "" &&
              typeof row.actions !== "undefined" &&
              row.actions !== null &&
              row.actions.length > 0
            ) {
              for (let a = 0; a < row.actions.length; a++) {
                if (row.actions[a][f] === filters[f]) {
                  // console.log("f", f);
                  matched++;
                  // matched++;
                }
              }
            }
          }
          if (filters[f] !== "") {
            countfilters++;
          }
        }

        // if (datefilters?.startdate !== "" && datefilters?.enddate === "") {
        //   if (
        //     new Date(row.created_at).getTime() >=
        //     new Date(datefilters?.startdate).getTime()
        //   ) {
        //     matched++;
        //   }
        // } else if (
        //   datefilters?.enddate !== "" &&
        //   datefilters?.startdate === ""
        // ) {
        //   if (
        //     new Date(row.created_at).getTime() <=
        //     new Date(datefilters?.enddate).getTime()
        //   ) {
        //     matched++;
        //   }
        // } else if (
        //   datefilters?.enddate !== "" &&
        //   datefilters?.startdate !== ""
        // ) {
        //   if (
        //     new Date(row.created_at).getTime() >=
        //       new Date(datefilters?.startdate).getTime() &&
        //     new Date(row.created_at).getTime() <=
        //       new Date(datefilters?.enddate).getTime()
        //   ) {
        //     matched++;
        //     matched++;
        //   }
        // }

        // console.log(matched, countfilters);

        if (matched.toString() === countfilters.toString()) {
          return row;
        } else {
          return null;
        }
      });
    } else {
      results = data?.features;
    }
    rows = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }
  return (
    <React.Fragment>
      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" className="cardtitle">
              Rekod Perniagaan
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <CsvDownloader
              datas={asyncFnComputeDate(results)}
              wrapColumnChar='"'
              filename="business-descriptions.csv"
              columns={asyncFnGetColumns(results)}
            >
              <IconButton aria-label="filter">
                <CloudDownloadIcon />
              </IconButton>
            </CsvDownloader>
          </Grid>
        </Grid>

        <Table size="small" className="tabledata">
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton
                  aria-label="filter"
                  onClick={() => {
                    setToggleFilter(!togglefilter);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </TableCell>
              <TableCell colSpan={3}>
                <OutlinedInput
                  size="small"
                  fullWidth
                  id="outlined-adornment-password"
                  type="text"
                  value={filters?.keyword ? filters?.keyword : ""}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </TableCell>
              {results && results.length > rowsPerPage ? (
                <TablePagination
                  colSpan={columns ? columns.length - 3 : 3}
                  count={results.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                  className="pagination"
                  rowsPerPageOptions={[20, 40, 80, 100]}
                />
              ) : (
                <TableCell
                  colSpan={columns ? columns.length - 1 : 0}
                ></TableCell>
              )}
            </TableRow>
            {togglefilter && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="status">Status</InputLabel>
                        <Select
                          labelId="status"
                          id="status"
                          name="status"
                          value={
                            typeof filters?.status !== "undefined"
                              ? filters?.status
                              : ""
                          }
                          label="Status"
                          onChange={handleSelectChange}
                        >
                          <MenuItem value="">
                            <em>Semua</em>
                          </MenuItem>
                          {businessStatus &&
                            businessStatus.map((status: any, idx: any) => (
                              <MenuItem key={idx} value={status?.id}>
                                {status?.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="state">Negeri</InputLabel>
                        <Select
                          labelId="state"
                          id="state_id"
                          name="state_id"
                          value={
                            typeof filters?.state_id !== "undefined"
                              ? filters?.state_id
                              : ""
                          }
                          label="Negeri"
                          onChange={handleSelectChange}
                        >
                          <MenuItem value="">
                            <em>Semua</em>
                          </MenuItem>
                          {data?.metadata?.states &&
                            data?.metadata?.states.map(
                              (state: any, idx: any) => (
                                <MenuItem key={idx} value={state?.id}>
                                  {state?.name}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="department">Cawangan</InputLabel>
                        <Select
                          labelId="department"
                          id="department_id"
                          name="department_id"
                          value={
                            typeof filters?.department_id !== "undefined"
                              ? filters?.department_id
                              : ""
                          }
                          label="Cawangan"
                          onChange={handleSelectChange}
                        >
                          <MenuItem value="">
                            <em>Semua</em>
                          </MenuItem>
                          {departments &&
                            departments.map((department: any, idx: any) => (
                              <MenuItem key={idx} value={department?.id}>
                                {department?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormControl size="small" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Dari"
                            value={
                              datefilters?.startdate
                                ? datefilters?.startdate
                                : ""
                            }
                            onChange={(newValue) => {
                              if (
                                isValid(newValue) &&
                                newValue.getFullYear() >= 2020
                              ) {
                                setDateFilters({
                                  ...datefilters,
                                  init: false,
                                  startdate: newValue.setHours(0, 0, 0),
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                            inputFormat="dd/MM/yyyy"
                            maxDate={
                              datefilters?.enddate ? datefilters?.enddate : ""
                            }
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl size="small" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Sehingga"
                            value={
                              datefilters?.enddate ? datefilters?.enddate : ""
                            }
                            onChange={(newValue) => {
                              if (
                                isValid(newValue) &&
                                newValue.getFullYear() >= 2020
                              ) {
                                setDateFilters({
                                  ...datefilters,
                                  init: false,
                                  enddate: newValue.setHours(23, 59, 59),
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                            inputFormat="dd/MM/yyyy"
                            minDate={
                              datefilters?.startdate
                                ? datefilters?.startdate
                                : ""
                            }
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="tindakan">Tindakan</InputLabel>
                        <Select
                          labelId="tindakan"
                          id="tindakan"
                          name="tindakan"
                          value={
                            typeof filters?.tindakan !== "undefined"
                              ? filters?.tindakan
                              : ""
                          }
                          label="Tindakan"
                          onChange={handleSelectChange}
                        >
                          <MenuItem value="">
                            <em>Semua</em>
                          </MenuItem>
                          {businessactions &&
                            businessactions.map((action: any, idx: any) => (
                              <MenuItem key={idx} value={action?.code}>
                                {action?.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/^serahan_/i.test(filters?.tindakan) && (
                      <Grid item xs={12} md={3}>
                        <FormControl size="small" fullWidth>
                          <InputLabel id="tindakan_serahan">
                            Serahan ?
                          </InputLabel>
                          <Select
                            labelId="tindakan_serahan"
                            id="tindakan_serahan"
                            name="tindakan_serahan"
                            value={
                              typeof filters?.tindakan_serahan !== "undefined"
                                ? filters?.tindakan_serahan
                                : ""
                            }
                            label="Serahan ?"
                            onChange={handleSelectChange}
                          >
                            <MenuItem value="">
                              <em>Semua</em>
                            </MenuItem>
                            {businessSerahanActions &&
                              businessSerahanActions.map(
                                (serahanaction: any, idx: any) => (
                                  <MenuItem key={idx} value={serahanaction?.id}>
                                    {serahanaction?.label}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell style={{ width: "1%" }}>#</TableCell>
              {columns.map((col: any, idx: any) => (
                <TableCell
                  key={idx}
                  style={{ width: col.width ? col.width : "unset" }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              <>
                {rows.map((row: any, idx: any) => (
                  <TableRow key={idx}>
                    <TableCell style={{ textAlign: "right" }}>
                      {rowsPerPage * page + idx + 1}
                    </TableCell>
                    {columns.map((col: any, id: any) => (
                      <Fragment key={id}>
                        {/^created_at/i.test(col.id) ? (
                          <TableCell
                            align="right"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {format(
                              new Date(row?.properties[col.id]),
                              "dd MMM yy HH:mm aa"
                            )}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {/^name/i.test(col.id) ? (
                              <>
                                <Link
                                  to={
                                    "/dashboard/business/" + row?.properties?.id
                                  }
                                >
                                  {row?.properties[col.id]}
                                </Link>
                              </>
                            ) : (
                              // row?.properties[col.id]
                              <>{formatValue(row?.properties, col.id)}</>
                            )}
                          </TableCell>
                        )}
                      </Fragment>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns ? columns?.length + 1 : 2}
                  style={{ textAlign: "center" }}
                >
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            {results && results.length > rowsPerPage && (
              <TableRow>
                <TablePagination
                  colSpan={columns ? columns.length + 1 : 2}
                  count={results.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                  className="pagination"
                  rowsPerPageOptions={[20, 40, 80, 100]}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default TableData;
