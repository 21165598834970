import React, { useEffect, useState, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import type { RootState } from "./store/index";
import { onAuthStateChanged } from "firebase/auth"; // New import

import AuthRoute from "./components/AuthRoute/Index";
import { auth } from "./config/Firebase";
import Logging from "./config/Logging";
import Routes from "./config/Routes";
// import Header from "./pages/Header";
// import Footer from "./pages/Footer";
import "react-day-picker/dist/style.css";
import "./App.css";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexDirection: "column",
//     minHeight: "100vh",
//   },
//   main: {
//     // marginTop: theme.spacing(8),
//     marginBottom: theme.spacing(4),
//   },
//   container: {
//     minHeight: "calc(100vh - 290px) !important",
//     // border: "1px solid #ff0000",
//   },
//   footer: {
//     // position: "fixed",
//     bottom: "0",
//     minHeight: "70px",
//     // width: "calc(100% -  20px)",
//     padding: "10px",
//     paddingTop: "20px",
//     marginTop: "auto",
//     backgroundColor:
//       theme.palette.type === "light"
//         ? theme.palette.grey[200]
//         : theme.palette.grey[800],
//   },
// }));

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  // const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  // const { profile } = useSelector((state: RootState) => state.userState);

  // const history = useHistory();

  useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        setUser(user);
        Logging.info("User detected.");
      } else {
        Logging.info("No user detected");
      }

      setLoading(false);
    });
  }, []);

  if (loading) return <Spinner color="info" />;

  // console.log(user);

  return (
    <>
      <Switch>
        {Routes.map((route, index) => {
          if (/\/form/i.test(route.path)) {
            return (
              <Route
                key={index}
                path={route.path + "/:type/:profile"}
                exact={route.exact}
                render={(routeProps: any) => {
                  if (route.protected) {
                    return (
                      <AuthRoute>
                        <route.component user={user} {...routeProps} />
                      </AuthRoute>
                    );
                  } else {
                    return <route.component user={user} {...routeProps} />;
                  }
                }}
              />
            );
          } else {
            return "";
          }
        })}
        {Routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(routeProps: any) => {
              if (route.protected) {
                return (
                  <AuthRoute>
                    <route.component user={user} {...routeProps} />
                  </AuthRoute>
                );
              } else {
                if (route.redirect) {
                  return <Redirect to={route.redirect} />;
                } else {
                  return <route.component user={user} {...routeProps} />;
                }
              }
            }}
          />
        ))}
      </Switch>
    </>
  );
};

export default Application;
