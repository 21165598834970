import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AuthContainer from "../../components/AuthContainer/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";
import { setProfile } from "./../../store/user";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import type { RootState } from "../../store/index";

const LogoutPage: React.FunctionComponent<IPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.userState);

  const Logout = () => {
    auth
      .signOut()
      .then(() => {
        const newprofile: any = {};
        dispatch(setProfile(newprofile));

        // if (props?.callback) {
        //   props?.callback("logout");
        // } else {
        history.push("/login");
        // }
      })
      .catch((error: any) => logging.error(error));

    if (/(lhdnm.gov|hasil.gov)/i.test(profile?.email)) {
      let authurl =
        "https://secure.hasil.gov.my/adfs/oauth2/logout?response_type=code" +
        "&client_id=" +
        "bancihasil" +
        "&resource=" +
        "urn:lhdnm:bancihasil" +
        "&post_logout_redirect_uri=" +
        window.location.origin +
        "/login/adfs/callback";
      window.open(authurl, "geohasillogin", "height=600,width=500");
    }
  };

  const cancelLogout = () => {
    if (props?.callback) {
      props?.callback("cancel");
    } else {
      history.goBack();
    }
  };

  if (typeof profile?.email === "undefined") {
    // console.log(history);
    history.goBack();
  }

  return (
    <AuthContainer header="">
      <div
        style={{
          backgroundImage: "url(/images/bgweb.jpg)",
          width: "100vw",
          height: "100vh",
          border: "0px solid #ff0000",
          // marginTop: "-64px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          verticalAlign: "middle",
          paddingTop: "100px",
          textAlign: "center",
        }}
      >
        <Paper
          style={{
            width: "100%",
            maxWidth: "400px",
            marginTop: "20vh",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src="/images/PlaceholderLogo.png"
                alt="CK"
                height="100"
                className="cklogo"
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="body2" component="p">
                Are you sure you want to logout?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                onClick={() => cancelLogout()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => Logout()}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <div className="text-center"></div>
      </div>
    </AuthContainer>
  );
};

export default LogoutPage;
