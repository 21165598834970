// import firebase from "firebase/app";
import "firebase/firestore";

import * as firebase from "firebase/app";
// import "firebase/auth";
// // import "firebase/auth";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // New import
import config from "../config/Config";
// console.log(firebase);

const Firebase = firebase.initializeApp(config.firebase);
export const firebaseAppAuth = getAuth(Firebase);
export const Providers = {
  google: new GoogleAuthProvider(),
};

// export const auth = firebaseAppAuth.auth();
export const auth = firebaseAppAuth;
export default Firebase;
