import React, { Fragment } from "react";

// import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CsvDownloader from "react-csv-downloader";

import CircularProgress from "@material-ui/core/CircularProgress";

import { onAuthStateChanged } from "firebase/auth"; // New import
import { auth } from "../../config/Firebase";
import "./Dataset.css";
// import { CollectionsOutlined } from "@material-ui/icons";

// function preventDefault(event: React.MouseEvent) {
//   event.preventDefault();
// }

const TableFoboData = () => {
  // const [keyword, setKeyword] = React.useState<string>();
  const [filters, setFilters] = React.useState<any>({});
  const [token, setToken] = React.useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [data, setData] = React.useState<any>();
  // const [departments, setDepartments] = React.useState<any[]>();
  const [columns] = React.useState<any[]>([
    {
      id: "name",
      label: "Nama",
    },
    {
      id: "email",
      label: "email",
    },
    {
      id: "fobo_name",
      label: "FOBO",
    },
    {
      id: "business_descs_count",
      label: "Kunjungan",
    },
    {
      id: "position",
      label: "Jawatan",
    },
    {
      id: "created",
      label: "Tarikh Daftar",
    },
    {
      id: "status",
      label: "Status",
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const userPosts: any = {
    "0": "Pembanci",
    "1": "Ketua Unit",
    "2": "",
  };

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);

      let filyterbydate: string[] = [];

      fetch("/api/userlist/fobo?" + filyterbydate.join("&"), {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.body);
          return response.json();
        })
        .then((data) => {
          if (isMounted) {
            // console.log(data);
            setData(data);
            setLoading(false);
          }
        })
        .catch((e: any) => {
          setLoading(false);
          window.alert("Fail to fetch data");
          console.log(e);
        });
    };

    if (typeof data === "undefined" && typeof token !== "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setKeyword(event.target.value);
    setFilters({
      ...filters,
      keyword: event.target.value,
    });
    setPage(0);
    // dispatch(setSearch(event.currentTarget.value));
  };

  const formatValue = (item: any, col: any) => {
    if (col === "status") {
      const status: any = {
        "0": <CheckCircleIcon style={{ color: "#00ff00" }} />,
        "1": <ErrorIcon style={{ color: "#ff0000" }} />,
      };
      return <>{status[item[col]]}</>;
    } else if (col === "position") {
      return (
        <>
          {typeof item[col] !== "undefined"
            ? userPosts[item[col].toString()]
            : ""}
        </>
      );
    } else {
      return <>{item[col]}</>;
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    console.log(event.target.name);
    if (event.target.name === "fobo_id") {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value,
        department_id: "",
      });
      // const idx = data?.metadata?.fobos.findIndex((state: any, ix: any) => {
      //   return state.id.toString() === event.target.value.toString();
      // });
      // if (idx >= 0) {
      //   setDepartments(data?.metadata?.fobos[idx].departments);
      // }
    } else {
      setFilters({
        ...filters,
        department_id: event.target.value,
      });
    }

    setPage(0);
  };

  const asyncFnComputeDate = (download: any[]) => {
    let newdata: any[] = [];
    for (let f = 0; f < download.length; f++) {
      let newfeature: any = JSON.parse(JSON.stringify(download[f]));
      for (let p in newfeature) {
        if (typeof newfeature[p] === "object") {
          newfeature[p] = JSON.stringify(newfeature[p]);
        } else {
          newfeature[p] = newfeature[p].toString().replace(/\n/g, "\\n");
        }
      }
      newdata.push(newfeature);
    }
    return Promise.resolve(newdata);
    // do whatever you need async
    // return Promise.resolve([
    //   {
    //     cell1: "row 1 - cell 1",
    //     cell2: "row 1 - cell 2",
    //   },
    //   {
    //     cell1: "row 2 - cell 1",
    //     cell2: "row 2 - cell 2",
    //   },
    // ]);
  };

  let rows: any[] = [];
  let results: any[] = [];

  if (data?.users) {
    let countttlfilter: number = Object.keys(filters).length;
    // for (let f in filters) {
    //   countttlfilter++;
    // }

    // if (filters?.keyword && filters?.keyword !== "") {
    if (countttlfilter > 0) {
      // console.log(filters);

      results = data?.users.filter((item: any) => {
        const row = item;

        let matched: number = 0;
        if (filters?.keyword && filters?.keyword !== "") {
          const word = filters?.keyword.toLowerCase();
          for (let r in row) {
            // console.log(r, row[r]);

            if (
              typeof row[r] !== "object" &&
              row[r] &&
              row[r].toString().toLowerCase().indexOf(word) >= 0
            ) {
              matched++;
              break;
              // return row;
            }
          }
        }

        let countfilters: number = 0;

        for (let f in filters) {
          if (f !== "keyword") {
            if (
              filters[f] !== "" &&
              typeof row[f] !== "undefined" &&
              row[f] !== null
            ) {
              if (/(date)$/i.test(f)) {
                // if(){
                //   matched++;
                // }
              } else if (row[f].toString() === filters[f].toString()) {
                matched++;
              }
            }
          }
          if (filters[f] !== "") {
            countfilters++;
          }
        }

        if (matched.toString() === countfilters.toString()) {
          return row;
        } else {
          return null;
        }
      });
      // console.log(results);
    } else {
      results = data?.users;
    }
    // rows = data?.users;
    rows = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // console.log(results, page, rowsPerPage, page, rowsPerPage, rowsPerPage);

    // console.log(rows);
  }

  // console.log(filters);
  return (
    <React.Fragment>
      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" className="cardtitle">
              Senarai Pengguna
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <CsvDownloader
              datas={asyncFnComputeDate(results)}
              wrapColumnChar='"'
              filename="leaderboard.csv"
            >
              <IconButton aria-label="filter">
                <CloudDownloadIcon />
              </IconButton>
            </CsvDownloader>
          </Grid>
        </Grid>

        <Table size="small" className="tabledata">
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length + 2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      // sx={{ m: 1 }}
                      size="small"
                      fullWidth
                      // className="datasetselect"
                    >
                      <OutlinedInput
                        size="small"
                        fullWidth
                        id="outlined-adornment-password"
                        type="text"
                        value={filters?.keyword ? filters?.keyword : ""}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      // sx={{ m: 1 }}
                      size="small"
                      fullWidth
                      // className="datasetselect"
                    >
                      <InputLabel id="state">FOBO</InputLabel>
                      <Select
                        labelId="state"
                        id="fobo_id"
                        name="fobo_id"
                        value={
                          typeof filters?.fobo_id !== "undefined"
                            ? filters?.fobo_id
                            : ""
                        }
                        label="Negeri"
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {data?.metadata?.fobos &&
                          data?.metadata?.fobos.map((state: any, idx: any) => (
                            <MenuItem key={idx} value={state?.id}>
                              {state?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="department">Cawangan</InputLabel>
                      <Select
                        labelId="department"
                        id="department_id"
                        name="department_id"
                        value={
                          typeof filters?.department_id !== "undefined"
                            ? filters?.department_id
                            : ""
                        }
                        label="Cawangan"
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {departments &&
                          departments.map((department: any, idx: any) => (
                            <MenuItem key={idx} value={department?.id}>
                              {department?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>
              </TableCell>
            </TableRow>
            {results && results.length > rowsPerPage && (
              <TableRow>
                <TablePagination
                  colSpan={columns ? columns.length + 2 : 3}
                  count={results.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                  className="pagination"
                  rowsPerPageOptions={[20, 40, 80, 100]}
                />
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ width: "1%" }} colSpan={2}>
                #
              </TableCell>
              {columns.map((col: any, idx: any) => (
                <TableCell
                  key={idx}
                  style={{ width: col.width ? col.width : "unset" }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              <>
                {rows.map((row: any, idx: any) => (
                  <TableRow key={idx}>
                    <TableCell style={{ textAlign: "right" }}>
                      {rowsPerPage * page + idx + 1}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Avatar
                        alt={row?.nickname}
                        src={row?.picture}
                        sx={{ width: 30, height: 30 }}
                      >
                        {row?.nickname}
                      </Avatar>
                    </TableCell>
                    {columns.map((col: any, id: any) => (
                      <Fragment key={id}>
                        {/count$/i.test(col.id) ? (
                          <TableCell
                            align="right"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {/* {format(
                              new Date(row[col.id]),
                              "dd MMM yy HH:mm aa"
                            )} */}
                            {row[col.id]}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {/^name/i.test(col.id) ? (
                              <>
                                {row[col.id]}
                                {/* <Link to={"./user/" + row?.id}>
                                  {row[col.id]}
                                </Link> */}
                              </>
                            ) : (
                              // row[col.id]
                              <>{formatValue(row, col.id)}</>
                            )}
                          </TableCell>
                        )}
                      </Fragment>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns ? columns?.length + 1 : 2}
                  style={{ textAlign: "center" }}
                >
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            {results && results.length > rowsPerPage && (
              <TableRow>
                <TablePagination
                  colSpan={columns ? columns.length + 2 : 2}
                  count={results.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                  className="pagination"
                  rowsPerPageOptions={[20, 40, 80, 100]}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default TableFoboData;
