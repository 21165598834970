let loadmap: any;
const layerHandler = (
  mapbox: any,
  listLayer: Array<any>,
  availablelayers?: string[]
) => {
  let layerapihost: string =
    window.location.protocol + "//" + window.location.host + "/";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    layerapihost = "https://mcmc.tuxgeo.dev/";
  }
  // let loadlayers: string = "";
  // if (availablelayers && availablelayers.length > 0) {
  //   loadlayers = "layers=" + availablelayers.join(",") + "&type=moduleid";
  // } else {
  //   loadlayers = "profile=1";
  // }

  // mapbox.on("load", () => {
  if (typeof loadmap !== "undefined") {
    clearTimeout(loadmap);
  }
  loadmap = setTimeout(() => {
    // if (!mapbox.getSource("mcmc-layers")) {
    // console.log(availablelayers);

    if (typeof availablelayers !== "undefined") {
      for (let l = 0; l < availablelayers.length; l++) {
        mapbox.addSource(availablelayers[l], {
          type: "vector",
          tiles: [
            layerapihost + "tiles/{z}/{x}/{y}.mvt?layers=" + availablelayers[l], //&nocache=1
          ],
          minzoom: 1,
          maxzoom: 20,
        });
      }
    }

    const layers = mapbox.getStyle().layers;
    // Find the index of the first symbol layer in the map style
    let firstSymbolId: string;
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].type === "symbol") {
        firstSymbolId = layers[i].id;
        break;
      }
    }

    listLayer.forEach(
      (sourceLayer: { head: string; child: Array<string>; type?: any }) => {
        getChilds(sourceLayer, firstSymbolId);
      }
    );
  }, 2000);

  const padEndingZeros = (num: any, size: any) => {
    var s = num + "";
    while (s.length < size) s = s + "0";
    return s;
  };

  const createLayer = (layer?: any, beforelayer?: string) => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    const color = "#" + padEndingZeros(randomColor, 6);

    let styles: any = {};

    // console.log(layer.style);
    if (layer.style && layer.style.length > 0) {
      const curstyles = layer.style.find((style: any) => {
        // console.log(style.id, layer?.type);
        return style.id.toString() === layer?.type.toString();
      });
      if (curstyles) {
        styles = curstyles.styles;
        if (typeof styles["circle-radius"] !== "undefined") {
          if (
            typeof styles["circle-radius"].stops !== "undefined" &&
            styles["circle-radius"].stops.length <= 0
          ) {
            styles["circle-radius"].stops = [
              [6, 100],
              [9, 200],
              [11, 300],
              [13, 400],
              [16, 500],
            ];
          }
        }
      }
    }

    if (typeof layer?.type !== "undefined" && layer?.type !== "") {
      if (layer?.type.toString() === "0") {
        // console.log(styles["circle-radius"]);
        if (
          typeof styles["circle-radius"] !== "undefined" &&
          typeof styles["circle-radius"].stops !== "undefined" &&
          typeof styles["circle-radius"].stops === "string"
        ) {
          styles["circle-radius"].stops = JSON.parse(
            styles["circle-radius"].stops
          );
          // console.log(styles["circle-radius"].stops);
        }
        mapbox.addLayer(
          {
            id: `state${layer?.head}`,
            type: "circle",
            source: layer?.head,
            "source-layer": layer?.head,
            paint: {
              "circle-radius":
                typeof styles["circle-radius"] !== "undefined" &&
                styles["circle-radius"]
                  ? styles["circle-radius"]
                  : 3,
              // "circle-radius": 3,
              "circle-color":
                typeof styles["circle-color"] !== "undefined" &&
                styles["circle-color"]
                  ? styles["circle-color"]
                  : color,
            },
          },
          beforelayer
        );
      } else if (
        layer?.type.toString() === "1" ||
        layer?.type.toString() === "3"
      ) {
        // console.log("kkskskks", styles["fill-outline-color"]);

        let outlinecolor: string = "";
        if (
          typeof styles["fill-outline-color"] === "undefined" ||
          (typeof styles["fill-outline-color"] !== "undefined" &&
            styles["fill-outline-color"] === "")
        ) {
          outlinecolor = "transparent";
        } else {
          if (layer?.head === "coverage") {
            outlinecolor = "transparent";
          } else {
            if (typeof styles["fill-outline-color"] !== "undefined") {
              outlinecolor = styles["fill-outline-color"];
            } else {
              outlinecolor = color;
            }
          }
        }

        mapbox.addLayer(
          {
            id: `state${layer?.head}`,
            type: "fill",
            source: layer?.head,
            "source-layer": layer?.head,
            paint: {
              "fill-outline-color": outlinecolor,
              "fill-color": styles["fill-color"] ? styles["fill-color"] : color,
              "fill-opacity": 0.3,
            },
          },
          beforelayer
        );
      } else if (layer?.type.toString() === "2") {
        // skip++;
        mapbox.addLayer(
          {
            id: `state${layer?.head}`,
            type: "circle",
            source: layer?.head,
            "source-layer": layer?.head,
            paint: {
              // "circle-radius": styles["circle-radius"]
              //   ? styles["circle-radius"]
              //   : 0,

              "circle-radius": 0,
              "circle-color": styles["circle-color"]
                ? styles["circle-color"]
                : color,
            },
          },
          beforelayer
        );
      } else if (layer?.type.toString() === "4") {
        mapbox.addLayer(
          {
            id: `state${layer?.head}`,
            type: "line",
            source: layer?.head,
            "source-layer": layer?.head,
            layout: {
              "line-join": styles["line-join"] ? styles["line-join"] : "round",
              "line-cap": styles["line-cap"] ? styles["line-cap"] : "round",
            },
            paint: {
              "line-width": styles["line-width"] ? styles["line-width"] : 5,
              "line-color": styles["line-color"] ? styles["line-color"] : color,
              "line-opacity": styles["line-opacity"]
                ? styles["line-opacity"]
                : 0.5,
            },
          },
          beforelayer
        );
      } else {
        console.log("herererre");
      }

      mapbox.setLayoutProperty(`state${layer?.head}`, "visibility", "none");
    }
  };

  const getChilds = (childs: any, firstSymbolId: string) => {
    createLayer(childs, firstSymbolId);
    if (childs.child && childs.child.length > 0) {
      for (let c = 0; c < childs.child.length; c++) {
        // createLayer(childs.child[c], firstSymbolId);
        // if (childs.child[c].child && childs.child[c].child.length > 0) {
        getChilds(childs.child[c], firstSymbolId);
        // }
      }
    }
  };

  // const layers = mapbox.getStyle().layers;
  // // Find the index of the first symbol layer in the map style
  // let firstSymbolId: string;
  // for (var i = 0; i < layers.length; i++) {
  //   if (layers[i].type === "symbol") {
  //     firstSymbolId = layers[i].id;
  //     break;
  //   }
  // }

  // listLayer.forEach(
  //   (sourceLayer: { head: string; child: Array<string>; type?: any }) => {
  //     // const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  //     // mapbox.addLayer({
  //     //   id: `state${sourceLayer?.head}`,
  //     //   type: "circle",
  //     //   source: "mcmc-layers",
  //     //   "source-layer": sourceLayer?.head,
  //     //   paint: {
  //     //     "circle-radius": 3,
  //     //     "circle-color": "#" + padEndingZeros(randomColor, 6),
  //     //   },
  //     // });
  //     // mapbox.setLayoutProperty(`state${sourceLayer?.head}`, "visibility", "none");
  //     // createLayer(sourceLayer, firstSymbolId);

  //     // if (sourceLayer?.child?.length > 0) {
  //     //   sourceLayer.child.forEach((childLayer: any) => {
  //     //     console.log(sourceLayer);
  //     //     createLayer(childLayer, firstSymbolId);
  //     //   });
  //     // } else {
  //     //   createLayer(sourceLayer, firstSymbolId);
  //     // }
  //     getChilds(sourceLayer, firstSymbolId);
  //   }
  // );
};

export default layerHandler;
