import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import { onAuthStateChanged } from "firebase/auth"; // New import
import React, { Fragment } from "react";
import { auth } from "../../config/Firebase";
// import Button from "@mui/material/Button";

import Map from "../MapLight";
import TableData from "./Table";
// import { relative } from "path";
// import { RGBAImage } from "maplibre-gl";

const HeatMap = () => {
  const [features, setFeatures] = React.useState<any[]>();
  const [token, setToken] = React.useState<any>("");
  const [data, setData] = React.useState<any>();
  const [feature, setFeature] = React.useState<any>();
  const [maphandler, setMapHandler] = React.useState<any>();
  const [heatmap, setHeatMap] = React.useState<any>();
  const [filters, setFilters] = React.useState<any>({});

  const defaultradius = 20;

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof features === "undefined" && token !== "") {
      let filter: string = "";
      if (typeof filters.district !== "undefined" && filters.district !== "") {
        filter += "district_id=" + filters?.district;
      }
      fetch("/api/map/crime/premise?" + filter, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (body) {
          return body.json();
        })
        .then(function (data) {
          setData(data);
          setFeatures(data?.features);
        })
        .catch(function (err) {
          console.log(err);
          alert("Unable to fetch census data");
        });
    }
  }, [features, filters, token]);

  React.useEffect(() => {
    if (
      typeof heatmap === "undefined" &&
      typeof features !== "undefined" &&
      typeof maphandler.map !== "undefined"
    ) {
      const newheatmap = new google.maps.visualization.HeatmapLayer({
        data: [], // heatMapData,
      });
      newheatmap.set("radius", defaultradius);
      newheatmap.setMap(maphandler.map);
      setHeatMap(newheatmap);
    }
  }, [features, maphandler, heatmap]);

  React.useEffect(() => {
    if (typeof heatmap !== "undefined" && typeof features !== "undefined") {
      let heatMapData: any[] = [];
      for (let f = 0; f < features.length; f++) {
        if (typeof features[f].geometry?.coordinates !== "undefined") {
          heatMapData.push({
            location: new google.maps.LatLng(
              features[f].geometry.coordinates[1],
              features[f].geometry.coordinates[0]
            ),
            weight: features[f].properties?.total,
          });
        }
      }
      heatmap.setData(heatMapData);
    }
  }, [features, heatmap]);

  const mapCallback = (callback: any) => {
    if (typeof maphandler === "undefined" && callback && callback.map) {
      setMapHandler(callback);

      var bounds = new callback.google.maps.LatLngBounds();
      bounds.extend({
        lat: 0.85,
        lng: 109.54,
      });
      bounds.extend({
        lat: 5.11,
        lng: 115.68,
      });
      callback.map.fitBounds(bounds);
    }
  };

  const setFocus = (feat: any) => {
    setFeature(feat);
  };

  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    heatmap.set("radius", newValue);
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    if (event.target.name === "district") {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value,
      });
      const idx = data?.metadata?.states.findIndex((state: any, ix: any) => {
        return state.id.toString() === event.target.value.toString();
      });
      if (idx >= 0) {
        setFeature({
          type: "Feature",
          geometry: data?.metadata?.states[idx]._bound_geom,
          properties: {
            _viewbox: data?.metadata?.states[idx]._bound_geom,
          },
        });
      }
    } else {
      setFilters({
        ...filters,
      });
    }
    setFeatures(undefined);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              // display: "flex",
              // flexDirection: "column",
              position: "relative",
              height: "calc(100vh - 150px)",
            }}
          >
            <Map mapcallback={mapCallback} focusfeature={feature} />
            <div
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                height: "100%",
                paddingTop: "40px",
                paddingBottom: "50px",
                paddingRight: "25px",
              }}
            >
              <Slider
                size="small"
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                  color: "#ffffff",
                }}
                orientation="vertical"
                defaultValue={defaultradius}
                aria-label="Radius"
                onKeyDown={preventHorizontalKeyboardNavigation}
                onChange={handleSliderChange}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={3} marginBottom="10px">
                <FormControl sx={{ m: 1 }} size="small" fullWidth>
                  <InputLabel id="district">Daerah</InputLabel>
                  <Select
                    labelId="district"
                    id="district"
                    name="district"
                    value={
                      typeof filters?.district !== "undefined"
                        ? filters?.district
                        : ""
                    }
                    label="Daerah"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {data?.metadata?.states &&
                      data?.metadata?.states.map((state: any, idx: any) => (
                        <MenuItem key={idx} value={state?.id}>
                          {state?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div>
              <TableData
                data={features}
                option={{
                  columns: [
                    {
                      id: "cases_category_name",
                      label: "Kategori Jenayah",
                    },
                    {
                      id: "premise_type",
                      label: "Jenis Premis",
                    },
                    {
                      id: "premise_name",
                      label: "Nama Premis",
                    },
                    {
                      id: "premise_district",
                      label: "Daerah",
                    },
                    {
                      id: "premise_started_date",
                      label: "Tarikh Premis Mula Operasi ",
                    },
                  ],
                  numbering: true,
                }}
                callback={setFocus}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HeatMap;
