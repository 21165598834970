import React, { Fragment } from "react";

import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import FilterListIcon from "@mui/icons-material/FilterList";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DoughnutChart from "../Charts/Doughnut";
import BarChart from "../Charts/Bar";
import Typography from "@mui/material/Typography";

import { format, isValid } from "date-fns";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import CsvDownloader from "react-csv-downloader";
// import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CircularProgress from "@material-ui/core/CircularProgress";

import { onAuthStateChanged } from "firebase/auth"; // New import
import { auth } from "../../config/Firebase";
import InfoBox from "./InfoBox";
import "./Dataset.css";

// function preventDefault(event: React.MouseEvent) {
//   event.preventDefault();
// }

const colors = [
  "#5085ec",
  "#cb5040",
  "#ebb63e",
  "#df6b92",
  "#ef7850",
  "#4ba9be",
  "#9f9c3d",
  "#479b5f",
  "#97c05c",
  "#775bbc",
  "#9f4fb6",
  "#47a7ee",
  "#e147ee",
  "#ee8e47",
  "#53ee47",
  "#b747ee",
];

const UsageStats = () => {
  // const [keyword, setKeyword] = React.useState<string>();
  const [filters, setFilters] = React.useState<any>({
    // startdate: new Date(),
    // enddate: new Date(),
  });
  const [dates, setDates] = React.useState<any>({
    // startdate: new Date(),
    // enddate: new Date(),
    init: false,
  });
  const [token, setToken] = React.useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(17);
  const [data, setData] = React.useState<any>();
  // const [departments, setDepartments] = React.useState<any[]>();
  const [columns] = React.useState<any[]>([
    // {
    //   id: "name",
    //   label: "Cawangan",
    // },
    {
      id: "state_name",
      label: "Negeri",
    },
    {
      id: "user_count",
      label: "Bil. Pengguna",
    },
    {
      id: "business_descs_count",
      label: "Bil. Premis Di Lawati",
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [piechart, setPiechart] = React.useState<any>();
  const [userpiechart, setUserPiechart] = React.useState<any>();
  const [premisepiechart, setPremisePiechart] = React.useState<any>();

  const infoboxs = [
    {
      value: data?.metadata?.stats?.user ? data?.metadata?.stats?.user : 0,
      label: "Bilangan Pengguna",
      icon: (
        <GroupIcon sx={{ fontSize: 35, color: "#111111" }} fontSize="large" />
      ),
    },
    {
      value: data?.metadata?.stats?.premis ? data?.metadata?.stats?.premis : 0,
      label: "Bilangan Premis",
      icon: (
        <StoreIcon sx={{ fontSize: 35, color: "#1AAF22" }} fontSize="large" />
      ),
    },
    // {
    //   value: data?.metadata?.stats?.department
    //     ? data?.metadata?.stats?.department
    //     : 0,
    //   label: "Bilangan Cawangan",
    //   icon: (
    //     <MapsHomeWorkIcon
    //       sx={{ fontSize: 35, color: "#ff0000" }}
    //       fontSize="large"
    //     />
    //   ),
    // },
  ];

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);

      let filyterbydate: string[] = [];
      if (typeof dates?.startdate !== "undefined") {
        filyterbydate.push(
          "start=" + format(dates?.startdate, "yyyy-MM-dd 00:00:00")
        );
      }
      if (typeof dates?.enddate !== "undefined") {
        filyterbydate.push(
          "end=" + format(dates?.enddate, "yyyy-MM-dd 23:59:59")
        );
      }
      fetch("/api/usagestatestats?" + filyterbydate.join("&"), {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.body);
          return response.json();
        })
        .then((data) => {
          if (isMounted) {
            // console.log(data);
            setData(data);
            // console.log(data?.metadata?.created);
            if (
              typeof data?.metadata?.created?.mindate !== "undefined" &&
              typeof data?.metadata?.created?.maxdate !== "undefined" &&
              typeof dates.startdate === "undefined"
            ) {
              const mindt = data?.metadata?.created?.mindate.split(" ");
              const maxdt = data?.metadata?.created?.maxdate.split(" ");
              setDates({
                startdate: new Date(mindt[0]).setHours(0, 0, 0),
                enddate: new Date(maxdt[0]).setHours(23, 59, 59),
                init: true,
              });
            }
            if (
              typeof data?.metadata?.stats?.premistypes !== "undefined" &&
              data?.metadata?.stats?.premistypes.length > 0
            ) {
              let labels: string[] = [];
              let datas: number[] = [];
              for (
                let p = 0;
                p < data?.metadata?.stats?.premistypes.length;
                p++
              ) {
                labels.push(
                  data?.metadata?.stats?.premistypes[p].type.replace(
                    /(^\w|\s\w)/g,
                    (m: string) => m.toUpperCase()
                  )
                );
                datas.push(data?.metadata?.stats?.premistypes[p].total);
              }
              setPiechart({
                option: {
                  hoverOffset: 20,
                  xplugins: {
                    legend: {
                      position: "right",
                    },
                  },
                },
                data: {
                  labels: labels,
                  datasets: [
                    {
                      label: "Jenis Premis",
                      data: datas,
                      backgroundColor: colors,
                      // borderColor: colors_2,
                      // borderWidth: 1,
                      offset: 0,
                    },
                  ],
                },
              });
            }
            setLoading(false);
          }
        })
        .catch((e: any) => {
          setLoading(false);
          window.alert("Fail to fetch data");
          console.log(e);
        });
    };

    if (token && !dates?.init) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, dates]);

  React.useEffect(() => {
    if (
      typeof data !== "undefined" &&
      typeof data?.departments !== "undefined" &&
      data?.departments.length > 0
    ) {
      // console.log(data);
      const options: any = {
        hoverOffset: 20,
        xplugins: {
          legend: {
            position: "bottom",
          },
        },
      };
      let labels: string[] = [];
      let datapremise: any[] = [];
      let datauser: any[] = [];
      for (let a = 0; a < data?.departments.length; a++) {
        labels.push(data?.departments[a]?.state_name);
        datapremise.push(data?.departments[a]?.business_descs_count);
        datauser.push(data?.departments[a]?.user_count);
      }
      setUserPiechart({
        option: options,
        data: {
          labels: labels,
          datasets: [
            {
              label: "Jumlah Penguna",
              data: datauser,
              backgroundColor: colors,
              offset: 0,
            },
          ],
        },
      });
      setPremisePiechart({
        option: options,
        data: {
          labels: labels,
          datasets: [
            {
              label: "Jumlah Premis",
              data: datapremise,
              backgroundColor: colors,
              offset: 0,
            },
          ],
        },
      });
    }
  }, [data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setKeyword(event.target.value);
    setFilters({
      ...filters,
      keyword: event.target.value,
    });
    // dispatch(setSearch(event.currentTarget.value));
  };

  const formatValue = (item: any, col: any) => {
    if (col === "status") {
      const status: any = {
        "1": <CheckCircleIcon style={{ color: "#00ff00" }} />,
        "2": <ErrorIcon style={{ color: "#ff0000" }} />,
      };
      return <>{status[item[col]]}</>;
    } else {
      return <>{item[col]}</>;
    }
  };

  const asyncFnComputeDate = (download: any[]) => {
    let newdata: any[] = [];
    for (let f = 0; f < download.length; f++) {
      let newfeature: any = JSON.parse(JSON.stringify(download[f]));
      for (let p in newfeature) {
        if (typeof newfeature[p] === "object") {
          newfeature[p] = JSON.stringify(newfeature[p]);
        } else {
          newfeature[p] = newfeature[p].toString().replace(/\n/g, "\\n");
        }
      }
      newdata.push(newfeature);
    }
    return Promise.resolve(newdata);
  };

  let rows: any[] = [];
  let results: any[] = [];

  if (data?.departments) {
    let countttlfilter: number = Object.keys(filters).length;
    // for (let f in filters) {
    //   countttlfilter++;
    // }

    // if (filters?.keyword && filters?.keyword !== "") {
    if (countttlfilter > 0) {
      // console.log(filters);

      results = data?.departments.filter((item: any) => {
        const row = item;

        let matched: number = 0;
        if (filters?.keyword && filters?.keyword !== "") {
          const word = filters?.keyword.toLowerCase();
          for (let r in row) {
            // console.log(r, row[r]);

            if (
              typeof row[r] !== "object" &&
              row[r] &&
              row[r].toString().toLowerCase().indexOf(word) >= 0
            ) {
              matched++;
              break;
              // return row;
            }
          }
        }

        let countfilters: number = 0;

        for (let f in filters) {
          if (f !== "keyword") {
            if (
              filters[f] !== "" &&
              typeof row[f] !== "undefined" &&
              row[f] !== null
            ) {
              if (/(date)$/i.test(f)) {
                // if(){
                //   matched++;
                // }
              } else if (row[f].toString() === filters[f].toString()) {
                matched++;
              }
            }
          }
          if (filters[f] !== "") {
            countfilters++;
          }
        }

        if (matched.toString() === countfilters.toString()) {
          return row;
        } else {
          return null;
        }
      });
      // console.log(results);
    } else {
      results = data?.departments;
    }
    // rows = data?.departments;
    rows = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }
  return (
    <React.Fragment>
      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      <Grid
        container
        spacing={2}
        style={{ marginBottom: "30px" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Grid container spacing={2}>
            {infoboxs &&
              infoboxs.map((info: any, idx: any) => (
                <Grid item key={idx} xs={12} md={6} lg={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <InfoBox
                      label={info.label}
                      value={info.value}
                      icon={info.icon}
                      size="small"
                    />
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          {piechart && (
            <DoughnutChart
              data={piechart.data}
              option={piechart.option}
              // callback={pieChartCallback}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={7} lg={8}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className="cardtitle">
                  Statistik Mengikut Negeri
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <CsvDownloader
                  datas={asyncFnComputeDate(results)}
                  wrapColumnChar='"'
                  filename="bystate-stats.csv"
                >
                  <IconButton aria-label="filter">
                    <CloudDownloadIcon />
                  </IconButton>
                </CsvDownloader>
              </Grid>
            </Grid>

            <Table size="small" className="tabledata">
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>#</TableCell>
                  <TableCell colSpan={1}>
                    <OutlinedInput
                      size="small"
                      fullWidth
                      id="outlined-adornment-password"
                      type="text"
                      value={filters?.keyword ? filters?.keyword : ""}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </TableCell>

                  <TableCell colSpan={1}>
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Dari"
                          value={dates?.startdate ? dates?.startdate : ""}
                          onChange={(newValue) => {
                            if (
                              isValid(newValue) &&
                              newValue.getFullYear() >= 2020
                            ) {
                              setDates({
                                ...dates,
                                startdate: newValue.setHours(0, 0, 0),
                                init: false,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                          inputFormat="dd/MM/yyyy"
                          maxDate={dates?.enddate ? dates?.enddate : ""}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Sehingga"
                          value={dates?.enddate ? dates?.enddate : ""}
                          onChange={(newValue) => {
                            if (
                              isValid(newValue) &&
                              newValue.getFullYear() >= 2020
                            ) {
                              setDates({
                                ...dates,
                                enddate: newValue.setHours(23, 59, 59),
                                init: false,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                          inputFormat="dd/MM/yyyy"
                          minDate={dates?.startdate ? dates?.startdate : ""}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell style={{ width: "1%" }}>#</TableCell> */}
                  {columns.map((col: any, idx: any) => (
                    <TableCell
                      key={idx}
                      style={{ width: col.width ? col.width : "unset" }}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length > 0 ? (
                  <>
                    {rows.map((row: any, idx: any) => (
                      <TableRow key={idx}>
                        <TableCell style={{ textAlign: "right" }}>
                          {rowsPerPage * page + idx + 1}
                        </TableCell>
                        {columns.map((col: any, id: any) => (
                          <Fragment key={id}>
                            {/count$/i.test(col.id) ? (
                              <TableCell
                                align="right"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {/* {format(
                              new Date(row[col.id]),
                              "dd MMM yy HH:mm aa"
                            )} */}
                                {row[col.id]}
                              </TableCell>
                            ) : (
                              <TableCell>
                                {/^name/i.test(col.id) ? (
                                  <>
                                    <Link
                                      to={
                                        "./leaderboard/" +
                                        row?.state_id +
                                        "/" +
                                        row?.id
                                      }
                                    >
                                      {row[col.id]}
                                    </Link>
                                  </>
                                ) : (
                                  // row[col.id]
                                  <>{formatValue(row, col.id)}</>
                                )}
                              </TableCell>
                            )}
                          </Fragment>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns ? columns?.length + 1 : 2}
                      style={{ textAlign: "center" }}
                    >
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              <TableFooter>
                {results && results.length > rowsPerPage && (
                  <TableRow>
                    <TablePagination
                      colSpan={columns ? columns.length + 1 : 2}
                      count={results.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                      className="pagination"
                      rowsPerPageOptions={[15, 20, 40, 80, 100]}
                    />
                  </TableRow>
                )}
              </TableFooter>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="body1" className="cardtitle">
                Premis
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ height: "24vh" }}>
              {premisepiechart && (
                <DoughnutChart
                  data={premisepiechart.data}
                  option={premisepiechart.option}
                  // callback={pieChartCallback}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="cardtitle">
                Pengguna
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ height: "24vh" }}>
              {userpiechart && (
                <DoughnutChart
                  data={userpiechart.data}
                  option={userpiechart.option}
                  // callback={pieChartCallback}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          marginTop: "20px",
          // display: "flex",
          // flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {data?.metadata?.stats?.premistypebystates &&
            data?.metadata?.stats?.premistypebystates.map(
              (state: any, idx: any) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={4}
                  key={idx}
                  style={{ height: "20vh" }}
                >
                  <>{state?.state_name}</>
                  {state?.totals.length > 0 && (
                    <BarChart
                      data={{
                        labels: state?.labels,
                        datasets: [
                          {
                            label: "Premis",
                            data: state?.totals,
                            backgroundColor: colors,
                            offset: 0,
                          },
                          {
                            label: "Pengguna/Pegawai",
                            data: state?.total_users,
                            backgroundColor: colors,
                            offset: 0,
                          },
                        ],
                      }}
                      // option={{
                      //   hoverOffset: 20,
                      //   xplugins: {
                      //     legend: {
                      //       position: "right",
                      //     },
                      //   },
                      // }}
                      // callback={pieChartCallback}
                    />
                  )}
                </Grid>
              )
            )}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default UsageStats;
