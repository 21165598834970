import React from "react";
import UploadForm from "../components/Forms/Upload";

interface PropsTotal {
  user?: any;
}
const Home: React.FC<PropsTotal> = ({ user }) => {
  return (
    <>
      <UploadForm
        id="hairul"
        type="users"
        token="xx"
        name="profile"
        callback={(e: any) => {
          console.log(e);
        }}
        maxsize={300} // 300MB
        folder={false}
      />
    </>
  );
};

export default Home;
