import React from "react";
import {
  // unstable_createMuiStrictModeTheme,
  withStyles,
} from "@material-ui/core/styles";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { unset } from "lodash";

import "./Accordion.css";

const Accordion: any = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    minHeight: "unset !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    width: "100%",
    // border: "1px solid #00ff00",
    height: "100%", //"cacl(100% - 100px) !important",
    flexGrow: 1,
    overflow: "auto",
  },
})(MuiAccordion);

const AccordionSummary: any = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 30,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails: any = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    flexGrow: 1,
    overflow: "auto",
    height: "100%",
    // height: "calc(100vh - 100px) ",
    // border: "2px solid #00ff00",
  },
}))(MuiAccordionDetails);

// interface AccordionProps {
//   items?: any[];
//   label?: any;
// }

export interface AccordionFunctionProps {
  label?: any;
  toggle?: string;
  id: string;
  callback?: (value: any) => void;
  children?: any;
}

const CustomizedAccordions: React.FunctionComponent<AccordionFunctionProps> = (
  props
) => {
  const { children, label, id, toggle, callback } = props;

  // const [expanded, setExpanded] = React.useState<string>("panel");

  const handleChange = (panel: string) => {
    if (callback) {
      callback(panel === toggle ? "" : panel);
    }
  };

  return (
    <Accordion
      square
      expanded={toggle === id}
      onChange={() => handleChange(id)}
    >
      <AccordionSummary
        aria-controls={"paneld-content"}
        id={"paneld-header"}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

// export default function CustomizedAccordions() {
// const CustomizedAccordionsx = ({ items, label }: AccordionProps) => {
//   const [expanded, setExpanded] = React.useState<string | false>("panel1");

//   const handleChange = (panel: string) => (
//     event: React.ChangeEvent<{}>,
//     newExpanded: boolean
//   ) => {
//     setExpanded(newExpanded ? panel : false);
//   };

//   return (
//     <div>
//       {items &&
//         items.length > 0 &&
//         items.map((item: any, idx: number) => (
//           <Accordion
//             square
//             expanded={expanded === "panel" + idx}
//             onChange={handleChange("panel" + idx)}
//             key={idx}
//           >
//             <AccordionSummary
//               aria-controls={"panel" + idx + "d-content"}
//               id={"panel" + idx + "d-header"}
//               expandIcon={<ExpandMoreIcon />}
//             >
//               <Typography>{item?.label}</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <item.component />
//             </AccordionDetails>
//           </Accordion>
//         ))}
//     </div>
//   );
// };

export default CustomizedAccordions;
