import CircularProgress from "@material-ui/core/CircularProgress";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import DashboardRounded from "@mui/icons-material/DashboardRounded";
import FileCopy from "@mui/icons-material/FileCopy";
import Map from "@mui/icons-material/MapRounded";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { onAuthStateChanged } from "firebase/auth"; // New import
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../config/Firebase";
import "./Dataset.css";

const TableData = () => {
  const [token, setToken] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [judiOnline, setJudiOnline] = useState(0);
  const history = useHistory();

  useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!token) return;

    fetch("/api/report/latest/status", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.metadata.status === "ERORR")
          return console.log("ERROR", data.metada.message);

        setJudiOnline(data.list.judi_online.not_seen);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, [token]);

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a kategori.");
  }

  function handleNavigate(path: string) {
    history.push(path);
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={`/dashboard/`}
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      Dashboard
    </Link>,
  ];

  return (
    <Box minHeight="90vh">
      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      <Grid item container xs={12} direction="row">
        <DashboardRounded fontSize="large" />
        <Typography sx={{ fontSize: "20px", paddingLeft: "20px" }}>
          Kategori
        </Typography>
      </Grid>
      <Divider
        sx={{ marginBottom: "20px", marginTop: "20px" }}
        variant="fullWidth"
      />
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Card sx={{ maxWidth: "300px", marginTop: "20px", borderRadius: "10px" }}>
        <CardMedia
          component="img"
          alt="Dashboard"
          height="140"
          image="/../../images/kategori.jpg"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{ fontWeight: "bolder" }}
          >
            Perjudian Online
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Status:{" "}
            {judiOnline ? (
              <>
                {judiOnline} laporan baru{" "}
                <AnnouncementOutlinedIcon
                  sx={{
                    color: "red",
                    animation: "blink 1s linear infinite",
                    "@keyframes blink": {
                      "0%": { opacity: 1 },
                      "50%": { opacity: 0.3 },
                      "100%": { opacity: 1 },
                    },
                  }}
                />
              </>
            ) : (
              "Tiada Laporan Baru"
            )}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            size="small"
            startIcon={<Map />}
            sx={{ color: "#000000" }}
            // href="/map-checker/judi-online"
            onClick={(_) => handleNavigate("/map-checker/judi-online")}
          >
            Map Checker
          </Button>
          <Button
            size="small"
            startIcon={<FileCopy />}
            sx={{ color: "#000000" }}
            // href="/dashboard/senarai-laporan/terbaru"
            onClick={(_) =>
              handleNavigate("/dashboard/senarai-laporan/terbaru")
            }
          >
            Laporan terbaru
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default TableData;
