import React, { useState, useEffect } from "react";
import startCase from "lodash/startCase";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import RoomIcon from "@material-ui/icons/Room";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import IconButton from "@material-ui/core/IconButton";

import DataModal from "./Modal";
// import { find } from "lodash";

interface layerControllerProps {
  data: any;
  mapbox: any;
  layerCallback?: (value: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginBottom: 5,
    // minWidth: 290,
    minWidth: 250,
    // maxWidth: 350,
    width: "100%",
  },
  childContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginBottom: 0,
    paddingLeft: 55,
  },
  collapseRoot: {
    width: "100%",
  },
  listItemModified: {
    borderRadius: 3,
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  divider: {
    marginTop: 5,
    width: "100%",
  },
  listItemIcon: {
    minWidth: "30px",
  },
  listModified: {
    "& > div": {
      paddingLeft: "30px",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    // margin: "0",
  },
  avatar: {
    width: "20px !important",
    height: "20px !important",
    borderRadius: "unset",
  },
  avatarcontainer: {
    minWidth: "unset",
    paddingRight: "5px",
  },
}));

interface Any {
  [key: string]: number | string | object | any;
}

const LayerController = ({
  data,
  mapbox,
  layerCallback,
}: layerControllerProps) => {
  const classes = useStyles();
  //setChecked
  const [checked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [layerdata, setLayerData] = useState<any>();
  // const [filter, setFilter] = useState<string>("-");
  const [childChecked, setChildChecked] = useState<Any>();

  useEffect(() => {
    if (typeof data !== "undefined" && typeof layerdata === "undefined") {
      setLayerData(data);
    }
  }, [data, layerdata]);

  useEffect(() => {
    const handleChild = () => {
      let newchildchecked: Any = {};
      if (typeof childChecked !== "undefined" && childChecked) {
        newchildchecked = JSON.parse(JSON.stringify(childChecked));

        layerdata.child.forEach((childModel: any) => {
          // setChildChecked({ [childModel?.head]: false });
          newchildchecked[childModel?.head] =
            typeof childChecked[childModel?.head] !== "undefined"
              ? childChecked[childModel?.head]
              : false;
        });
      }

      setChildChecked(newchildchecked);
    };

    if (
      typeof childChecked === "undefined" &&
      typeof layerdata !== "undefined" &&
      layerdata?.child &&
      layerdata?.child?.length > 0
    ) {
      handleChild();
    }
  }, [layerdata, childChecked]);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  //   let status: string = "none";
  //   if (event.target.checked === false) {
  //     status = "none";
  //   } else {
  //     status = "visible";
  //   }

  //   if (layerdata?.child && layerdata?.child?.length > 0) {
  //     let newchildchecked: any = JSON.parse(JSON.stringify(childChecked));
  //     for (let l = 0; l < layerdata?.child?.length; l++) {
  //       console.log(layerdata?.child[l].head, status);
  //       mapbox.setLayoutProperty(
  //         `state${layerdata?.child[l].head}`,
  //         "visibility",
  //         status
  //       );
  //       newchildchecked[layerdata?.child[l].head] =
  //         status === "none" ? false : true;
  //     }

  //     setChildChecked(newchildchecked);
  //   } else {
  //     mapbox.setLayoutProperty(`state${layerdata?.head}`, "visibility", status);
  //   }
  //   // mapbox.setLayoutProperty(`state${layerdata?.head}`, "visibility", status);
  // };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChangeChild = (
    event: React.ChangeEvent<HTMLInputElement>,
    childLabel: string
  ) => {
    let newchildChecked: any = JSON.parse(JSON.stringify(childChecked));

    if (childChecked) {
      newchildChecked[childLabel] = event.target.checked;
      // setChildChecked({
      //   ...childChecked,
      //   [childLabel]: event.target.checked,
      // });
    }
    let status: string = "none";
    if (event.target.checked === false) {
      status = "none";
    } else {
      status = "visible";
    }
    // console.log(childChecked, newchildChecked);

    if (childLabel.length <= 6 && /^\d+$/i.test(childLabel)) {
      // console.log("cats", layerdata);
      const subcats = layerdata.child.find((sc: any) => {
        return sc.head === childLabel;
      });

      for (let l = 0; l < subcats?.child?.length; l++) {
        // console.log("cat", subcats?.child[l].head);

        // if(event.target.checked){
        if (
          childChecked &&
          childChecked[subcats?.child[l].head] !== event.target.checked
        ) {
          // childChecked[subcats?.child[l].head] = event.target.checked;
          newchildChecked[subcats?.child[l].head] = event.target.checked;
          // setChildChecked({
          //   ...childChecked,
          //   [subcats?.child[l].head]: event.target.checked,
          // });

          // console.log(subcats?.child[l].head, status);
          if (event.target.checked) {
            mapbox.setLayoutProperty(
              `state${subcats?.child[l].head}`,
              "visibility",
              status
            );
          } else {
            mapbox.setLayoutProperty(
              `state${subcats?.child[l].head}`,
              "visibility",
              status
            );
          }
        }
        // }
      }
    } else {
      // console.log(childLabel, status);

      mapbox.setLayoutProperty(`state${childLabel}`, "visibility", status);
    }
    setChildChecked(newchildChecked);
  };

  const handleChangeFilter = (
    event: React.ChangeEvent<{ value: unknown }>,
    id: string
  ) => {
    console.log("here", layerdata?.head, id, event?.target?.value);

    let layerdatanew: any = JSON.parse(JSON.stringify(layerdata));
    const idx = layerdatanew?.filters.findIndex((filter: any) => {
      return filter?.id.toString() === id.toString();
    });

    if (idx >= 0) {
      layerdatanew.filters[idx].selected = event?.target?.value;

      setLayerData(layerdatanew);
    }

    // const layer = mapbox.getLayer("state" + layerdata?.head);
    // const layer = mapbox.getSource("state" + layerdata?.head).vectorLayerIds;

    // const layer = mapbox.querySourceFeatures("mcmc-layers", {
    //   sourceLayer: layerdata?.head,
    // });

    console.log(layerdata);
    if (layerdata?.child && layerdata?.child.length > 0) {
      for (let l = 0; l < layerdata?.child?.length; l++) {
        console.log("child");
        if (event?.target?.value !== "-") {
          mapbox.setFilter(`state${layerdata?.child[l].head}`, [
            "==",
            ["get", id?.toString().toLowerCase()],
            event?.target?.value,
          ]);
        } else {
          console.log("parent");
          mapbox.setFilter(`state${layerdata?.child[l].head}`);
        }
      }
    }
    // setFilter(event?.target?.value as string);
  };

  const handleLayerCallback = (mainlayer: any, layer: any) => {
    console.log(mainlayer, layer);
    if (layerCallback) {
      layerCallback({
        parent: mainlayer,
        layer: layer,
      });
    }
  };

  const getChildComponent = (subChild: any, subChildChecked: any) => {
    if (subChild?.child?.length > 0) {
      return (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.collapseRoot}
        >
          <List component="div" className={classes.listModified} disablePadding>
            {subChild.child.map((thisChild: any, idx: number) => {
              return (
                <div key={idx.toString()} className={classes.childContent}>
                  <ListItem className={classes.listItemModified}>
                    <ListItemAvatar className={classes.avatarcontainer}>
                      <Avatar
                        alt={`${thisChild?.label}`}
                        src={`/assets/${thisChild?.icon}`}
                        className={classes.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        thisChild?.label
                          ? thisChild?.label
                          : startCase(thisChild?.head)
                      }
                    />
                    {thisChild?.type.toString() === "2" ? (
                      <>
                        {layerCallback ? (
                          <IconButton
                            size="medium"
                            onClick={() =>
                              handleLayerCallback(subChild, thisChild)
                            }
                            className="modaldata"
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        ) : (
                          <DataModal
                            module={thisChild?.head}
                            label={startCase(thisChild?.head)}
                          />
                        )}
                      </>
                    ) : (
                      <Checkbox
                        checked={
                          subChildChecked[thisChild?.head]
                            ? subChildChecked[thisChild?.head]
                            : false
                        }
                        value={
                          subChildChecked[thisChild?.head]
                            ? subChildChecked[thisChild?.head]
                            : false
                        }
                        onChange={(event: any) =>
                          handleChangeChild(event, thisChild?.head)
                        }
                        color="primary"
                        inputProps={{
                          "aria-label": "secondary checkbox",
                        }}
                      />
                    )}
                  </ListItem>
                  {thisChild?.child?.length > 0
                    ? getChildComponent(thisChild, subChildChecked)
                    : null}
                </div>
              );
            })}
          </List>
          <Divider className={classes.divider} />
        </Collapse>
      );
    } else {
      return <></>;
    }
  };

  const prepTitle: string = layerdata?.label
    ? layerdata?.label
    : layerdata?.head?.indexOf("_") !== -1
    ? startCase(layerdata?.head)
    : layerdata?.head?.toUpperCase();

  return (
    <div className={classes.root}>
      <ListItem button className={classes.listItemModified}>
        <ListItemIcon className={classes.listItemIcon} onClick={handleClick}>
          {layerdata?.child?.length > 0 ? (
            <>{open ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}</>
          ) : (
            <RoomIcon />
          )}
        </ListItemIcon>
        <ListItemText onClick={handleClick} primary={prepTitle} />
        {/* <Checkbox
          checked={checked}
          onChange={handleChange}
          value={checked}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        /> */}
      </ListItem>
      {childChecked && layerdata?.child?.length > 0 ? (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.collapseRoot}
        >
          <List component="div" className={classes.listModified} disablePadding>
            {layerdata.child.map((modelChild: any, key: number) => (
              <div key={key.toString()} className={classes.childContent}>
                <ListItem className={classes.listItemModified}>
                  {modelChild?.icon && modelChild?.icon !== "" && (
                    <ListItemAvatar className={classes.avatarcontainer}>
                      <Avatar
                        alt={`${modelChild?.label}`}
                        src={`/assets/${modelChild?.icon}`}
                        className={classes.avatar}
                      />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={
                      modelChild?.label
                        ? modelChild?.label
                        : startCase(modelChild?.head)
                    }
                  />
                  {modelChild?.type.toString() === "2" ? (
                    // <DataModal
                    //   module={modelChild?.head}
                    //   label={startCase(modelChild?.head)}
                    // />
                    <>
                      {layerCallback ? (
                        <IconButton
                          size="small"
                          onClick={() => handleLayerCallback({}, modelChild)}
                          className="modaldata"
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      ) : (
                        <DataModal
                          module={modelChild?.head}
                          label={startCase(modelChild?.head)}
                        />
                      )}
                    </>
                  ) : (
                    <Checkbox
                      checked={
                        childChecked[modelChild?.head]
                          ? childChecked[modelChild?.head]
                          : false
                      }
                      value={
                        childChecked[modelChild?.head]
                          ? childChecked[modelChild?.head]
                          : false
                      }
                      onChange={(event: any) =>
                        handleChangeChild(event, modelChild?.head)
                      }
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  )}
                </ListItem>
                {getChildComponent(modelChild, childChecked)}
                {/* {modelChild?.child?.length > 0 ? (
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapseRoot}
                  >
                    <List
                      component="div"
                      className={classes.listModified}
                      disablePadding
                    >
                      {modelChild.child.map(
                        (templateChild: any, idx: number) => {
                          return (
                            <div
                              key={idx.toString()}
                              className={classes.childContent}
                            >
                              <ListItem className={classes.listItemModified}>
                                <ListItemText
                                  primary={
                                    templateChild?.label
                                      ? templateChild?.label
                                      : startCase(templateChild?.head)
                                  }
                                />
                                {templateChild?.type.toString() === "2" ? (
                                  <DataModal
                                    module={templateChild?.head}
                                    label={startCase(templateChild?.head)}
                                  />
                                ) : (
                                  <Checkbox
                                    checked={
                                      childChecked[templateChild?.head]
                                        ? childChecked[templateChild?.head]
                                        : false
                                    }
                                    value={
                                      childChecked[templateChild?.head]
                                        ? childChecked[templateChild?.head]
                                        : false
                                    }
                                    onChange={(event: any) =>
                                      handleChangeChild(
                                        event,
                                        templateChild?.head
                                      )
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                )}
                              </ListItem>
                            </div>
                          );
                        }
                      )}
                    </List>
                    <Divider className={classes.divider} />
                  </Collapse>
                ) : null} */}
              </div>
            ))}
          </List>
          <Divider className={classes.divider} />
        </Collapse>
      ) : null}

      {checked && layerdata?.filters && layerdata?.filters?.length > 0 && (
        <div
          style={{
            textAlign: "left",
            width: "100%",
            paddingTop: "10px",
            paddingLeft: "45px",
            paddingRight: "25px",
          }}
        >
          <Paper
            elevation={0}
            style={{ border: "1px solid #dddddd", padding: "10px" }}
          >
            <Typography>Filters By:</Typography>
            {layerdata?.filters.map((filter: any, ix: number) => (
              // <>{filter.label}</>
              <FormControl className={classes.formControl} key={ix}>
                <InputLabel id="demo-simple-select-label">
                  {filter.label}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter?.selected ? filter?.selected : ""}
                  onChange={(e) => handleChangeFilter(e, filter?.id)}
                  fullWidth
                >
                  <MenuItem value={"-"}>-All-</MenuItem>
                  {filter?.data &&
                    filter?.data.map((data: string, six: number) => (
                      <MenuItem key={six} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ))}
          </Paper>
        </div>
      )}
    </div>
  );
};

export default LayerController;
