import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import type { RootState } from "../store/index";
import Routes from "../config/Routes";
import Maps from "./ModuleMaps";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

interface PropsTotal {
  user?: any;
}
const Home: React.FC<PropsTotal> = ({ user }) => {
  const classes = useStyles();
  const { profile } = useSelector((state: RootState) => state.userState);

  // console.log(profile);
  return (
    <>
      {profile?.position !== "HASIL" && (
        <div className="mapcontainer_module">
          <Maps />
        </div>
      )}
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        // alignItems="center"
      >
        {profile?.position !== "HASIL" && (
          <Grid item xs={12}>
            <div className="mapcontainer_module_dummy"></div>
          </Grid>
        )}

        {Routes.map((route: any, index: number) => {
          if (/\/module\//i.test(route.path)) {
            return (
              <Grid
                item
                xs={12}
                sm={3}
                key={index}
                // style={{ textAlign: "center" }}
              >
                <Card className={classes.root} key={index}>
                  <CardActionArea component={Link} to={route?.path}>
                    <CardMedia
                      className={classes.media}
                      image={`/images/${route.path}.jpg`}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="p">
                        {route?.name}
                      </Typography>

                      {route?.description ? (
                        <div style={{ minHeight: "50px" }}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {route?.description}
                          </Typography>
                        </div>
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </Typography>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          } else {
            return "";
          }
        })}
      </Grid>
    </>
  );
};

export default Home;
