import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link, useHistory } from "react-router-dom";

interface Props {
  open: boolean;
  callback: (status: boolean) => void;
  profiles: any[];
}

const MapDialog: React.FC<Props> = ({ open, callback, profiles }) => {
  const [value, setValue] = React.useState<number>(0);
  const [profile, setProfile] = React.useState<any>();

  React.useEffect(() => {
    if (value >= 0 && typeof profiles !== "undefined" && profiles.length > 0) {
      // console.log("profilexx", value, profiles);
      if (typeof profiles[value] !== "undefined") {
        setProfile(profiles[value]);
      } else {
        setProfile(profiles[0]);
      }
    }
  }, [value, profiles]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    if (typeof callback !== "undefined") {
      callback(false);
    }
  };

  console.log("profile", profiles, profile);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {profiles && profiles.length == 1
            ? profiles[0].properties?._type === "premise"
              ? "Premis"
              : "Suspek"
            : "Profiles"}
          {/* {profiles?.properties?._type === "premise" ? "Premis" : "Suspek"} */}
        </DialogTitle>
        <DialogContent>
          {profiles && profiles.length > 1 && (
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Premise & Suspects"
              >
                {profiles &&
                  profiles.map((profile: any, idx: number) => (
                    <Tab label={profile?.properties?.name} wrapped />
                  ))}
              </Tabs>
            </Box>
          )}
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <div
                  style={{
                    width: "100%",
                    maxHeight: "50vh",
                    overflow: "auto",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                    }}
                    src={
                      "https://ck.tuxgeo.dev/api/photos/" +
                      profile?.properties?.image_id
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {/* {profile?.properties?._type === "premise"
                    ? "Premis"
                    : "Suspek"}{" "}
                  :  */}
                  <strong>{profile?.properties?.name}</strong>
                </Typography>
              </Grid>
              {typeof profile?.properties?.syndicate !== "undefined" &&
                profile?.properties?.syndicate !== "" && (
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {profile?.properties?.syndicate}
                    </Typography>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Typography variant="body2">
                  {profile?.properties?.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {typeof profile?.properties[
                    profile?.properties?._type + "_id"
                  ] !== "undefined" ? (
                    <>
                      <Button
                        component={Link}
                        to={`/dashboard/profile/${profile?.properties?._type}/${
                          profile?.properties[
                            profile?.properties?._type + "_id"
                          ]
                        }`}
                        variant="contained"
                        fullWidth
                      >
                        View Profile
                      </Button>
                    </>
                  ) : (
                    <>no</>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* {profiles &&
              profiles.map((profile: any, idx: number) => (
                <Box key={idx} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Grid item xs={12}>
                      <div
                        style={{
                          width: "100%",
                          maxHeight: "50vh",
                          overflow: "auto",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "500px",
                            width: "100%",
                          }}
                          src={
                            "https://ck.tuxgeo.dev/api/photos/" +
                            profile?.properties?.image_id
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {profile?.properties?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {profile?.properties?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MapDialog;
