import CircularProgress from "@material-ui/core/CircularProgress";
import { DashboardCustomizeRounded, NavigateNext } from "@mui/icons-material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import {
  Box,
  Breadcrumbs,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { onAuthStateChanged } from "firebase/auth"; // New import
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { auth } from "../../../config/Firebase";
import "../Dataset.css";

const dateFormat = "dd MMM yyyy HH:mm";

const UsageStats = () => {
  const [token, setToken] = React.useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [data, setData] = React.useState<any[] | null>(null);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (!token) return;
    let pageNow = page + 1;

    fetch(
      "/api/report/table?" +
        "per_page=" +
        rowsPerPage +
        "&" +
        "page=" +
        pageNow,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTotal(data.metadata.total);
        setData(data.list);
      })
      .catch((error: any) => {
        console.error("error", error);
      });

    return () => {
      setData(null);
      setTotal(0);
    };
  }, [token, page, rowsPerPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a kategori.");
  }

  const breadcrumbs = [
    <Link
      component={RouterLink}
      to={`/dashboard2/senarai-laporan/terbaru`}
      // to={`/dashboard/senarai-laporan`}
      underline="hover"
      key="1"
      color="inherit"
      onClick={handleClick}
    >
      Senarai Laporan
    </Link>,
    <Link
      component={RouterLink}
      to="/" // Adjust this to your correct route
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleClick}
    >
      Perjudian Online
    </Link>,
  ];

  return (
    <Box width="100%">
      <Grid item container xs={12} direction="row">
        <DashboardCustomizeRounded fontSize="large" />
        <Typography
          sx={{ paddingBottom: "20px", paddingLeft: "20px", fontSize: "20px" }}
        >
          Senarai Laporan{" "}
        </Typography>
      </Grid>
      <Divider sx={{ marginBottom: "20px" }} variant="fullWidth" />

      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: "40px" }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Paper
        sx={{
          minHeight: "25vh",
          height: "100%",
          padding: "15px",
        }}
      >
        <Typography gutterBottom variant="h6">
          Senarai Laporan Perjudian Online
        </Typography>
        <Box width="100%" marginTop="10px">
          <TableContainer>
            <Table
              sx={{
                overflow: "auto",
                textAlign: "center",
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: "#F4F4F4", color: "black" }}>
                  <TableCell>No.</TableCell>
                  <TableCell>Jenis Premis</TableCell>
                  <TableCell>Nama Premis</TableCell>
                  <TableCell>Tarikh Laporan</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Nama Pegawai (Approver)</TableCell>
                  <TableCell>Daerah</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!data ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box
                        // justifyContent={"center"}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : data && data.length > 0 ? (
                  data.map((row: any, idx: any) => (
                    <TableRow key={idx}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{row.premise_type}</TableCell>
                      <TableCell>{row.premise_name}</TableCell>
                      <TableCell>
                        {new Date(row.created_at).toLocaleString([], {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.checker_name ?? "Tiada data"}</TableCell>
                      <TableCell>{row.district}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>No Data</>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={total ? total : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default UsageStats;
