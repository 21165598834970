import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = ({ data, option, callback, refCallback }: any) => {
  const chartRef = React.useRef<HTMLDivElement>(null);
  const [chartElement, setChartElement] = React.useState<HTMLDivElement>();

  React.useLayoutEffect(() => {
    if (chartRef.current) {
      setChartElement(chartRef.current);
      if (refCallback) {
        if (typeof chartRef.current.childNodes[0] !== "undefined") {
          refCallback(chartRef.current.childNodes[0]);
        }
      }
    }
  }, [chartElement, refCallback]);
  // const datadoughnut = {
  //   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: [12, 19, 3, 5, 2, 3],
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(75, 192, 192, 0.2)",
  //         "rgba(153, 102, 255, 0.2)",
  //         "rgba(255, 159, 64, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(54, 162, 235, 1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(75, 192, 192, 1)",
  //         "rgba(153, 102, 255, 1)",
  //         "rgba(255, 159, 64, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // const rgbToHex = (r: any, g: any, b: any) => {
  //   return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  // };

  // const hexToRgb = (hex: any) => {
  //   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   console.log(result);
  //   if (result) {
  //     var r = parseInt(result[1], 16);
  //     var g = parseInt(result[2], 16);
  //     var b = parseInt(result[3], 16);
  //     return {
  //       r: r,
  //       g: g,
  //       b: b,
  //     };
  //   }
  //   return {
  //     r: 0,
  //     g: 0,
  //     b: 0,
  //   };
  // };

  let options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltips: {
        mode: "dataset",
      },
      onHover: (event: any, chartElement: any) => {
        const target = event.native ? event.native.target : event.target;
        // target.style.cursor = chartElement[0] ? "pointer" : "default";
        // var point = this.getElementAtEvent(e);
        // if (event.length) target.style.cursor = "default";
        // else target.style.cursor = "default";
        target.style.cursor = "default";
      },
      legend: {
        onHover: (event: any) => {
          const target = event.native ? event.native.target : event.target;
          target.style.cursor = "pointer";
        },
        position:
          typeof option?.xplugins?.legend?.position !== "undefined"
            ? option?.xplugins?.legend?.position
            : ("left" as const),
        labels: {
          // boxWidth: 11,
          font: {
            size: 11,
          },
          usePointStyle: true,
        },
        onClick: function (evt: any, element: any) {
          // console.log("here", evt, element, data);
          const dsetidx = element.datasetIndex ? element.datasetIndex : 0;

          // let selected: number = -1;
          for (let a = 0; a < data.datasets[dsetidx].offset.length; a++) {
            // console.log(data.datasets[dsetidx].offset[a]);
            if (a.toString() === element.index.toString()) {
              if (data.datasets[dsetidx].offset[a] > 0) {
                data.datasets[dsetidx].offset[a] = 0;
              } else {
                data.datasets[dsetidx].offset[a] = 20;
                // selected = a;
              }
            } else {
              data.datasets[dsetidx].offset[a] = 0;
            }
          }
          evt.chart.update(data);
          // if (typeof callback !== "undefined") {
          //   if (selected >= 0) {
          //     callback(selected);
          //   } else {
          //     callback(null);
          //   }
          // }
        },
      },

      datalabels: {
        display: true,
        align: "center",
        // backgroundColor: "#ccc",
        // borderRadius: 3,
        font: {
          size: 9,
        },
        formatter: (value: any, categories: any) => {
          if (value && typeof value.toLocaleString !== "undefined") {
            return value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            });
          } else {
            return value;
          }
        },
        color: "#fff",
      },
    },

    onClick: function (evt: any, element: any) {
      // console.log(evt);
      if (element.length > 0) {
        // console.log(element, element[0].datasetIndex);
        // // you can also get dataset of your selected element
        // console.log(data.datasets[element[0].datasetIndex]);
        // console.log(data.labels[element[0].index]);

        // console.log(
        //   "first",
        //   typeof data.datasets[element[0].datasetIndex].offset
        // );
        let selected: number = -1;
        if (
          typeof data.datasets[element[0].datasetIndex].offset !== "undefined"
        ) {
          for (
            let a = 0;
            a < data.datasets[element[0].datasetIndex].offset.length;
            a++
          ) {
            // console.log(data.datasets[element[0].datasetIndex].offset[a]);
            if (a.toString() === element[0].index.toString()) {
              if (data.datasets[element[0].datasetIndex].offset[a] > 0) {
                // data.datasets[element[0].datasetIndex].offset[a] = 0;
              } else {
                data.datasets[element[0].datasetIndex].offset[a] = 20;
                // selected = a;
              }
              selected = a;
            } else {
              data.datasets[element[0].datasetIndex].offset[a] = 0;
            }
          }
        }
        evt.chart.update(data);

        if (typeof callback !== "undefined") {
          if (selected >= 0) {
            callback(selected);
          } else {
            callback(null);
          }
        }
      }
    },
    hoverOffset: 10,

    // labels: {
    //   render: "value",
    //   // render: "percentage",
    //   fontColor: function (data: any) {
    //     var rgb = hexToRgb(data.dataset.backgroundColor[data.index]);
    //     var threshold = 140;
    //     var luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
    //     return luminance > threshold ? "black" : "white";
    //   },
    //   precision: 2,
    // },
  };

  if (typeof option !== "undefined") {
    // options
    for (let a in option) {
      options[a] = option[a];
    }
  }

  return (
    <>
      {/* {typeof data?.datasets !== "undefined" && data?.datasets.length > 0 ? ( */}
      <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
        <Doughnut
          options={options}
          data={typeof data !== "undefined" ? data : {}}
        />
      </div>
      {/* ) : (
        <>missing data</>
      )} */}
    </>
  );
};

export default DoughnutChart;
