import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";
import Cookies from "universal-cookie";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth"; // New import

// import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Google } from "@mui/icons-material";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a href="https://hasil.gov.my/" target="_blank" rel="noreferrer">
        CK
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "120px",
    height: "100px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  adlogin: {
    margin: theme.spacing(1, 0, 0),
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
  },
}));

// export default function SignIn() {
const LoginPage: React.FunctionComponent<IPageProps> = (props) => {
  const cookies = new Cookies();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [email] = useState<string>("");
  const [password] = useState<string>("");
  // const [email, setEmail] = useState<string>("");
  // const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const signInWithEmail = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (typeof email !== "undefined" && password !== "") {
      if (error !== "") setError("");

      setAuthenticating(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((result: any) => {
          logging.info(result);
          if (/login/i.test(location.pathname)) {
            history.push(location.pathname.replace("/login", ""));
          } else {
            history.push("/");
          }
        })
        .catch((error: any) => {
          logging.error(error);
          setAuthenticating(false);
          setError(error.message);
        });
    } else if (typeof email !== "undefined" && password === "") {
      if (auth.isSignInWithEmailLink(window.location.href)) {
        auth
          .signInWithEmailLink(email, window.location.href)
          .then((result: any) => {
            logging.info(result);
            if (/login/i.test(location.pathname)) {
              history.push(location.pathname.replace("/login", ""));
            } else {
              history.push("/");
            }
          })
          .catch((error: any) => {
            logging.error(error);
            setAuthenticating(false);
            setError(error.message);
          });
      } else {
      }
    }
  };

  const signInWithADCustomToken = (token: any) => {
    // console.log(token);
    if (typeof token !== "undefined" && token !== "") {
      signInWithCustomToken(auth, token)
        .then((userCredential: any) => {
          // console.log(userCredential);
          setAuthenticating(true);
          // Signed in
          // const user = userCredential.user;
          logging.info(userCredential);
          if (/login/i.test(location.pathname)) {
            history.push(location.pathname.replace("/login", ""));
          } else {
            history.push("/");
          }
          // ...
        })
        .catch((error: any) => {
          // console.log(error);
          logging.error(error);
          setAuthenticating(false);
          setError(error.message);
          // ...
        });
    }
  };

  const signInWithProvider = (provider: any) => {
    setAuthenticating(true);
    // console.log(auth);
    if (typeof signInWithPopup !== "undefined") {
      signInWithPopup(auth, provider)
        .then((result: any) => {
          logging.info(result);
          if (/login/i.test(location.pathname)) {
            history.push(location.pathname.replace("/login", ""));
          } else {
            history.push("/");
          }
          // history.push("/");
        })
        .catch((error: any) => {
          logging.error(error);
          setAuthenticating(false);
          setError(error.message);
        });
    }
  };

  const adLogin = () => {
    // let url = "https://" + window.location.host;
    // if (/(localhost|127.0.0.1|172.16.1.62)/i.test(window.location.host)) {
    //   url = "https://lhdn15.tuxgeo.dev";
    // }
    // // console.log(url);
    // window.open(url + "/login?type=adfs", "hasillogin", "height=600,width=500");
    cookies.set("login", "dashboard", { path: "/" });
    let authurl =
      "https://secure.hasil.gov.my/adfs/oauth2/authorize?response_type=code" +
      "&client_id=" +
      "bancihasil" +
      "&resource=" +
      "urn:lhdnm:bancihasil" +
      "&redirect_uri=" +
      window.location.origin +
      "/login/adfs/callback";
    window.open(authurl, "geohasillogin", "height=600,width=500");

    // https://secure.hasil.gov.my/adfs/oauth2/logout?post_logout_redirect_uri=https://geohasil.hasil.gov.my/login/adfs/callback&state=state-here&x-client-SKU=bancihasil&x-client-ver=2.1.4.0
  };

  window.onmessage = function (e) {
    if (e.data !== "auth_error" && e.data !== "" && !/^!/i.test(e.data)) {
      if (typeof e.data === "string") {
        // console.log("register session", e.data);
        signInWithADCustomToken(e.data);
      }
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        border: "0px solid #ff0000",
        // marginTop: "-64px",
        backgroundColor: "#F6F6F6",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        verticalAlign: "middle",
        // paddingTop: "100px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        style={{
          // border: "1px solid #ff0000",
          height: "100vh",
          width: "100vw",
          margin: "0",
        }}
      >
        <Grid
          item
          xs={12}
          sm={7}
          style={{
            backgroundImage: "url(/images/bgweb.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundAttachment: "fixed",
            backgroundPosition: "center center",
          }}
        >
          &nbsp;
        </Grid>
        <Grid item xs={12} sm={5}>
          <div
            style={{
              // border: "1px solid #ff0000",
              width: "90%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Container
              component="main"
              maxWidth="xs"
              style={{
                border: "0 solid #ff0000",
                borderRadius: "5px",
                backgroundColor: "#F6F6F6",
                paddingTop: "2px",
              }}
            >
              <CssBaseline />
              <div className={classes.paper}>
                {/* <img
                  src="/images/PlaceholderLogo.png"
                  alt="CK"
                  height="100"
                  className="mcmclogo"
                /> */}
                <div style={{ paddingTop: "50px" }}>
                  <Typography
                    component="p"
                    sx={{
                      color: "#2C3A97",
                      fontSize: "24px",
                      fontWeight: "bolder",
                    }}
                  >
                    CK Profiling
                  </Typography>
                </div>
                <div>
                  <Typography
                    component="p"
                    sx={{
                      color: "#0A0000",
                      fontSize: "24px",
                      fontWeight: "bolder",
                    }}
                  >
                    Welcome Back!
                  </Typography>
                </div>
                <div style={{ fontSize: "16px" }}>
                  <Typography
                    component="p"
                    sx={{
                      display: "grid",
                      color: "#616161",
                      textAlign: "center",
                    }}
                  >
                    Log masuk dengan data anda yang anda masukkan semasa
                    pendaftaran
                  </Typography>
                </div>

                <ErrorText error={error} />
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={(e: any) => signInWithEmail(e)}
                >
                  {/(hasil.gov.my)/i.test(window.location.host) ? (
                    <Button
                      disabled={authenticating}
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.adlogin}
                      onClick={adLogin}
                    >
                      CK AD SIGN IN
                    </Button>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          disabled={authenticating}
                          type="button"
                          size="large"
                          fullWidth
                          variant="contained"
                          sx={{
                            borderRadius: "30px",
                            backgroundColor: "#2C3A97",
                          }}
                          onClick={() =>
                            signInWithProvider(new GoogleAuthProvider())
                          }
                          className={classes.googlesignin}
                          startIcon={<Google />}
                        >
                          LOGIN WITH GOOGLE
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </form>
              </div>
              <div style={{ padding: "25px" }}>
                <Copyright />
              </div>
            </Container>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
