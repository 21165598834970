import { DashboardCustomizeRounded, NavigateNext } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import {
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { onAuthStateChanged } from "firebase/auth"; // New import
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { auth } from "../../config/Firebase";

const dateFormat = "dd MMM yyyy HH:mm";

const UsageStats = () => {
  const [token, setToken] = React.useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [data, setData] = React.useState<any[] | null>(null);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [markReadTrigger, setMarkReadTrigger] = React.useState(0);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    if (!token) return;

    let pageNow = page + 1;

    fetch(
      "/api/report/table/latest?" +
        "per_page=" +
        rowsPerPage +
        "&" +
        "page=" +
        pageNow,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTotal(data.metadata.total);
        setData(data.list);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("error", error);
      });

    return () => {
      setData(null);
      setTotal(0);
    };
  }, [token, page, rowsPerPage, markReadTrigger]);

  function handleMarkAsRead(cases_id: number) {
    setLoading(true);
    fetch("/api/report/mark/" + cases_id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        setMarkReadTrigger((prev) => prev + 1);
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a kategori.");
  }

  const breadcrumbs = [
    <Link
      component={RouterLink}
      to={`/dashboard/senarai-laporan/terbaru`}
      underline="hover"
      key="1"
      color="inherit"
      onClick={handleClick}
    >
      Senarai Laporan
    </Link>,
    <Link
      component={RouterLink}
      to="/" // Adjust this to your correct route
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleClick}
    >
      Perjudian Online
    </Link>,
  ];

  return (
    <Box>
      <Grid item container xs={12} direction="row">
        <DashboardCustomizeRounded fontSize="large" />
        <Typography
          sx={{ paddingBottom: "20px", paddingLeft: "20px", fontSize: "20px" }}
        >
          Senarai Laporan{" "}
        </Typography>
      </Grid>
      <Divider sx={{ marginBottom: "20px" }} />

      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: "40px" }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Paper
        sx={{
          minHeight: "25vh",
          height: "100%",
          padding: "15px",
          margin: "-5px",
          width: "100%",
        }}
      >
        <Typography gutterBottom variant="h6">
          Senarai Laporan Terbaru
        </Typography>
        <Box marginTop="10px">
          <TableContainer>
            <Table
              sx={{
                overflow: "auto",
                textAlign: "center",
              }}
              size="small"
            >
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#F4F4F4",
                    color: "black",
                  }}
                >
                  <TableCell>No.</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Jenis Premis
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Nama Premis
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Tarikh Laporan
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Nama Pegawai (Creator)
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Daerah</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Kelulusan Laporan
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!data || loading ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box
                        // justifyContent={"center"}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <LinearProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : data && data.length > 0 ? (
                  data.map((row: any, idx: any) => (
                    <TableRow key={idx}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{row.premise_type}</TableCell>
                      <TableCell>{row.premise_name}</TableCell>
                      <TableCell>
                        {format(new Date(row.created_at), dateFormat)}

                        {/* {new Date(row.created_at).toLocaleString([], {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        })} */}
                      </TableCell>
                      <TableCell>{row.creator_name ?? "Tiada data"}</TableCell>
                      <TableCell>{row.district}</TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent={"center"}>
                          {row.is_approved === null ? (
                            <QueryBuilderOutlinedIcon color="secondary" />
                          ) : (
                            "No Data"
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" flexDirection="row">
                          <Tooltip title="Open" placement="top">
                            <IconButton
                              component={RouterLink}
                              to={`/dashboard/profile/cases/${row?.cases_id}`}
                            >
                              <FolderOpenIcon color="info" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mark as read" placement="top">
                            <IconButton
                              onClick={(e) => handleMarkAsRead(row.cases_id)}
                            >
                              <DoneIcon color="info" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>No Data</>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={total ? total : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default UsageStats;
