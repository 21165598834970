import React, { useCallback, useEffect } from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Type {
  [key: string]: number | string | object | any;
}

interface DialogProps {
  id?: any;
  type?: string;
  data?: Type;
  callback?: (status?: string, url?: string) => void;
  token: string;
  name?: string;
  maxsize?: number;
  folder?: boolean;
}

interface Upload {
  status?: string | any;
  statusMsg?: string;
  name?: string;
  size?: any;
  path?: string;
  [key: string]: number | string | object | any;
}

const presigned_server = "/api/s3/presigned";
const tmp_server = "/api/s3/tmpurl";

const DialogPanel = (props: DialogProps) => {
  const { id, type, token, name, callback, maxsize, folder } = props;

  const [upload, setUpload] = React.useState<Upload>({
    status: "OK",
    statusMsg: "",
    name: "",
    path: "",
  });
  const [preview, setPreview] = React.useState<string>("");

  const formatFileName = useCallback(
    (file: any) => {
      let newpathname: string[] = [];

      if (typeof type !== "undefined" && type !== "") {
        newpathname.push(type);
      }
      if (typeof id !== "undefined" && id !== "") {
        newpathname.push(id);
      }

      if (
        typeof folder !== "undefined" &&
        folder &&
        typeof name !== "undefined" &&
        name !== ""
      ) {
        newpathname.push(name + "/" + file?.name);
      } else if (
        ((typeof folder !== "undefined" && !folder) ||
          typeof folder === "undefined") &&
        typeof name !== "undefined" &&
        name !== ""
      ) {
        newpathname.push(name);
      } else {
        newpathname.push(file?.name);
      }

      return newpathname.join("/");
    },
    [type, id, folder, name]
  );

  const uploadFiles = useCallback(
    (file: any, url: any, tmpurl: any) => {
      console.log(file);
      fetch(url, {
        method: "PUT",
        body: file,
      })
        .then((e: any) => {
          // console.log(e, file);
          let info: object | any = { file: {}, upload: {} };
          for (let f in file) {
            info.file[f] = file[f];
          }
          for (let f in e) {
            info.upload[f] = e[f];
          }
          // updateData(file, JSON.stringify(info));
          setPreview("");
          setUpload({
            status: "COMPLETED",
            statusMsg: "Uploaded",
            name: file.name,
            path: formatFileName(file),
          });
          if (typeof callback !== "undefined") {
            callback("COMPLETED", tmpurl);
          }
        })
        .catch((e) => {
          // console.error(e);
          setUpload({
            status: "ERROR",
            statusMsg: e,
            name: file.name,
          });
          setPreview("");
        });
    },
    [callback, formatFileName]
  );

  const retrieveNewURL = useCallback(
    (file: any, cb: any) => {
      let filename: string = encodeURIComponent(formatFileName(file));
      filename = filename.replace(/&/gi, "%26");

      if (token !== "") {
        fetch(`${presigned_server}?file=${filename}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response: any) => {
            if (
              typeof response?.error !== "undefined" &&
              response?.error !== ""
            ) {
              window.alert("error");
            } else {
              response.json().then((resp: any) => {
                cb(file, resp.url, resp.tmp_url);
              });
            }
          })
          .catch((e) => {
            setUpload({
              status: "ERROR",
              statusMsg: "SERVER ERROR!!!! " + e,
            });
            setPreview("");
            console.error(e);
          });
      } else {
        window.alert("missing token");
      }
    },
    [token, formatFileName]
  );

  const signUpload = useCallback(
    (file: any, tmptoken?: any) => {
      retrieveNewURL(file, (file: any, url: any, tmpurl?: any) => {
        uploadFiles(file, url, tmpurl);
      });
    },
    [retrieveNewURL, uploadFiles]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      //currently doing 1 file upload only... to enabled multi file... just enhance this function..
      const maxfilesize = maxsize ? maxsize * 1000000 : 2000000;
      if (acceptedFiles[0].size <= maxfilesize) {
        setUpload({
          status: "UPLOADING",
          statusMsg: "Uploading...",
          name: acceptedFiles[0].name,
        });
        setPreview("");
        signUpload(acceptedFiles[0], token);
      } else {
        setUpload({
          status: "ERROR",
          statusMsg:
            "Exceed maximum file size, Maximum Allowed file size " +
            (maxfilesize / 1000000).toFixed(2) +
            "MB",
          size: acceptedFiles[0].size,
          name: acceptedFiles[0].name,
        });
        setPreview("");
      }
    },
    [token, signUpload, maxsize]
  );

  useEffect(() => {
    if (
      upload?.status === "COMPLETED" &&
      typeof upload?.path !== "undefined" &&
      upload?.path !== "" &&
      token !== "" &&
      preview === ""
    ) {
      fetch(`${tmp_server}?file=${upload?.path}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response: any) => {
          return response.json();
        })
        .then((data: any) => {
          console.log(data);
          if (data?.status === "OK") {
            setPreview(data?.url);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [upload, preview, token]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      style={{
        width: "99%",
        height: "99%",
        minHeight: "10px",
        paddingTop: "5%",
        textAlign: "center",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        border: "1px dashed #000000",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <>
          {upload.status === "COMPLETED" && (
            <>
              <div>
                <AddTaskIcon style={{ color: "#00ff00" }} />
              </div>
              {typeof upload !== "undefined" && preview !== "" && (
                <div>
                  <img src={preview} alt={upload?.name} />
                </div>
              )}
            </>
          )}
          {upload.status === "ERROR" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ErrorIcon style={{ color: "#ff0000" }} />
              <br />
              <Typography variant="caption">{upload?.statusMsg}</Typography>
            </div>
          )}
          {upload.status === "UPLOADING" ? (
            <CircularProgress />
          ) : (
            <p>Drag 'n' drop photo files here, or click to select files</p>
          )}
        </>
      )}
    </div>
  );
};

export default DialogPanel;
