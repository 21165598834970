import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../store/index";

import Admin from "./Admin";
import Modules from "./Module";
// import PusatInternet from "../components/PusatInternet";
// import PusatInternetByAgency from "../components/PusatInternet/ByAgency";
// import PusatInternetByProvider from "../components/PusatInternet/ByProvider";
const Home: React.FunctionComponent = (props) => {
  const history = useHistory();
  const { profile } = useSelector((state: RootState) => state.userState);

  // console.log(profile);

  if (/(administrator|mcmc)/i.test(profile?.position)) {
    return (
      <div>
        <Admin />
      </div>
    );
  } else if (/manager/i.test(profile?.position)) {
    return (
      <div>
        <Modules />
      </div>
    );
  } else if (/agency/i.test(profile?.position)) {
    return <div>{/* <PusatInternetByAgency /> */}</div>;
  } else if (/provider/i.test(profile?.position)) {
    return <div>{/* <PusatInternetByProvider /> */}</div>;
  } else if (/rakandigital/i.test(profile?.position)) {
    // return <div>under development</div>;
    return <div>{/* <PusatInternetByProvider /> */}</div>;
  } else {
    history.push("/dashboard");
    return (
      <>
        We cannot determine your access control level, Please contact
        administrator if you think this is an error.
      </>
    );
  }
};

export default Home;
