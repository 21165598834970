import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { format } from "date-fns";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation, useRouteMatch } from "react-router-dom";
// import PoppinsWoff from "../../fonts/Poppins/Poppins-Regular.woff";
import type { RootState } from "../../store/index";
import Business from "./Business";
import Dataset from "./Dataset";
import Default from "./Default";
import HeatMap from "./HeatMap";
import Kategori from "./Kategori";
import {
  default as LaporanTerbaru,
  default as UsageFoboStats,
} from "./LaporanTerbaru";
import MapOperation from "./MapOperation";
import MapTracking from "./MapTracking";
// import SenaraiLaporan from "./SenaraiLaporan";
import UsageStateStats from "./UsageStateStats";
import UserFoboList from "./UserFoboList";
import PoppinsWoff from "../../fonts/Poppins/Poppins-Regular.woff";

import "./index.css";
import { Chip } from "@mui/material";
// import SenaraiLaporan from "./SenaraiLaporan/SenaraiLaporan";
// import LaporanTerbaru from "./LaporanTerbaru";
import UserList from "./UserList";
import { MainListItems, secondaryListItems } from "./listItems";
import TableList from "./SenaraiLaporan/TableList";
import ProfileSuspect from "./Profile/Suspect";
import ProfilePremise from "./Profile/Premise";
import ProfileCases from "./Profile/Cases";
// import "./index.css";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {/* {"Copyright © "}
      <Link color="inherit" href="https://tuxgeo.com/">
        TuxGeo+
      </Link>{" "}
      by{" "}
      <Link color="inherit" href="https://tuxuri.com/">
        Tuxuri
      </Link>
      {"  "}
      {new Date().getFullYear()}
      {"."} */}
    </Typography>
  );
}

const drawerWidth: number = 240;

const mdTheme = createTheme({
  typography: {
    fontFamily: "Poppins, Verdana, Arial",
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-size: 90%;
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsWoff}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const DashboardContent = () => {
  // const classes = useStyles();
  const location = useLocation();
  const match = useRouteMatch();
  // const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { profile } = useSelector((state: RootState) => state.userState);

  const drawer = (
    <div>
      <Toolbar>
        <img
          src="/images/PlaceholderLogo.png"
          alt="ck logo"
          style={{ height: "40px", marginRight: "10px" }}
        />
        <Typography
          variant="h6"
          color="#2C3A97"
          component="div"
          style={{ marginRight: "25px", fontWeight: "bolder" }}
        >
          CK Profiling
        </Typography>
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton> */}
      </Toolbar>
      <Divider />
      <List component="nav" className="listitem">
        <MainListItems path={location?.pathname} status={true} />
        <Divider sx={{ my: 1 }} />
        {secondaryListItems}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={mdTheme}>
      <Box display="flex" sx={{ backgroundColor: "#F6F6F6" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "#000000",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Selamat Datang! {profile?.name ? profile.name : profile.email}
            </Typography>

            <Chip
              label={profile?.name ? profile.name : profile.email}
              avatar={
                <Avatar
                  alt={profile.name ? profile.name : profile.email}
                  src={profile?.picture}
                />
              }
              variant="outlined"
              sx={{ color: "#F5FF02" }}
            />
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Route
            path={`${match.path}/kategori`}
            exact={true}
            component={Kategori}
          />
          <Route
            path={`${match.path}/leaderboard/:stateid/:departmentid`}
            exact={true}
            component={Kategori}
          />
          <Route
            path={`${match.path}/operation`}
            exact={true}
            render={() => (
              <Redirect
                to={`${match.path}/operation/${format(
                  new Date(),
                  "yyyy-MM-dd"
                )}`}
              />
            )}
          />
          <Route
            path={`${match.path}/operation/:date`}
            exact={false}
            component={MapOperation}
          />

          <Route
            path={`${match.path}/tracking`}
            exact={true}
            render={() => (
              <Redirect
                to={`${match.path}/tracking/${format(
                  new Date(),
                  "yyyy-MM-dd"
                )}`}
              />
            )}
          />
          <Route
            path={`${match.path}/tracking/:date`}
            exact={false}
            component={MapTracking}
          />
          <Route
            path={`${match.path}/dataset`}
            exact={true}
            component={Dataset}
          />
          <Route
            path={`${match.path}/dataset/type/:type`}
            exact={true}
            component={Dataset}
          />
          <Route
            path={`${match.path}/dataset/user/:user`}
            exact={true}
            component={Dataset}
          />
          <Route path={`${match.path}/map`} exact={true} component={HeatMap} />
          <Route
            path={`${match.path}/senarai-laporan/terbaru`}
            exact={true}
            component={LaporanTerbaru}
          />
          <Route
            path={`${match.path}/senarai-laporan`}
            exact={true}
            component={TableList}
          />
          <Route
            path={`${match.path}/stats/state`}
            exact={true}
            component={UsageStateStats}
          />
          <Route
            path={`${match.path}/stats/fobo`}
            exact={true}
            component={UsageFoboStats}
          />
          <Route
            path={`${match.path}/business/:id`}
            exact={true}
            component={Business}
          />
          <Route
            path={`${match.path}/user`}
            exact={true}
            component={UserList}
          />
          <Route
            path={`${match.path}/user/fobo`}
            exact={true}
            component={UserFoboList}
          />
          <Route
            path={`${match.path}/profile/suspect/:id`}
            exact={true}
            component={ProfileSuspect}
          />
          <Route
            path={`${match.path}/profile/premise/:id`}
            exact={true}
            component={ProfilePremise}
          />
          <Route
            path={`${match.path}/profile/cases/:id`}
            exact={true}
            component={ProfileCases}
          />

          <Route path={`${match.path}`} exact={true} component={Default} />
          <Copyright marginTop={"10px"} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default function Dashboard() {
  return <DashboardContent />;
}
