import * as React from "react";
import { DateRange, DayPicker } from "react-day-picker";
import {
  Autocomplete,
  Button,
  LinearProgress,
  Popover,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Box from "@mui/material/Box";

interface Props {
  callback: (status: any) => void;
  label?: string;
}

const InputDateRange: React.FC<Props> = ({ label, callback }) => {
  const defaultSelected: DateRange = {
    from: undefined,
    to: undefined,
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [range, setRange] = React.useState<DateRange | undefined>(
    defaultSelected
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCalendarChange(newRange: DateRange | undefined) {
    setRange(newRange);
    if (typeof callback !== "undefined") {
      callback(newRange);
    }

    // if (newRange && newRange.from && newRange.to) {
    //   const formattedFrom = format(newRange.from, "yyyy/MM/dd");
    //   const formattedTo = format(newRange.to, "yyyy/MM/dd");
    //   // setFilters({ ...filters, dateRange: `${formattedFrom} - ${formattedTo}` });
    //   setFilters({
    //     ...filters,
    //     premiseFrom: `${formattedFrom}`,
    //     premiseTo: `${formattedTo}`,
    //   });
    // } else {
    //   setFilters({ ...filters, premiseFrom: "", premiseTo: "" });
    // }
  }

  function resetRange() {
    setRange({ from: undefined, to: undefined });
    if (typeof callback !== "undefined") {
      callback({ from: undefined, to: undefined });
    }
    // setFilters({ ...filters, premiseFrom: "", premiseTo: "" });
  }

  return (
    <React.Fragment>
      <Typography
        sx={{
          "&:hover": {
            border: "1px solid black",
          },
          border: "1px solid #bcbcbc",
          width: "100%",
          padding: "10px",
          borderRadius: "6px",
          // marginBottom: "40px",
          fill: "#004C5B",
          opacity: range?.from && range?.to ? 1 : 0.7,
        }}
        onClick={handleClickCalendar}
      >
        {range && range.from && range.to
          ? `${format(range.from, "yyyy/MM/dd")} -  ${format(
              range.to,
              "yyyy/MM/dd"
            )}`
          : label}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DayPicker
          mode="range"
          captionLayout="dropdown-buttons"
          selected={range}
          onSelect={handleCalendarChange}
        />
        <Box margin="0px 5px 5px" display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            color="error"
            size="small"
            sx={{ marginRight: "5px" }}
            onClick={resetRange}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#2020ff",
              "&:hover": {
                backgroundColor: "#3a3aff",
              },
            }}
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            Selesai
          </Button>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default InputDateRange;
