const Config = {
  firebase: {
    apiKey: "AIzaSyCdMjbTgj1F80_XkH7m8wlikYk_BCq-wBo",
    authDomain: "ck-profiling.firebaseapp.com",
    projectId: "ck-profiling",
    storageBucket: "ck-profiling.appspot.com",
    messagingSenderId: "1048167935443",
    appId: "1:1048167935443:web:6cd1c9e670892093e69326",
    measurementId: "G-X5K7V72929",
    databaseURL: "",
  },
};

export default Config;
