import React, { Fragment } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InfoBox from "./InfoBox";
// import EventIcon from "@mui/icons-material/Event";
// import GroupIcon from "@mui/icons-material/Group";
// import StoreIcon from "@mui/icons-material/Store";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import Avatar from "@mui/material/Avatar";
import { onAuthStateChanged } from "firebase/auth"; // New import
import { auth } from "../../config/Firebase";

import { format, isValid } from "date-fns";
import Map from "../MapLight";
// import { count } from "console";

// const useStyles = makeStyles((theme) => ({}));

const MapOperation = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [filters, setFilters] = React.useState<any>({
    date: new Date(),
  });
  const [token, setToken] = React.useState<any>("");
  const [websocket, setWebsocket] = React.useState<any>();
  const [maphandler, setMapHandler] = React.useState<any>();
  const [feature, setFeature] = React.useState<any>();
  const [features, setFeatures] = React.useState<any[]>();
  const [wsfeatures, setWsFeatures] = React.useState<any[]>();
  const [setfocus, setSetFocus] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(7);
  const [stats, setStats] = React.useState<any>({
    total: 0,
    position_0: 0,
    position_1: 0,
    department: 0,
  });
  let { date } = useParams();

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof date !== "undefined" && date !== "") {
      setFilters({ date: new Date(date).setHours(0, 0, 0) });
    }
  }, [date]);

  const infoboxs = [
    {
      value: stats.total,
      label: "Jumlah",
      icon: (
        <PeopleAltIcon
          sx={{ fontSize: 60, color: "#111111" }}
          fontSize="large"
        />
      ),
    },
    {
      value: stats.position_0,
      label: "Pegawai",
      icon: (
        <PersonIcon sx={{ fontSize: 60, color: "#ffcc33" }} fontSize="large" />
      ),
    },
    {
      value: stats.position_1,
      label: "Lain-lain",
      icon: (
        <PersonSearchIcon
          sx={{ fontSize: 60, color: "#ff0000" }}
          fontSize="large"
        />
      ),
    },
    {
      value: stats.department,
      label: "Sektor",
      icon: (
        <MapsHomeWorkIcon
          sx={{ fontSize: 60, color: "#1AAF22" }}
          fontSize="large"
        />
      ),
    },
  ];

  React.useEffect(() => {
    if (typeof wsfeatures !== "undefined" && wsfeatures.length > 0) {
      let filteredfeatures: any[] = [];
      let tmpfeatures: any[] = [];
      if (typeof features !== "undefined") {
        tmpfeatures = JSON.parse(JSON.stringify(features));
      }

      // let tmpfeatures: any[] = JSON.parse(JSON.stringify(features));
      for (let w = 0; w < wsfeatures.length; w++) {
        let idx: number = -1;
        if (tmpfeatures && tmpfeatures.length > 0) {
          idx = tmpfeatures.findIndex((feat: any, id: number) => {
            return (
              typeof feat?.properties?.id !== "undefined" &&
              typeof wsfeatures[w].properties?.id !== "undefined" &&
              feat?.properties?.id.toString() ===
                wsfeatures[w].properties?.id.toString()
            );
          });
        }
        filteredfeatures.push(wsfeatures[w]);
        if (idx >= 0) {
          tmpfeatures.splice(idx, 1);
        }
      }

      const newfeatures = filteredfeatures.concat(tmpfeatures);
      setFeatures(formatGeoJSON(newfeatures));
      setWsFeatures(undefined);
    }
  }, [wsfeatures, features]);

  React.useEffect(() => {
    if (typeof features !== "undefined" && features.length > 0) {
      let counts: any = {
        total: features.length,
        position_0: 0,
        position_1: 0,
        department: 0,
        departments: [],
      };
      let departments: any = {};
      for (let f = 0; f < features.length; f++) {
        if (typeof features[f].properties.position !== "undefined") {
          if (features[f].properties.position.toString() === "0") {
            counts.position_0++;
          } else if (features[f].properties.position.toString() === "1") {
            counts.position_1++;
          }
        }
        departments[features[f].properties.department_id] = 1;
      }
      for (let d in departments) {
        counts.department++;
        counts.departments.push(d);
      }
      setStats(counts);
    } else {
      setStats({
        total: 0,
        position_0: 0,
        position_1: 0,
        department: 0,
      });
    }
  }, [features]);

  const formatGeoJSON = (json: any[]) => {
    for (let f = 0; f < json.length; f++) {
      if (typeof json[f].properties.position !== "undefined") {
        if (json[f].properties.position.toString() === "0") {
          json[f].properties._icon = "/images/markers/user-round-yellow.png";
        } else if (json[f].properties.position.toString() === "1") {
          json[f].properties._icon = "/images/markers/user-round-red.png";
        } else {
          json[f].properties._icon = "/images/markers/user-round-red.png";
        }
      } else {
        json[f].properties._icon = "/images/markers/user-round-red.png";
      }
      if (typeof json[f].properties.name === "undefined") {
        json[f].properties.name = json[f].properties.agent_name;
      }
    }
    return json;
  };

  React.useEffect(() => {
    if (
      typeof filters?.date !== "undefined" &&
      filters?.date !== "" &&
      token !== ""
    ) {
      console.log("initialize data for date");
      const begin = format(filters?.date, "yyyy-MM-dd");

      fetch("/api/user/tracking?live=true&date=" + begin, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (body) {
          return body.json();
        })
        .then(function (data) {
          if (!data.features) {
            setFeatures([]);
            return;
          } else {
            setFeatures(formatGeoJSON(data?.features));
          }

          // for (let i = 0; i < data.features.length; i++) {
          // }
        })
        .catch(function (err) {
          console.log(err);
          alert("Unable to fetch census data");
        });
    }
  }, [filters?.date, token]);

  React.useEffect(() => {
    const startWS = () => {
      let base: string = "";
      let hostname: string = "";
      // console.log(window.location);
      if (/(localhost|127.0.0.1|172.16.1.62)/i.test(window.location.hostname)) {
        hostname = "ck.tuxgeo.dev";
        // hostname = "geohasil.hasil.gov.my";
      } else {
        hostname = window.location.hostname;
      }

      if (window.location.protocol === "https:") {
        base = "wss://" + hostname;
      } else if (window.location.protocol === "http:") {
        base = "ws://" + hostname;
      } else {
        alert("unable to support unknown protocol!");
        return;
      }

      if (!base.endsWith("/")) {
        base = base + "/";
      }

      let endpoint: string = base + "service/live/officer";

      // console.log(endpoint);
      // const previous = new Date();
      const conn = new WebSocket(endpoint);

      conn.onopen = () => {
        console.log("WebSocket Client Connected");
      };
      conn.onmessage = function (evt: any) {
        let eventfeatures: any[] = JSON.parse(evt.data).features;
        let filteredfeatures: any[] = [];

        if (eventfeatures.length <= 0) {
          // console.log("no update");
          return false;
        } else {
          for (let i = 0; i < eventfeatures.length; i++) {
            // console.log(
            //   eventfeatures[i].properties,
            //   eventfeatures[i].properties?.ts
            // );
            if (
              format(
                new Date(eventfeatures[i].properties?.timestamp_human),
                "yyyy-MM-dd"
              ) === format(filters?.date, "yyyy-MM-dd")
            ) {
              eventfeatures[i].properties.created_at =
                eventfeatures[i].properties.timestamp_human;
              eventfeatures[i].properties.name =
                eventfeatures[
                  i
                ].properties.data?.maklumat_asas?.nama_perniagaan;

              filteredfeatures.push(eventfeatures[i]);
            }
          }
        }

        // console.log(eventfeatures, filteredfeatures);
        setWsFeatures(filteredfeatures);
        if (filteredfeatures.length > 0) {
          setSetFocus(true);
        }
      };
      conn.onclose = function (e) {
        console.log(
          "Socket is closed. Reconnect will be attempted in 5 second.",
          e.reason
        );
        setTimeout(() => {
          startWS();
        }, 5000);
      };

      setWebsocket(conn);
    };
    if (typeof websocket === "undefined" && typeof maphandler !== "undefined") {
      startWS();
    }
  }, [websocket, maphandler, filters?.date]);

  const mapCallback = (callback: any) => {
    // console.log("c", callback);
    if (typeof maphandler === "undefined" && callback && callback.map) {
      setMapHandler(callback);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const setFocus = (feat: any) => {
    setFeature(feat);
  };

  // rows = data?.features;
  let rows: any[] | any = features;
  if (typeof features !== "undefined" && features.length > 0) {
    if (features.length > rowsPerPage) {
      rows = features.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      );
    }
  }

  return (
    <Fragment>
      <table
        cellSpacing={10}
        style={{
          width: "100%",
          height: "calc(100vh - 170px)",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                height: "1px",
                verticalAlign: "top",
                // border: "1px solid #00ff00",
              }}
            >
              <Grid container spacing={2}>
                {infoboxs &&
                  infoboxs.map((info: any, idx: any) => (
                    <Grid item key={idx} xs={12} lg={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <InfoBox
                          label={info.label}
                          value={info.value}
                          icon={info.icon}
                        />
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </td>
            <td
              rowSpan={2}
              style={{
                width: "300px",
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" className="cardtitle">
                      Tarikh
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // label="Dari"
                          value={filters?.date ? filters?.date : ""}
                          onChange={(newValue) => {
                            if (
                              isValid(newValue) &&
                              newValue.getFullYear() >= 2020
                            ) {
                              setFilters({
                                ...filters,
                                date: newValue.setHours(0, 0, 0),
                              });
                              history.push({
                                pathname: `${match.path.replace(
                                  "/:date",
                                  ""
                                )}/${format(newValue, "yyyy-MM-dd")}`,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="standard"
                            />
                          )}
                          inputFormat="dd/MM/yyyy"
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" className="cardtitle">
                      Senarai Pegawai
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        // maxHeight: "calc(100vh - 400px)",
                        height: "calc(100vh - 430px)",
                        overflowY: "auto",
                        // border: "1px solid #ff0000",
                        marginBottom: "10px",
                      }}
                    >
                      {rows &&
                        rows.map((feature: any, idx: number) => (
                          <Paper
                            key={idx}
                            sx={{
                              p: 1,
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "10px",
                              border: "1px solid #eeeeee",
                              cursor: "pointer",
                            }}
                            onClick={() => setFocus(feature)}
                          >
                            <Grid
                              container
                              spacing={0}
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={12} sm={3}>
                                {feature.properties?.picture !== "" &&
                                feature?.properties.picture !== null ? (
                                  <Avatar
                                    alt={feature?.properties.agent_name}
                                    src={feature?.properties.picture}
                                    sx={{
                                      width: 36,
                                      height: 36,
                                      marginRight: "20px",
                                    }}
                                  />
                                ) : (
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: 40,
                                      color:
                                        typeof feature?.properties?.status !==
                                          "undefined" &&
                                        feature?.properties?.status.toString() ===
                                          "1"
                                          ? "#ff0000"
                                          : "#ffcc33",
                                    }}
                                    fontSize="large"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={9}>
                                <Typography variant="body2">
                                  {feature?.properties?.name
                                    ? feature?.properties?.name
                                    : feature?.properties?.agent_name}
                                </Typography>
                                <Typography variant="body2">
                                  {feature?.properties?.email
                                    ? feature?.properties?.email
                                    : feature?.properties?.agent_email}
                                </Typography>
                                <Typography variant="caption">
                                  {feature?.properties?.timestamp_human}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                    </div>
                    {features && features.length > rowsPerPage && (
                      <Pagination
                        count={
                          typeof features !== "undefined"
                            ? Math.ceil(features.length / rowsPerPage)
                            : 0
                        }
                        defaultPage={1}
                        boundaryCount={1}
                        siblingCount={0}
                        page={page}
                        onChange={handleChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </td>
          </tr>
          <tr>
            <td
              style={{
                height: "99%",
                verticalAlign: "top",
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  height: "100%",
                }}
              >
                <Map
                  mapcallback={mapCallback}
                  features={features}
                  reDrawFeatures={true}
                  focusLatestFeature={setfocus}
                  popupLatestFeature={true} // if focusLatestFeature is true
                  popupLatestFeatureField={"image_id"} // if focusLatestFeature is true
                  focusfeature={feature}
                  focusfeaturepopup={true}
                  popupContentColumns={[
                    { label: "", id: "name" },
                    { label: "Last Seen", id: "timestamp_human" },
                    {
                      label: "e-mail",
                      id: "agent_email",
                    },
                    { label: "", id: "picture", isImage: true },
                  ]}
                />
              </Paper>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default MapOperation;
