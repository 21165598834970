const DEFAULT_NAMESPACE = "Client";
const DEFAULT_SHOWMSG = false;

const info = (message: any, namespace?: string) => {
  if (typeof message === "string") {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO]`,
      DEFAULT_SHOWMSG ? message : "hidden"
    );
  }
};

const warn = (message: any, namespace?: string) => {
  if (typeof message === "string") {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN]`,
      DEFAULT_SHOWMSG ? message : "hidden"
    );
  }
};

const error = (message: any, namespace?: string) => {
  if (typeof message === "string") {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR]`,
      DEFAULT_SHOWMSG ? message : "hidden"
    );
  }
};

const getDate = () => {
  return new Date().toISOString();
};

const Logging = { info, warn, error };

export default Logging;
