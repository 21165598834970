import React, { Fragment } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import "./Table.css";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const TableData = ({ data, option, callback }: any) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    if (typeof option !== "undefined" && option && option.rowperpage) {
      setRowsPerPage(parseInt(option.rowperpage));
    }
  }, [option]);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onClickRow = (item: any) => {
    if (callback) {
      callback(item);
    }
  };

  let rows: any[] = [];
  if (data) {
    // rows = data;
    rows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }
  return (
    <React.Fragment>
      <Table size="small" className="tabledata">
        <TableHead>
          <TableRow>
            {option?.numbering && (
              <TableCell sx={{ width: "10%", maxWidth: "10px" }}>#</TableCell>
            )}
            {option &&
              option.columns.map((col: any, idx: any) => (
                <TableCell key={idx}>{col.label}</TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 ? (
            <>
              {rows.map((row: any, idx: any) => (
                <TableRow
                  key={idx}
                  onClick={() => onClickRow(row)}
                  style={{ cursor: "pointer" }}
                >
                  {option?.numbering && (
                    <TableCell align="right">
                      {rowsPerPage * page + idx + 1}.
                    </TableCell>
                  )}
                  {option &&
                    option.columns.map((col: any, id: any) => (
                      <Fragment key={id}>
                        {/^total/i.test(col.id) ? (
                          <TableCell align="right">
                            {typeof row?.properties !== "undefined" &&
                            typeof row?.properties[col.id] !== "undefined"
                              ? row?.properties[col.id]
                              : row[col.id]}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {typeof row?.properties !== "undefined" &&
                            typeof row?.properties[col.id] !== "undefined"
                              ? row?.properties[col.id]
                              : row[col.id]}
                          </TableCell>
                        )}
                      </Fragment>
                    ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell
                colSpan={option.columns ? option.columns?.length : 1}
                style={{ textAlign: "center" }}
              >
                No Data Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          {data && data.length > rowsPerPage && (
            <TableRow>
              <TablePagination
                colSpan={
                  option && option.columns
                    ? option.columns.length + (option?.numbering ? 1 : 0)
                    : 1
                }
                count={data.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                className="pagination"
                rowsPerPageOptions={[5, 10, 20, 50]}
              />
            </TableRow>
          )}
        </TableFooter>
      </Table>

      {option?.more && (
        <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
          See more orders
        </Link>
      )}
    </React.Fragment>
  );
};

export default TableData;
