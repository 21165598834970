import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { Headers, fetchPlus } from "./../../store/headers";
import layerHandler from "./layerHandler";
import LayerController from "./LayerController";
// import { callbackify } from "util";

interface vectorTilesProps {
    mapbox: any;
    profile?: any;
    category?: any;
    grid?: boolean;
    layerCallback?: (value: any) => void;
}

const useStyles = makeStyles({
    box: {
        position: "absolute",
        width: "20vw",
        minWidth: "300px",
        top: 0,
        right: 0,
        bottom: "14px",
        zIndex: 9,
        backgroundColor: "rgba(255,255,255,0.5)",
    },
    box_grid: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    content: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
        overflowX: "hidden",
        // maxHeight: 400,
        width: "100%",
        backgroundColor: "white",
        borderRadius: 3,
        padding: 0,
        maxHeight: "calc(100vh - 420px)",
        overflow: "auto !important",
        border: "0px solid #ff0000",
    },
});

let api: string = "/api/module/tree/1";

const VectorTiles = ({
    mapbox,
    profile,
    category,
    grid,
    layerCallback,
}: vectorTilesProps) => {
    const classes = useStyles();
    const [data, setData] = useState<any>(null);
    // const [load, setLoad] = useState<boolean>(false);
    const [listModule, setListModule] = useState<Array<string> | null>(null);

    useEffect(() => {
        let isMounted = true;

        let templatelayers: string[] = [];

        const getChilds = (childs: any[]) => {
            let newchilds: any[] = [];
            for (let c = 0; c < childs.length; c++) {
                if (
                    !childs[c].template ||
                    (childs[c].template &&
                        typeof childs[c].type !== "undefined" &&
                        childs[c].type !== "")
                ) {
                    let mod: any = {
                        child: [],
                        filters: [],
                        head: childs[c].module_tag
                            ? childs[c].module_tag
                            : childs[c].code,
                        label: childs[c].label
                            ? childs[c].label
                            : childs[c].name,
                        icon: childs[c].icon ? childs[c].icon : "",
                        style: childs[c].style ? childs[c].style : {},
                        type:
                            typeof childs[c].type !== "undefined"
                                ? childs[c].type
                                : "",
                    };
                    if (childs[c].subs && childs[c].subs.length > 0) {
                        mod.child = getChilds(childs[c].subs);
                    } else if (
                        childs[c].modules &&
                        childs[c].modules.length > 0
                    ) {
                        mod.child = getChilds(childs[c].modules);
                    }
                    if (
                        childs[c].type !== "" &&
                        typeof childs[c].module_id !== "undefined" &&
                        childs[c].module_id !== ""
                    ) {
                        // console.log(childs[c].module_id, childs[c]);
                        // templatelayers?.push(childs[c].module_id);
                        templatelayers?.push(mod?.head);
                    }
                    newchilds.push(mod);
                }
            }
            return newchilds;
        };

        const handleCategoryModule = (categories: any[]) => {
            let prepareTag: any[] = [];
            // console.log(categories);
            for (let f = 0; f < categories.length; f++) {
                let mod: any = {
                    child: [],
                    filters: [],
                    head: categories[f].code,
                    label: categories[f].label,
                    type:
                        typeof categories[f].type !== "undefined" &&
                        categories[f].type !== ""
                            ? categories[f].type
                            : "",
                };
                if (categories[f].subs && categories[f].subs.length > 0) {
                    mod.child = getChilds(categories[f].subs);
                } else if (
                    categories[f].modules &&
                    categories[f].modules.length > 0
                ) {
                    mod.child = getChilds(categories[f].modules);
                }
                prepareTag.push(mod);
            }

            setListModule(prepareTag);
            if (prepareTag?.length > 0) {
                layerHandler(mapbox, prepareTag, templatelayers);
            }
            // const menuItems: any = param?.modulemenu?.menuitems;
            // let prepareTag: Array<any> = [];
            // if (menuItems?.length > 0) {
            //   menuItems?.forEach((model: any) => {
            //     if (model?.module_tag) {
            //       if (prepareTag.indexOf(model.module_tag) === -1) {
            //         if (model?.children?.length > 0) {
            //           let listChild: Array<any> = [];
            //           model.children.forEach((childModel: any) => {
            //             if (childModel?.module_tag) {
            //               if (prepareTag.indexOf(childModel.module_tag) === -1) {
            //                 listChild = [
            //                   ...listChild,
            //                   {
            //                     head: childModel?.module_tag,
            //                     type: childModel?.module_type,
            //                   },
            //                 ];
            //               }
            //             }
            //           });
            //           prepareTag = [
            //             ...prepareTag,
            //             {
            //               head: model.module_tag,
            //               type: model.module_type,
            //               filters: model.filterables,
            //               child: listChild,
            //             },
            //           ];
            //         } else {
            //           prepareTag = [
            //             ...prepareTag,
            //             { head: model.module_tag, type: model.module_type },
            //           ];
            //         }
            //       }
            //     }
            //   });
            // }
            // setListModule(prepareTag);
            // if (prepareTag?.length > 0) {
            //   layerHandler(mapbox, prepareTag);
            // }
        };

        const handleModule = (param: {
            modulemenu: { menuitems: Array<any> };
        }) => {
            const menuItems: any = param?.modulemenu?.menuitems;
            let prepareTag: Array<any> = [];
            if (menuItems?.length > 0) {
                menuItems?.forEach((model: any) => {
                    if (model?.module_tag) {
                        if (prepareTag.indexOf(model.module_tag) === -1) {
                            if (model?.children?.length > 0) {
                                let listChild: Array<any> = [];
                                model.children.forEach((childModel: any) => {
                                    if (childModel?.module_tag) {
                                        if (
                                            prepareTag.indexOf(
                                                childModel.module_tag
                                            ) === -1
                                        ) {
                                            listChild = [
                                                ...listChild,
                                                {
                                                    head: childModel?.module_tag,
                                                    type: childModel?.module_type,
                                                },
                                            ];
                                        }
                                    }
                                });
                                prepareTag = [
                                    ...prepareTag,
                                    {
                                        head: model.module_tag,
                                        type: model.module_type,
                                        filters: model.filterables,
                                        child: listChild,
                                    },
                                ];
                            } else {
                                prepareTag = [
                                    ...prepareTag,
                                    {
                                        head: model.module_tag,
                                        type: model.module_type,
                                    },
                                ];
                            }
                        }
                    }
                });
            }
            setListModule(prepareTag);

            // console.log(JSON.parse(mapbox));
            if (prepareTag?.length > 0) {
                layerHandler(mapbox, prepareTag);
            }
        };

        const handleVector = async () => {
            // if (typeof category !== "undefined" && category.length > 0) {
            //   let newcode: string[] = [];
            //   for (let a = 0; a < category.length; a++) {
            //     newcode.push(category[a].code);
            //   }
            //   if (newcode.length > 0) {
            //     api += "?cats=" + newcode.join(",");
            //   }
            // }
            fetch(api)
                .then((response) => response.json())
                .then((data) => {
                    if (isMounted) {
                        setData(data);
                        handleModule(data);
                    }
                });
            // let request: any;
            // let response: any;
            // try {
            //   request = await fetch(api);
            //   if (request.ok) {
            //     response = await request.json();
            //   }
            // } catch (error) {
            //   throw error;
            // } finally {
            //   if (response) {
            //     if (isMounted) {
            //       setData(response);
            //       handleModule(response);
            //     }
            //   }
            // }
        };

        if (!data && typeof mapbox !== "undefined") {
            // console.log(JSON.stringify(mapbox));
            if (typeof category !== "undefined") {
                handleCategoryModule(category);
            } else {
                handleVector();
            }
        }

        return () => {
            isMounted = false;
        };
    }, [data, mapbox, category]);

    // React.useEffect(() => {
    //   let isMounted = true;
    //   const fetchData = () => {
    //     fetchPlus("/api/message/inbox", Headers())
    //       // .then((response) => response.json())
    //       .then((data) => {
    //         if (isMounted) {
    //           //console.log(data);
    //           setInboxList(data.inboxs);
    //         }
    //       });
    //   };
    //   if (typeof inboxslist === "undefined") {
    //     fetchData();
    //   }
    //   return () => {
    //     isMounted = false;
    //   };
    // }, [inboxslist]);

    // React.useEffect(() => {
    //   console.log("here");
    //   if (mapbox) {
    //     console.log("herex");
    //     mapbox.on("load", () => {
    //       console.log("herey");
    //       setLoad(true);
    //     });
    //   }
    // }, [mapbox]);

    return (
        <Fragment>
            {listModule && listModule?.length > 0 && (
                // <div className={`${grid ? classes.box_grid : classes.box}`}>
                <div className={classes.content}>
                    {mapbox &&
                        listModule.map((layerModel: any, key: number) => {
                            return (
                                <LayerController
                                    key={key.toString()}
                                    data={layerModel}
                                    mapbox={mapbox}
                                    layerCallback={layerCallback}
                                />
                            );
                        })}
                </div>
                // </div>
            )}
        </Fragment>
    );
};

export default VectorTiles;
