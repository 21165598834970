import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ data, option }: any) => {
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
        labels: {
          // boxWidth: 11,

          font: {
            size: 11,
          },
          usePointStyle: true,
        },
      },
      title: {
        display: false,
        // text: "Chart.js Bar Chart",
      },

      datalabels: {
        display: true,
        align: "top",
        // backgroundColor: "#ccc",
        // borderRadius: 3,
        font: {
          size: 8,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
    legend: {
      display: false,
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const datas: any = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <Bar
      options={typeof option !== "undefined" && option ? option : options}
      data={typeof data !== "undefined" && data ? data : datas}
    />
  );
};

export default BarChart;
