import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { format } from "date-fns";

import { useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../config/Firebase";
import Map from "../../../MapLight";
import CasesTable from "./Cases";
import SuspectTable from "./Suspects";

const SespectIndex = () => {
  const [profile, setProfile] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  let { id } = useParams();

  React.useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof profile === "undefined" && token !== "" && id !== "") {
      fetch("/api/crime/premise/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(function (body) {
          return body.json();
        })
        .then(function (data) {
          if (typeof data?.premise?.id === "undefined") {
            setProfile({});
          } else {
            setProfile(data);
          }

          // for (let i = 0; i < data.features.length; i++) {
          // }
        })
        .catch(function (err) {
          console.log(err);
          alert("Unable to fetch census data");
        });
    }
  }, [profile, token, id]);

  console.log("profile", profile);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            // noWrap
            component="div"
            // letterSpacing={2}
            // fontSize={18}
          >
            Premis: {profile?.premise?.premise_name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            style={{
              height: "30vh",
              width: "100%",
              maxHeight: "500px",
              minHeight: "10vh",
              backgroundImage: `url(https://ck.tuxgeo.dev/api/photos/cawangankhas/premise_picture/${profile?.premise?.cases_id})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                `https://ck.tuxgeo.dev/api/photos/cawangankhas/premise_picture/${profile?.premise?.cases_id}`,
                "profilephotos"
              );
            }}
          ></div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            style={{
              width: "100%",
              height: "30vh",
              maxHeight: "500px",
              minHeight: "10vh",
              border: "0px solid #ff0000",
            }}
          >
            <Map />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="p">
            Maklumat Premis:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            {profile?.premise?.premise_description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Jenis Premis: {profile?.premise?.premise_type}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Mula Beroperasi:
            {profile?.premise?.premise_started_date
              ? format(
                  new Date(profile?.premise?.premise_started_date),
                  "dd MMMM yyyy"
                )
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Sektor: {profile?.premise?.district}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="p">
            Maklumat Kes:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="p">
            Laporan Id: #{profile?.premise?.case_id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Sektor: {profile?.premise?.case_district}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Status: {profile?.premise?.case_status}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="p">
            Kelulusan:{" "}
            {profile?.premise?.case_is_approved
              ? profile?.premise?.case_is_approved
              : "Menunggu Kelulusan"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="p">
            Suspek Dikesan:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <SuspectTable
            data={profile?.premise?.suspects ? profile?.premise?.suspects : []}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="p">
            Laporan Berkaitan:
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <CasesTable
            data={profile?.premise?.cases ? profile?.premise?.cases : []}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SespectIndex;
