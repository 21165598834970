import { useEffect, useState } from "react";
import constate from "constate";
import { Loader, LoaderOptions } from "@googlemaps/js-api-loader";

interface Prop {
  elem?: HTMLDivElement;
  loaderOptions: LoaderOptions;
  mapOptions?: google.maps.MapOptions;
  profile?: any;
  category?: any;
  mapCallback?: (ghandler: any, maphandler: any) => void;
}

const defaultLoaderOptions = {
  version: "quarterly",
  region: "my",
} as LoaderOptions;

function useGoogleMaps({ elem, loaderOptions, mapOptions, mapCallback }: Prop) {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    function init(elem: HTMLElement) {
      const defaultMapOptions = {
        zoom: typeof mapOptions?.zoom !== "undefined" ? mapOptions?.zoom : 10,
        center:
          typeof mapOptions?.center !== "undefined"
            ? mapOptions?.center
            : { lat: 3.8, lng: 101.86869 },
        controlSize:
          typeof mapOptions?.controlSize !== "undefined"
            ? mapOptions?.controlSize
            : 25,
        fullscreenControl:
          typeof mapOptions?.fullscreenControl !== "undefined"
            ? mapOptions?.fullscreenControl
            : false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DEFAULT,
        },
        // restriction: {
        //   latLngBounds: {
        //     north: 12.069289,
        //     south: -5.32301,
        //     west: 92.744813,
        //     east: 124.385438,
        //   },
        // },
        gestureHandling: "greedy",
      } as google.maps.MapOptions;

      setMap(
        new google.maps.Map(elem, {
          ...defaultMapOptions,
          ...mapOptions,
        })
      );
    }

    if (elem && !map) {
      if (google.maps) {
        init(elem);
      } else {
        const loader = new Loader({
          ...defaultLoaderOptions,
          ...loaderOptions,
        });

        loader
          .load()
          .then(() => {
            init(elem);
          })
          .catch((e: Error) => {
            console.error("error load map", e);
          });
      }
    }
  }, [elem, loaderOptions, mapOptions, map]);

  useEffect(() => {
    if (
      mapCallback &&
      typeof google !== "undefined" &&
      typeof google.maps !== "undefined" &&
      typeof map !== "undefined" &&
      map !== null
    ) {
      mapCallback(google, map);
    }
  }, [mapCallback, map]);

  return map;
}

const [GoogleMapsProvider, useMap] = constate(useGoogleMaps);
export { useMap };
export default GoogleMapsProvider;
